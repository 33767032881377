<template>
  <div class="obu-personalization-container">
    <b-row style="justify-content: center">
      <b-col xl="5" lg="10" md="10" sm="12">
        <b-row>
          <b-col cols="12">
            <label class="c-white">Aggiornamento ogni:</label>
            <b-row>
              <b-col class="pr-0 pt-1" cols="12" xl="3" lg="3" md="4" sm="12">
                <b-input-group class="mb-2">
                  <b-dropdown class="my-btn-secondary my-dropdown"
                              :text="secondsDelay + ' secondi'"
                              v-model="secondsDelay">
                    <b-dropdown-item @click="secondsDelay='5'">5 secondi</b-dropdown-item>
                    <b-dropdown-item @click="secondsDelay='10'">10 secondi</b-dropdown-item>
                    <b-dropdown-item @click="secondsDelay='15'">15 secondi</b-dropdown-item>
                    <b-dropdown-item @click="secondsDelay='30'">30 secondi</b-dropdown-item>
                  </b-dropdown>
                </b-input-group>
              </b-col>
              <b-col class="pt-1 mb-2">
                <b-button id="refresh-button"
                          @click="getData(true)">
                  Refresh
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-card class="table">
          <b-row style="justify-content: center">
            <b-col class="table-cols" cols="12" xl="10" lg="9" md="10" sm="12">
              <b-table
                  id="personalizationObuTable"
                  ref="personalizationObuTable"
                  :items="obu"
                  :fields="fields"
                  :sort-desc=true
                  responsive="sm"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :empty-text="'Non ci sono dati da visualizzare'"
                  :tbody-tr-class="rowClass"
                  :show-empty="true"
                  thead-tr-class="d-none"
              >
                <template v-slot:cell(obuid)="row">
                  <b-card  id="card-obu-input" :class="row.item.colorClass">
                    <b-form-input
                        v-model="row.item.obuid"
                        :disabled="row.item.obuid.length === 15 || !(session && session.operator && session.operator.auth_mask[maskValue('PERSONALIZATION_OBU', 'UPDATE')] === '1' && session.operator.auth_mask[maskValue('PERSONALIZATION_OBU', 'CREATE')] === '1')"
                        @input="row.item.obuid.length === 15 ? setObuId(row.item) : null"
                        type="text"
                        style="height: 1.7rem; text-align: center; border: none; background-color: transparent"

                        ref="inputObuId"
                    ></b-form-input>
                  </b-card>
                </template>
                <template v-slot:cell(delete)="row">
                  <b-button
                      class="enable-disable"
                      @click="currentObu = row.item; $refs.confirmDeleteModal.show()"
                      :disabled="row.item.obuid === '' || row.item.colorClass.includes('white')"
                      v-if="session && session.operator && session.operator.auth_mask[maskValue('PERSONALIZATION_OBU', 'DELETE')] === '1'"
                  >
                    <i class="fa fa-trash-alt"/>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
        <b-row style="justify-content: end" class="mb-2">
          <b-button id="clear-all"
                    :disabled="!obu.find(e => e.obuid.length > 0)"
                    v-if="session && session.operator && session.operator.auth_mask[maskValue('PERSONALIZATION_OBU', 'DELETE')] === '1'"
                    @click="$refs.confirmMultipleDeleteModal.show()">Elimina tutti
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
        id="confirm-delete-modal"
        ref="confirmDeleteModal"
        title="Attenzione"
        no-close-on-backdrop
        @ok="clearObu(currentObu)"
    >
      <b-row style="justify-content: center">
        <div class="m-3">
          <h6 style="text-align: center">
            Sei sicuro di voler eliminare l'obu {{ currentObu.obuid }}?
          </h6>
        </div>
      </b-row>
    </b-modal>
    <b-modal
        id="confirm-multiple-delete-modal"
        ref="confirmMultipleDeleteModal"
        title="Attenzione"
        no-close-on-backdrop
        @ok="clearAll"
    >
      <b-row style="justify-content: center">
        <div class="m-3">
          <h6 style="text-align: center">
            Sei sicuro di voler eliminare tutti gli obu?
          </h6>
        </div>
      </b-row>
    </b-modal>
    <b-modal
        id="existing-obu-id-modal"
        ref="existingObuIdModal"
        title="Attenzione"
        no-close-on-backdrop
    >
      <b-row style="justify-content: center">
        <div class="m-3">
          <h6 style="text-align: center">
            L'obu ID inserito è già nella lista
          </h6>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import _, {isNil} from "lodash";

export default {
  name: "MonitorObuTable",
  data() {
    return {
      stackedModeOn: null,
      secondsDelay: '10',
      timer: '',
      session: '',
      obu: [],
      fields: [
        {key: 'number', label: '', sortable: false},
        {key: 'obuid', label: '', sortable: false},
        {key: 'delete', label: '', sortable: false},
      ],
      currentPage: 1,
      perPage: 100,
      currentObu: '',
      originalDock: [],
      dockLength: 10,
      currentFlasherSerial: this.$route.params.id
    }
  },
  watch: {
    '$route.params.id'() {
      this.currentFlasherSerial = this.$route.params.id;
      this.getData(true)
      this.timer = setInterval(this.getData, this.secondsDelay * 1000, false);
      Vue.setLocalStorage('timer', this.timer)
    },
    secondsDelay() {
      clearInterval(this.timer)
      this.timer = setInterval(this.getData, this.secondsDelay * 1000, true);
      Vue.setLocalStorage('timerPersonalizationObu', this.timer)
    },
  },
  created() {
    this.session = Vue.getLocalStorage('session')
    this.getData(true)
    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md' || size === 'lg'
    clearInterval(this.timer)
    this.timer = setInterval(this.getData, this.secondsDelay * 1000, true);
    Vue.setLocalStorage('timerPersonalizationObu', this.timer)
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.showTextInputToVerifyObu) return 'yellow-background'
    },
    async setObuId(currentItem) {
      clearInterval(this.timer)
      await Vue.myGet(this, '/customization_dock/fs/' + this.currentFlasherSerial).then(async (docksResponse) => {
        var dockToSend = []
        if (docksResponse.status === 404) {
          dockToSend = new Array(parseInt(this.dockLength)).fill({});
        } else if (!isNil(docksResponse) && !isNil(docksResponse.dock) && docksResponse.dock.length > 0) {
          dockToSend = this.getOnlyObuIDs(docksResponse.dock)
        }
        if (!isNil(docksResponse.dock.find(item => item.obuid === currentItem.obuid))) {
          this.$refs.existingObuIdModal.show()
          let itemInObu = _.find(this.obu, item => item === currentItem)
          this.timer = setInterval(this.getData, this.secondsDelay * 1000, true);
          Vue.setLocalStorage('timerPersonalizationObu', this.timer)
          itemInObu.obuid = ''
        } else {
          dockToSend[currentItem.number - 1] = {obuid: currentItem.obuid}
          await this.sendPostDock(dockToSend, true)
        }
      }).catch(() => {
        this.$root.$emit('errorNotification')
      })
    },
    async getData(send) {
      this.obu = []
      await Vue.myGet(this, '/customization_dock/fs/' + this.currentFlasherSerial).then(async (docksResponse) => {
        if (docksResponse.status === 404) {
          this.initializeLocalDock()
        } else if (!isNil(docksResponse) && !isNil(docksResponse.dock) && docksResponse.dock.length > 0) {
          var i = 1;
          docksResponse.dock.forEach((item) => {
            var currentItem = item
            currentItem.number = i
            if (!isNil(item.obuid)) {
              if (currentItem.status === "ONGOING") {
                currentItem.colorClass = 'obu-card-yellow'
              } else if (currentItem.status === "KO") {
                currentItem.colorClass = 'obu-card-red'
              } else {
                currentItem.colorClass = 'obu-card-green'
              }
              this.obu.push(currentItem)
            } else {
              this.obu.push({number: i, obuid: '', colorClass: 'obu-card-white'},)
            }
            i += 1
          })
        }
        if (!isNil(send) && send === true) {
          var newDock = docksResponse.status === 404 ? new Array(parseInt(this.dockLength)).fill({}) : this.getOnlyObuIDs(docksResponse.dock)
          await this.sendPostDock(newDock, false)
        }
      }).catch((error) => {
        this.$root.$emit('errorNotification', 'telepassServiceError')
        console.log(error)
      })
    },
    initializeLocalDock() {
      let i = 1
      this.localEntireDock = []
      while (i <= parseInt(this.dockLength)) {
        this.obu.push({
          number: i,
          obuid: '',
          colorClass: 'obu-card-white'
        })
        i += 1
      }
    },
    async clearObu(item) {
      clearInterval(this.timer)
      var newDock = []
      if (!isNil(item)) {
        await Vue.myGet(this, '/customization_dock/fs/' + this.currentFlasherSerial).then(async (docksResponse) => {
          if (!isNil(docksResponse) && !isNil(docksResponse.dock) && docksResponse.dock.length > 0) {
            var itemToDelete = docksResponse.dock.find(obuInDock => obuInDock.obuid === item.obuid)
            var index = docksResponse.dock.indexOf(itemToDelete)
            newDock = this.getOnlyObuIDs(docksResponse.dock)
            newDock[index] = {}
            await this.sendPostDock(newDock, true)
          }
        }).catch(() => {
          this.$root.$emit('errorNotification')
        })
      }
    },
    getOnlyObuIDs(originalDock) {
      var newDock = []
      originalDock.forEach((item) => {
        if (!isNil(item.obuid)) {
          newDock.push({obuid: item.obuid})
        } else {
          newDock.push({})
        }
      })
      return newDock
    },
    async clearAll() {
      clearInterval(this.timer)
      let newDock = []
      this.obu.forEach((item) => {
        if (item.status !== 'ONGOING') {
          newDock.push({})
        } else {
          newDock.push({obuid: item.obuid})
        }
      })
      await this.sendPostDock(newDock, true)
    },
    async sendPostDock(newDock, get) {
      newDock.forEach((item) => {
        delete item.status
      })
      var requestBody = {
        dock: newDock,
        flasher_serial: this.currentFlasherSerial
      }
      await Vue.myPost('/customization_dock', requestBody).then(() => {
        if (!isNil(get) && get === true) {
          this.getData(false)
          this.timer = setInterval(this.getData, this.secondsDelay * 1000, true);
          Vue.setLocalStorage('timerPersonalizationObu', this.timer)
        }

      }).catch(() => {
        this.$root.$emit('errorNotification')
      })
    }
  }
}
</script>

<style>
@import '../assets/styles/monitor-style.css';

.table tr td:first-child, .table tr th:first-child {
  padding: 1rem;
}
</style>
