<template>
  <div class="shadow">
    <b-navbar class="header" variant="light">
      <b-navbar-brand class="header-logo" v-if="logged && $route.name !== '/'"
                      @click="$route.name !== 'home' ? $router.push('/home') : null">
        <img src="@/assets/logo.png" alt="flasher logo"/>
      </b-navbar-brand>
      <b-collapse id="nav-collapse" is-nav v-if="logged && $route.name !== '/'">
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <font-awesome-icon icon="user-circle"/>
            </template>
            <b-dropdown-text class="border-bottom">
              <div style="display: flex">
                <div style="margin-right: 0.3rem">Benvenuto</div>
                <div style="display: inline-block" class="bold-font text-overflow-handle">{{
                    session.operator.username
                  }}
                </div>
                !
              </div>
            </b-dropdown-text>
            <b-dropdown-item @click="goToUserProfile">Profilo</b-dropdown-item>
            <b-dropdown-item @click="logout">Esci</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Header",
  data() {
    const logged = Vue.getLocalStorage('logged')
    const session = Vue.getLocalStorage('session')

    return {
      logged: logged,
      session: session
    }
  },
  watch: {
    '$route'() {
      this.logged = Vue.getLocalStorage('logged')
      this.session = Vue.getLocalStorage('session')

    },
  },
  mounted() {
    this.logged = Vue.getLocalStorage('logged')
    this.$root.$refs.Header = this;
    this.$root.$on('changeMask', () => {
      this.session = Vue.getLocalStorage('session')
    })

  },
  methods: {
    goToUserProfile() {
      this.$router.push('/home/user')
    },
    logout() {
      Vue.deleteLocalStorage('session')
      Vue.setLocalStorage('logged', false)
      let timer = Vue.getLocalStorage('timer')
      clearInterval(timer);
      if (this.$route.name !== '/')
        this.$router.push('/')
    },
  }
}
</script>

<style scoped>
img {
  max-height: 4rem;
}

.svg-inline--fa {
  width: 2.5rem;
  height: 2.5rem;
  color: black;
}

.navbar-dark .navbar-nav .nav-link {
  color: black;
}

.bold-font {
  font-weight: bold;
}

.navbar {
  padding: 0;
}

.text-overflow-handle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration-color: black;
  max-width: 10rem;
}

.header-logo {
  cursor: pointer;
}
</style>

