<template>
  <div :class="!stackedModeOn ? 'table-container' : 'table-responsive'">
    <b-row>
      <b-col>
        <label class="c-white">Aggiornamento ogni:</label>
        <b-row>
          <b-col class="pr-0 pt-1" cols="6" sm="12" md="6" lg="2" xl="2">

            <b-input-group class="mb-2">
              <b-dropdown :disabled="allGreyAndDisabled" class="my-btn-secondary my-dropdown"
                          :text="secondsDelay + ' secondi'"
                          v-model="secondsDelay">
                <b-dropdown-item @click="secondsDelay='5'">5 secondi</b-dropdown-item>
                <b-dropdown-item @click="secondsDelay='10'">10 secondi</b-dropdown-item>
                <b-dropdown-item @click="secondsDelay='15'">15 secondi</b-dropdown-item>
                <b-dropdown-item @click="secondsDelay='30'">30 secondi</b-dropdown-item>
              </b-dropdown>
            </b-input-group>
          </b-col>
          <b-col class="pt-1 mb-2" cols="12" sm="9" md="6" lg="2" xl="2">
            <b-button id="refresh-button" :disabled="allGreyAndDisabled"
                      @click="$root.$emit('activeLoader', true); getDock()">
              Refresh
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-card class="table " style="padding-bottom: 1rem; text-align: start; margin-bottom: 0.3rem">
      {{ $route.params.id }}
    </b-card>
    <b-card class="table">
      <b-row v-if="!operationComplete">
        <b-col class="table-cols" cols="12" lg="3">
          <b-table
              id="monitorTable1"
              ref="monitorTable1"
              class="table monitor-table table-font"
              :items="pieceOfDock1"
              :fields="fields"
              :sort-by="sortBy"
              :sort-desc=true
              responsive="sm"
              :fixed="fixed !== false ? true : null"
              :current-page="currentPage"
              :per-page="perPage"
              :empty-text="'Non ci sono dati da visualizzare'"
              :tbody-tr-class="rowClass"
              :show-empty="true"
              thead-tr-class="d-none"
          >
            <template v-slot:cell(obu_id)="row">
              <b-card v-if="!row.item.showTextInputToVerifyObu" id="obu-card"
                      :class="row.item.colorClass"
                      style="width: 80%; display: flex">
                {{ row.item.obu_id }}
                <i :class="row.item.connectionStatus" style="margin-right: 20px;"></i>
              </b-card>
              <b-card id="card-obu-input" class="p-0 obu-card-white" v-else>
                <b-form-input
                    :placeholder="row.item.obu_id"
                    @input="setObuId"
                    v-model="selectedVerificableObu.inserted"
                    style="height: 1.7rem; text-align: center; border: none"
                    class="white-background"
                    ref="inputObuId"
                    autofocus
                ></b-form-input>
              </b-card>
            </template>
            <template v-slot:cell(verified)="row">
              <input class="custom-checkbox" type="checkbox" v-model="row.item.verified" :disabled="true"/>
            </template>
          </b-table>

        </b-col>
        <b-col class="table-cols" cols="12" lg="3">
          <b-table
              id="monitorTable2"
              ref="monitorTable2"
              class="table monitor-table table-font"
              :items="pieceOfDock2"
              :fields="fields"
              :sort-by="sortBy"
              :sort-desc=true
              responsive="sm"
              :fixed="fixed !== false ? true : null"
              :current-page="currentPage"
              :per-page="perPage"
              :empty-text="'Non ci sono dati da visualizzare'"
              :show-empty="true"
              thead-tr-class="d-none"
          >
            <template v-slot:cell(obu_id)="row">
              <b-card v-if="!row.item.showTextInputToVerifyObu" id="obu-card"
                      :class="row.item.colorClass"
                      style="width: 80%; display: flex">
                {{ row.item.obu_id }}
                <i :class="row.item.connectionStatus" style="margin-right: 20px;"></i>
              </b-card>
              <b-card id="card-obu-input" class="p-0 obu-card-white" v-else>
                <b-form-input
                    @input="setObuId"
                    v-model="selectedVerificableObu.inserted"
                    style="height: 1.7rem; text-align: center; background: rgba(243, 61, 82, 0.97); border: none"
                    class="white-background"
                    ref="inputObuId"
                ></b-form-input>
              </b-card>
            </template>
            <template v-slot:cell(verified)="row">
              <input class="custom-checkbox" type="checkbox" v-model="row.item.verified" :disabled="true"/>
            </template>
          </b-table>
        </b-col>
        <b-col class="table-cols" cols="12" lg="3">
          <b-table
              id="monitorTable3"
              ref="monitorTable3"
              class="table monitor-table table-font"
              :items="pieceOfDock3"
              :fields="fields"
              :sort-by="sortBy"
              :sort-desc=true
              responsive="sm"
              :fixed="fixed !== false ? true : null"
              :current-page="currentPage"
              :per-page="perPage"
              :empty-text="'Non ci sono dati da visualizzare'"
              :show-empty="true"
              thead-tr-class="d-none"
          >
            <template v-slot:cell(obu_id)="row">
              <b-card v-if="!row.item.showTextInputToVerifyObu" id="obu-card"
                      :class="row.item.colorClass"
                      style="width: 85%; display: flex">
                {{ row.item.obu_id }}
                <i :class="row.item.connectionStatus" style="margin-right: 20px;"></i>
              </b-card>
              <b-card id="card-obu-input" class="p-0 obu-card-white" v-else>
                <b-form-input
                    @input="setObuId"
                    v-model="selectedVerificableObu.inserted"
                    style="height: 1.7rem; text-align: center; border: none"
                    class="white-background"
                    ref="inputObuId"
                ></b-form-input>
              </b-card>
            </template>
            <template v-slot:cell(verified)="row">
              <input class="custom-checkbox" type="checkbox" v-model="row.item.verified" :disabled="true"/>

            </template>
          </b-table>
        </b-col>
        <b-col class="table-cols" cols="12" lg="3">
          <b-table
              id="monitorTable4"
              ref="monitorTable4"
              class="table monitor-table table-font"
              :items="pieceOfDock4"
              :fields="fields"
              :sort-by="sortBy"
              :sort-desc=true
              responsive="sm"
              :fixed="fixed !== false ? true : null"
              :current-page="currentPage"
              :per-page="perPage"
              :empty-text="'Non ci sono dati da visualizzare'"
              :show-empty="true"
              thead-tr-class="d-none"
          >
            <template v-slot:cell(obu_id)="row">
              <b-card v-if="!row.item.showTextInputToVerifyObu" id="obu-card"
                      :class="row.item.colorClass"
                      style="width: 80%; display: flex">
                {{ row.item.obu_id }}
                <i class="fa-solid fa-plug-circle-check"></i>
                <i :class="row.item.connectionStatus" style="margin-right: 20px;"></i>
              </b-card>
              <b-card id="card-obu-input" class="p-0 obu-card-white" v-else>
                <b-form-input
                    @input="setObuId"
                    v-model="selectedVerificableObu.inserted"
                    style="height: 1.7rem; text-align: center; border: none"
                    class="white-background"
                    ref="inputObuId"
                ></b-form-input>
              </b-card>
            </template>
           <!-- <template v-slot:cell(verified)="row">
              <input class="custom-checkbox" type="checkbox" v-model="row.item.verified" :disabled="true"/>

            </template> -->
          </b-table>
        </b-col>
      </b-row>
     <!-- <b-row style="justify-content: center" v-else-if="!operationComplete">
        <b-card class="mb-4 pb-4 radius-card">
          <div style="text-align: center"><b>CARATTERISTICA APPARATO</b></div>
          <div class="mt-1 mb-2">Seleziona una caratteristica da assegnare a tutti gli apparati verificati.</div>
          <div v-for="vehicle in vehicles" :key="vehicle.id">
            <input class="custom-checkbox mr-2 mb-1" type="radio" v-model="selectedVehicle" name="some-radios"
                   :value="vehicle.id"/>{{ vehicle.Type }}
          </div>
        </b-card>
      </b-row> -->
      <b-row style="justify-content: center" v-else-if="operationComplete">
        <b-card id="success-card" class="mb-4 radius-card pt-auto pt-auto" style="height: 15rem">
          <div style="text-align: center;" class="pt-auto"><b>OPERAZIONE CONCLUSA CON SUCCESSO</b></div>
        </b-card>
      </b-row>
    </b-card>
    <b-row style="justify-content: end">
      <b-button :disabled="numberOfRecords === 0" @click="$refs.confirmResetDockModal.show()">Resetta dock</b-button>
      <!--<b-button :disabled="numberOfRecords === 0 || (flasher !== null && flasher.active_status_id === 3)" @click="$refs.confirmResetDockModal.show()">Resetta dock</b-button>
      <i v-if="flasher !== null && flasher.active_status_id === 3"
         v-b-tooltip.hover title="Il dock non può essere resettato: il flasher è in fase di aggiornamento" class="fa ml-2 fa-info-circle" style="margin: auto 10px; color: #ff6a6a"
      />-->
      <!--<b-button id="associate-features-button"
                v-if="allFinished && chooseFeature && !showChooseFeatureElement && session && session.operator && session.operator.auth_mask[maskValue('MONITOR', 'UPDATE')] === '1'"
                @click="showChooseFeatureElement = true">Assegna caratteristica apparati
      </b-button>
      <b-button id="complete-operation-button" v-if="allFinished && showChooseFeatureElement && !operationComplete"
                @click="associateVehicle()" :disabled="!selectedVehicle">Completa operazione
      </b-button>
      <b-button id="complete-operation-button" v-if="allFinished && chooseFeature && !operationComplete && session && session.operator && session.operator.auth_mask[maskValue('MONITOR', 'UPDATE')] === '1'"
                @click="associateVehicle()">Completa operazione
      </b-button>
      <b-button id="new-lot-button"
                v-if="(operationComplete || !allVerified) && session && session.operator && session.operator.auth_mask[maskValue('MONITOR', 'DELETE')] === '1' && numberOfRecords > 0"
                class="mr-3" @click="$refs.confirmResetDockModal.show()">Nuovo lotto
      </b-button>
      <b-button v-if="operationComplete" @click="$router.push({ path: '/home'})">Chiudi</b-button>
      <b-button id="verify-button" :disabled="!allFinished"
                v-if="!chooseFeature && !showChooseFeatureElement && !allGreyAndDisabled"
                @click="noRed ? verificationOfEquipment() : selectFirstRedObu()">Verifica apparati
      </b-button>-->

    </b-row>
    <b-modal
        ref="verifiedModal"
        title="Verifica apparati"
        no-close-on-backdrop
        ok-only
        @ok="chooseFeature = true"
    >
      <div style="text-align: center">Operazione terminata con successo. <br><br> Rimuovi tutti gli apparati rimanenti
        dal K1Flasher e inseriscili nella scatola degli apparati SPEDIBILI.
      </div>
    </b-modal>
    <b-modal
        ref="succeededOperationModal"
        :title="!allGreyAndDisabled ? 'Assegnazione Caratteristica Apparato' : 'Avvia nuovo lotto'"
        no-close-on-backdrop
        ok-only
        @ok="operationComplete = true"
    >
      <div style="text-align: center">{{ !allGreyAndDisabled ? 'Operazione completata con successo.' : '' }} Premere il
        pulsante “Nuovo Lotto” per avviare un nuovo lotto di lavoro.
      </div>
    </b-modal>

    <b-modal
        ref="alertErrorOnObuModal"
        title="VERIFICA APPARATO"
        no-close-on-backdrop
        @ok="resetVerification"
        @close="resetVerification"
        ok-only ok-variant="secondary" ok-title="Cancel"
    >
      <b-row style="justify-content: center">
        <div class="m-3">
          <h5 style="text-align: center">

            Estrai dal K1Flasher l'apparato in errore in posizione <b> {{ selectedObuPosition }} </b>e inquadra il
            codice a barre.

          </h5>
          <b-card id="card-obu-input" class="p-0 obu-card-white w-50" style="margin: 0 auto">
            <b-form-input
                :placeholder="selectedVerificableObu.obu_id"
                @input="setObuId"
                v-model="selectedVerificableObu.inserted"
                style="height: 1.7rem; text-align: center; border: none"
                class="white-background"
                ref="inputObuId"
                id="input-obu-id"
                autofocus
            ></b-form-input>
          </b-card>
        </div>
      </b-row>
    </b-modal>
    <b-modal
        id="discarded-apparatuses-insert-modal"
        ref="discardedApparatusesInsertModal"
        title="VERIFICA APPARATI"
        no-close-on-backdrop
        ok-only
        @ok="!noRed ? selectFirstRedObu() : focusOnObu()"
    >
      <b-row style="justify-content: center">
        <div class="m-3">
          <h6 style="text-align: center">
            Inserisci l'apparato nel box degli apparati SCARTATI.

          </h6>
        </div>
      </b-row>
    </b-modal>

    <b-modal
        id="shipped-apparatuses-insert-modal"
        ref="shippedApparatusesInsertModal"
        title="VERIFICA APPARATI"
        no-close-on-backdrop
        ok-only
        @ok="$refs.alertErrorOnObuModal.show()"
    >
      <b-row style="justify-content: center">
        <div class="m-3">
          <h6 style="text-align: center">
            Inserisci l'apparato nel box degli apparati SPEDIBILI.
          </h6>
        </div>
      </b-row>
    </b-modal>

    <b-modal
        id="sudden-reset-dock-modal"
        ref="suddenResetDockModal"
        title="Flasher inizializzato"
        no-close-on-backdrop
        ok-only
        @ok="$router.go()"
    >
      <b-row style="justify-content: center">
        <div class="m-3">
          <h6 style="text-align: center">
            Il flasher è stato inizializzato. Il lavoro precedente è stato perso.
          </h6>
        </div>
      </b-row>
    </b-modal>

    <b-modal
        id="confirm-reset-dock-modal"
        ref="confirmResetDockModal"
        title="Attenzione"
        no-close-on-backdrop
        @ok="resetDock()"
    >
      <b-row style="justify-content: center">
        <div class="m-3">
          <h6 style="text-align: center">
            Sei sicuro di voler inizializzare il dock? Il lavoro precedente non sarà più visualizzabile.
          </h6>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import myUsefulFunctions from "@/plugin/my-useful-functions";
import _, {first, isEmpty, isNil} from "lodash";

export default {
  data() {
    return {
      session: '',
      sortBy: 'timestamp',
      operationCodes: null,
      operationDes: [],
      currentPage: 1,
      perPage: 100,
      pageOptions: [5, 10, 25, 50, 100],
      stackedModeOn: null,
      sortDesc: false,
      startDate: '',
      endDate: '',
      timer: '',
      versions: [],
      fields: [
        {key: 'number', label: '', sortable: false},
        {key: 'obu_id', label: '', sortable: false},
       // {key: 'verified', label: '', sortable: false},
      ],
      totalNumberOfRecords: '64',
      status: [],
      vehicles: [],
      secondsDelay: '10',
      localEntireDock: [],
      pieceOfDock1: [],
      pieceOfDock2: [],
      pieceOfDock3: [],
      pieceOfDock4: [],
      selectedVehicle: null,
      noRed: true,
      selectedVerificableObu: {
        inserted: '',
        obu_id: ''
      },
      numberOfRecords: 0,
      chunksLength: 8,
      flasher: null,
      dock: [],
      selectedObuPosition: '',
      allVerified: false,
      operationComplete: false,
      allGreyAndDisabled: false,
      allFinished: false,
      chooseFeature: false,
      showChooseFeatureElement: false,
    }
  },
  computed: {
    numberOfRows() {
      return this.numberOfRecords
    },
  },
  async created() {
    this.session = Vue.getLocalStorage('session')
    this.initializeLocalDock()

    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md' || size === 'lg'
    await this.fetchFirstData().then(async () => {
      this.getDock()
    })

    this.timer = setInterval(this.getDock, this.secondsDelay * 1000);
    Vue.setLocalStorage('timer', this.timer)
  },
  methods: {
    setObuId() {
      if (!_.isNil(this.selectedVerificableObu) && !_.isEmpty(this.selectedVerificableObu.obu_id) && !_.isEmpty(this.selectedVerificableObu.inserted)) {
        if (this.selectedVerificableObu.inserted === this.selectedVerificableObu.obu_id) {
          let foundItem = _.find(this.localEntireDock, item => item.obu_id === this.selectedVerificableObu.obu_id)
          if(!isNil(foundItem)){
            foundItem.verified = true
            foundItem.showTextInputToVerifyObu = false
            this.splitDock()
            if (foundItem.colorClass === 'obu-card-red') {
              this.$refs.discardedApparatusesInsertModal.show()
            }
            this.$refs.alertErrorOnObuModal.hide()
          }
        } else {
          const wrongPositionItem = _.find(this.localEntireDock, item => item.obu_id === this.selectedVerificableObu.inserted)
          if (!_.isNil(wrongPositionItem)) {
            wrongPositionItem.verified = false
            let verifiedItem = _.find(this.localEntireDock, item => item.number === this.selectedVerificableObu.number)
            wrongPositionItem.obu_id = _.cloneDeep(verifiedItem.obu_id)
            const showModalDiscard = wrongPositionItem.colorClass === 'obu-card-red'
            if (showModalDiscard) {
              this.$refs.discardedApparatusesInsertModal.show()
            } else {
              this.$refs.alertErrorOnObuModal.hide()
              this.$refs.shippedApparatusesInsertModal.show()
            }
            verifiedItem.obu_id = _.cloneDeep(this.selectedVerificableObu.inserted)
            const colorOfVerifiedItem = verifiedItem.colorClass
            verifiedItem.colorClass = _.cloneDeep(wrongPositionItem.colorClass)
            wrongPositionItem.colorClass = _.cloneDeep(colorOfVerifiedItem)
            this.selectedVerificableObu = wrongPositionItem
            verifiedItem.verified = true
            verifiedItem.showTextInputToVerifyObu = false
            wrongPositionItem.showTextInputToVerifyObu = true
            this.selectedObuPosition = this.localEntireDock.indexOf(wrongPositionItem) + 1
            this.splitDock()

            if (!showModalDiscard && this.$refs.shippedApparatusesInsertModal.hidden) {
              this.$refs.alertErrorOnObuModal.show()
            }
          }
        }
      }
      setTimeout(() => {
        if (!_.isNil(_.find(this.localEntireDock, item => item.showTextInputToVerifyObu) && !_.isNil(this.$refs.inputObuId))) {
          this.$refs.inputObuId.focus();
        }
      }, 1000);
      this.selectedVerificableObu.inserted = ''
    },
    initializeLocalDock() {
      let i = 1
      this.localEntireDock = []
      var dockLength = this.dock.length
      this.dock = []
      while (i <= parseInt(this.totalNumberOfRecords)) {
        this.localEntireDock.push({
          number: i,
          obu_id: '           ',
          verified: false,
          operation_code_counter: {},
          showTextInputToVerifyObu: false,
          colorClass: 'obu-card-yellow'
        })
        if(dockLength === 0) {
          this.dock.push({})
        }
        i += 1
      }
      this.splitDock()
      this.refreshAllTables()
    },
    async fetchFirstData() {
      if (this.vehicles.length === 0) {
        this.vehicles = await myUsefulFunctions.getVehicle()
      }
      this.versions = await myUsefulFunctions.getVersions()
      this.status = await myUsefulFunctions.getStatus()
      this.operationDes = await myUsefulFunctions.getOperationDes()
      this.operationCodes = await myUsefulFunctions.getOperationCodes()
      this.vehicles = await myUsefulFunctions.getVehicle()
      this.flasher = _.first(await myUsefulFunctions.getFlashers({flasherSerial: this.$route.params.id}))
    },
    async getDock() {
      this.numberOfRecords = 0
      await Vue.myGet(this, '/dock/fs/' + this.flasher.flasher_serial).then(async (docksResponse) => {
        if (!_.isNil(docksResponse)) {
          if (docksResponse.status === 500) {
            this.$root.$emit('errorNotification', 'telepassServiceError', null, docksResponse.data.errMsg)
          } else if (docksResponse.status !== 404
              && !_.isNil(docksResponse.dock)
              && !_.isEmpty(_.first(docksResponse.dock))) {
            this.dock = docksResponse.dock
            this.dock.forEach((obj) => {
              if (!_.isEmpty(obj) && this.numberOfRecords < parseInt(this.totalNumberOfRecords)) {
                this.localEntireDock[this.numberOfRecords].obu_id = obj.obu_id
                this.localEntireDock[this.numberOfRecords].colorClass = obj.status === 'KO' ? 'obu-card-red' : obj.status === 'OK' ? 'obu-card-green' : 'obu-card-yellow'
                this.localEntireDock[this.numberOfRecords].status = obj.status 
                this.localEntireDock[this.numberOfRecords].connectionStatus = obj.connnectionStatus === 'CONNECTED' ? 'fa-solid fa-plug' : 'fa-solid fa-circle-xmark'

                this.refreshAllTables()

                this.numberOfRecords += 1
              }
            })
            this.allFinished = true
            this.localEntireDock.forEach((item) => {
              if (docksResponse.dock_status === 'CLOSED') {
               /* item.color = 'grey'
                if (!isNil(this.$refs.succeededOperationModal)) {
                  this.$refs.succeededOperationModal.show()
                }*/
                this.allFinished = false
                if (!item.colorClass.includes('-light')) {
                  item.colorClass = item.colorClass + "-light"
                }
              }
              if (item.status === 'ONGOING') {
                this.allFinished = false
              }
              if (item.colorClass === 'obu-card-green') {
                this.noRed = false
              }
            })
           /* if (docksResponse.dock_status === 'CLOSED') {
              clearInterval(this.timer)
            }*/
          //  this.allGreyAndDisabled = docksResponse.dock_status === 'CLOSED';
            this.splitDock()
            this.refreshAllTables()

          } else if (!_.isNil(docksResponse.dock)
              && isEmpty(first(docksResponse.dock))
              && docksResponse.dock_status === 'OPEN'
              && !(first(this.localEntireDock).obu_id.trim().length === 0)) {
            if (!isNil(this.$refs.suddenResetDockModal)) {
              this.$refs.suddenResetDockModal.show()
            }
            this.initializeLocalDock()
          } else {
            this.initializeLocalDock()
          }
        }
      }).finally(() => {
        setTimeout(() => {
          this.$root.$emit('activeLoader', false)
        }, 1500)
      }).catch((error) => {
        this.$root.$emit('errorNotification', 'telepassServiceError')
        console.log(error)
      })
    },
    refreshAllTables() {
      if (!_.isNil(this.$refs.monitorTable1)) {
        this.$refs.monitorTable1.refresh()
      }
      if (!_.isNil(this.$refs.monitorTable2)) {
        this.$refs.monitorTable2.refresh()
      }
      if (!_.isNil(this.$refs.monitorTable3)) {
        this.$refs.monitorTable3.refresh()
      }
      if (!_.isNil(this.$refs.monitorTable4)) {
        this.$refs.monitorTable4.refresh()
      }
    },
    splitArrayIntoChunksOfLen(arr, len) {
      var chunks = [], i = 0, n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    },
    verificationOfEquipment() {
      clearInterval(this.timer)

      this.localEntireDock.forEach((item) => {
        if (item.colorClass === 'obu-card-green') {
          item.verified = true;
        }
      })

      this.$refs.verifiedModal.show()
      this.allVerified = true
    },
    async associateVehicle() {
      let dockBody = {}
     /* await Vue.myGet(this, '/flasher_version/' + this.flasher.id + '/active', dockBody).then(async (flasherVersion) => {
        await Vue.myGet(this, '/flasher/' + this.flasher.id).then(async (flasher) => {
          const operation = {
            flasher_serial: flasher.flasher_serial,
            status_id: flasher.active_status_id,
            operation_code_id: 16,
            firmware_version_id: flasherVersion.id_version,
            vehicle_type_id: this.selectedVehicle
          }*/
          await Vue.myGet(this, '/dock/fs/' + this.flasher.flasher_serial).then(async (response) => {
            dockBody = response
           // dockBody.vehicle_type_id = this.selectedVehicle
            delete dockBody.dock_unpacked
            delete dockBody.id
            await Vue.myPost('/dock', dockBody).then(async () => {
            /*  operation.obu_id = '-'
              await Vue.myPost('/operation', operation).then(async (response) => {
                if (response.status === 500) {
                  this.$root.$emit('errorNotification', 'telepassServiceError', null, response.data.errMsg)
                  operation.operation_code_id = 18
                  await Vue.myPost('/operation', operation).then(async () => {
                    dockBody.dock = new Array(parseInt(this.totalNumberOfRecords)).fill({});
                    await Vue.myPost('/dock', dockBody).then(() => {
                    }).catch(() => {
                      this.$root.$emit('errorNotification')
                    })
                  }).catch(() => {
                    this.$root.$emit('errorNotification')
                  })
                } else {*/
                  this.operationComplete = true
               /* }
              }).finally(() => {
                this.$root.$emit('activeLoader', false)
              }).catch(() => {
                this.$root.$emit('errorNotification')
              })*/
            }).catch(() => {
              this.$root.$emit('errorNotification')
         /*   }).catch(() => {
              this.$root.$emit('errorNotification')
            })
          }).catch((error) => {
            this.root.$emit('errorNotification')
            console.log(error)
          })*/
        })
      })
    },
    selectFirstRedObu() {
      clearInterval(this.timer)
      let foundItem = _.find(this.localEntireDock, item => item.colorClass === 'obu-card-red' && !item.verified)
      if (!_.isNil(foundItem)) {
        this.$refs.alertErrorOnObuModal.show()
        foundItem.showTextInputToVerifyObu = true
        this.splitDock()
        this.selectedVerificableObu = foundItem
        this.selectedObuPosition = this.localEntireDock.indexOf(foundItem) + 1
        this.focusOnObu()
      }
      this.noRed = _.isNil(_.find(this.localEntireDock, obj => obj.colorClass === 'obu-card-red' && !obj.verified && foundItem.obu_id !== obj.obu_id))
    },
    resetVerification() {
      let foundItem = _.find(this.localEntireDock, item => item.showTextInputToVerifyObu === true)

      if (!_.isNil(foundItem)) {
        foundItem.showTextInputToVerifyObu = false
        this.splitDock()
        this.selectedVerificableObu = {
          inserted: '',
          obu_id: ''
        }
        this.selectedObuPosition = ''
        this.noRed = false
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.showTextInputToVerifyObu) return 'yellow-background'
    },
    focusOnObu() {
      this.$refs.alertErrorOnObuModal.hide()
      this.noRed = _.isNil(_.find(this.localEntireDock, obj => obj.colorClass === 'obu-card-red' && !obj.verified))
      if (!this.noRed) {
        this.$refs.alertErrorOnObuModal.show()
      }
      if (!_.isNil(this.$refs.inputObuId)) {
        setTimeout(() => {
          this.$nextTick(() => this.$refs.inputObuId.focus());
        }, 800);
      }
    },
    splitDock() {
      let chunksLength = Math.max(Math.ceil(this.numberOfRecords/4),this.chunksLength)
      const splitted = this.splitArrayIntoChunksOfLen(this.localEntireDock, chunksLength)
      this.pieceOfDock1 = splitted[0]
      this.pieceOfDock2 = splitted[1]
      this.pieceOfDock3 = splitted[2]
      this.pieceOfDock4 = splitted[3]
    },
    async resetDock() {
     /* this.flasher.desired_status_id = this.status.find(item => item.Name === 'WAIT_FOR_INIT').id
      Vue.put('/flasher/' + this.flasher.id.toString(), this.flasher).then(async (response) => {
        this.$root.$emit('activeLoader', false)

        if (response.status !== 200 && response.status !== 204) {
          this.$root.$emit('errorNotification')
        }*/
        await Vue.myGet(this, '/dock/fs/' + this.flasher.flasher_serial).then(async (response) => {
          const dockBody = response
          delete dockBody.dock_unpacked
          delete dockBody.id
          dockBody.dock = new Array(parseInt(this.totalNumberOfRecords)).fill({});
          await Vue.myPost('/dock', dockBody).then(async () => {
            this.$refs.succeededOperationModal.hide()
            await this.getDock().then(async () => {
              await Vue.myGet(this, '/flasher_version/' + this.flasher.id + '/active', dockBody).then(async (flasherVersion) => {
                await Vue.myGet(this, '/flasher/' + this.flasher.id).then(async (flasher) => {
                  const operation = {
                    flasher_serial: flasher.flasher_serial,
                    status_id: flasher.active_status_id,
                    operation_code_id: 26,
                    obu_id: '-',
                    firmware_version_id: flasherVersion.id_version
                  }
                  await Vue.myPost('/operation', operation).then(async () => {
                    this.$root.$emit('successNotification')
                    this.allGreyAndDisabled = false;
                    this.allVerified = false;
                    this.operationComplete = false;
                    this.allFinished = false;
                    this.chooseFeature = false;
                    this.showChooseFeatureElement = false;
                    this.timer = setInterval(this.getDock, this.secondsDelay * 1000);
                  }).catch(() => {
                    this.$root.$emit('errorNotification')
                  })
                })
              })
            })
          }).catch(() => {
            this.$root.$emit('errorNotification')
          })
        }).catch(() => {
          this.$root.$emit('errorNotification')
        })
   /*   }).catch(() => {
        this.$root.$emit('activeLoader', false)
        this.$root.$emit('errorNotification')
      })*/
    }
  }
  ,
  props: [
    'fixed',
  ],
  watch: {
    '$route.params.id'() {
      this.$router.go()
      this.getDock()
      this.timer = setInterval(this.getDock, this.secondsDelay * 1000);
      Vue.setLocalStorage('timer', this.timer)
    },
    secondsDelay() {
      clearInterval(this.timer)
      this.timer = setInterval(this.getDock, this.secondsDelay * 1000);
      Vue.setLocalStorage('timer', this.timer)
    },
  }
}

</script>

<style>
@import '../assets/styles/monitor-style.css';
</style>

