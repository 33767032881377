<template>
  <div class="column-left mr-2">
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      label="Home"/>
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="SNMPRead"
            @change="$emit('updatePermission', maskValue('HOME', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      label="Gestione operatori"
        />
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="operatorsCreate && operatorsRead"
            :disabled="!operatorsRead"
            @change="$emit('updatePermission', maskValue('OPERATORS', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="operatorsRead"
            @change="$emit('updatePermission', maskValue('OPERATORS', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="operatorsUpdate && operatorsRead"
            :disabled="!operatorsRead"
            @change="$emit('updatePermission', maskValue('OPERATORS', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="operatorsDelete && operatorsRead"
            :disabled="!operatorsRead"
            @change="$emit('updatePermission', maskValue('OPERATORS', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      label="Gestione flasher"
        />
      </b-row>
      <b-row class="mb-2 content-margins distance-content">
        <b-form-checkbox
            :checked="flashersRead"
            @change="$emit('updatePermission', maskValue('FLASHERS', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="flashersUpdate && flashersRead"
            :disabled="!flashersRead"
            @change="$emit('updatePermission', maskValue('FLASHERS', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="flashersDelete && flashersRead"
            :disabled="!flashersRead"
            @change="$emit('updatePermission', maskValue('FLASHERS', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      label="Gestione versioni"/>
      </b-row>

      <b-row class="mb-2 distance-content">
        <b-form-checkbox
            :checked="versionsCreate && versionsRead"
            :disabled="!versionsRead"
            @change="$emit('updatePermission', maskValue('VERSIONS', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="versionsRead"
            @change="$emit('updatePermission', maskValue('VERSIONS', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="versionsUpdate && versionsRead"
            :disabled="!versionsRead"
            @change="$emit('updatePermission', maskValue('VERSIONS', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="versionsDelete && versionsRead"
            :disabled="!versionsRead"
            @change="$emit('updatePermission', maskValue('VERSIONS', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      label="Report"/>
      </b-row>
      <b-row class="distance-content">
        <b-form-checkbox
            :checked="reportRead"
            @change="$emit('updatePermission', maskValue('REPORTS', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>
    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      label="Monitor operazioni"/>
      </b-row>

      <b-row class="mb-2 distance-content">
        <b-form-checkbox
            :checked="monitorCreate && monitorRead"
            :disabled="!monitorRead"
            @change="$emit('updatePermission', maskValue('MONITOR', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="monitorRead"
            @change="$emit('updatePermission', maskValue('MONITOR', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="monitorUpdate && monitorRead"
            :disabled="!monitorRead"
            @change="$emit('updatePermission', maskValue('MONITOR', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="monitorDelete && monitorRead"
            :disabled="!monitorRead"
            @change="$emit('updatePermission', maskValue('MONITOR', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>

    <form class="mb-4">
      <b-row>
        <b-form-group class="mb-1"
                      label="Personalizzazione OBU"/>
      </b-row>
      <b-row class="mb-2 distance-content">
        <b-form-checkbox
            :checked="personalizationObuCreate && personalizationObuRead"
            :disabled="!personalizationObuRead"
            @change="$emit('updatePermission', maskValue('PERSONALIZATION_OBU', 'CREATE'))">
          <i class="fa fa-plus-circle crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="personalizationObuRead"
            @change="$emit('updatePermission', maskValue('PERSONALIZATION_OBU', 'READ'))">
          <i class="fa fa-search crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="personalizationObuUpdate && personalizationObuRead"
            :disabled="!personalizationObuRead"
            @change="$emit('updatePermission', maskValue('PERSONALIZATION_OBU', 'UPDATE'))">
          <i class="fa fa-edit crud-icon"/>
        </b-form-checkbox>
        <b-form-checkbox
            :checked="personalizationObuDelete && personalizationObuRead"
            :disabled="!personalizationObuRead"
            @change="$emit('updatePermission', maskValue('PERSONALIZATION_OBU', 'DELETE'))">
          <i class="fa fa-trash crud-icon"/>
        </b-form-checkbox>
      </b-row>
    </form>
  </div>
</template>

<script>
export default {
  name: "PermissionMask",
  props: [
    'SNMPRead',
    'operatorsCreate',
    'operatorsRead',
    'operatorsUpdate',
    'operatorsDelete',
    'flashersRead',
    'flashersUpdate',
    'flashersDelete',
    'versionsCreate',
    'versionsRead',
    'versionsUpdate',
    'versionsDelete',
    'reportRead',
    'monitorCreate',
    'monitorRead',
    'monitorUpdate',
    'monitorDelete',
    'personalizationObuCreate',
    'personalizationObuRead',
    'personalizationObuUpdate',
    'personalizationObuDelete',
  ],
}
</script>

<style scoped>
.distance-content {
  justify-content: space-between;
}

.content-margins {
  padding-right: 33%;
}

</style>
