import location from '../../prop/location'
import myUsefulFunctions from "@/plugin/my-useful-functions";

const axios = require('axios')
let BASE_URL = ''

function checkToken(Vue) {
    const session = Vue.getLocalStorage('session')
    axios.defaults.headers.common.Authorization = session && session.token
    axios.defaults.baseURL = BASE_URL
}

switch (location) {
    case 'local':
        BASE_URL = 'https://flasher-dev.awtech.it/k1'
        // BASE_URL = 'http://localhost:8089/k1'
        break
    case 'prod':
        BASE_URL = 'https://flasher.awtech.it/k1'
        break
    case 'telepass_test':
        BASE_URL = 'https://k1flasherserver.test.gcp.telepass.com/k1'
        break
    case 'telepass_prod':
        BASE_URL = 'https://k1flasherserver.prod.gcp.telepass.com/k1'
        break
    default:
        BASE_URL = ''
        break
}

const MyServerInteraction = {
    install(Vue) {
        Vue.myGet = (it, path, obj, reponseType) => {
            checkToken(Vue)

            const url = BASE_URL + path
            return axios.get(url,
            reponseType ? {
                responseType:'blob'
            }: null).then((response) => {
                if (response && response.data && response.data[obj]) {
                    return response.data[obj]
                } else if (response && response.data) {
                    return response.data
                }
                return response
            }).catch(error => {
                return error.response
            })
        },
            Vue.getBaseUrl = () => {
                return BASE_URL
            },
            Vue.put = (path, obj) => {
                const url = BASE_URL + path
                return axios.put(url, obj).catch(error => {
                    return error.response
                })
            }

        Vue.myPost = (path, obj) => {
            checkToken(Vue)

            const url = BASE_URL + path
            return axios.post(url, obj).then((response) => {
                return response
            }).catch(error => {
                return error.response
            })
        }

        Vue.myLogin = (username, password) => {
            checkToken(Vue)
            const url = BASE_URL + '/login'
            return axios.get(url, {
                auth: {username, password}
            }).then(async (response) => {
                const session = response.data
                Vue.setLocalStorage('session', session)
                checkToken(Vue)
                let flashers = await myUsefulFunctions.getNotRemovedFlashers()
                Vue.setLocalStorage('flashers', flashers)
                return response
            }).catch((error) => {
                return error.response
            })
        }

        Vue.myDelete = (path) => {
            const url = BASE_URL + path
            return axios.delete(url).then((response) => {
                return response
            }).catch(error => {
                return error.response
            })
        }


    },

}
export default MyServerInteraction
