<template>
  <div id="login" class="mw-100 pb-5 pt-5">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col cols="12" sm="12" md="6" lg="4" xl="4">
          <b-row align-h="center" class="mb-4">
            <img class="logo-size" src="@/assets/logo.png">
          </b-row>
          <b-row align-h="center">
            <b-col cols="12" sm="9" md="12" lg="12" xl="12">
              <!-- User -->
              <b-form-group>
                <b-form-input v-model="input.username"
                              :disabled="showChangePwdButton"
                              class="custom-text-field box-style"
                              v-on:keyup.enter="doLogin"
                              id="input-username"
                              autocomplete="off"
                              type="text"
                              placeholder="Username">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="9" md="12" lg="12" xl="12">
              <!-- Password -->
              <b-form-group>
                <b-form-input v-model="input.password"
                              :disabled="showChangePwdButton"
                              class="custom-text-field box-style"
                              id="input-password"
                              autocomplete="off"
                              v-on:keyup.enter="doLogin"
                              type="password"
                              placeholder="Password">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-collapse id="my-collapse-change-pwd">
            <b-row align-h="center">
              <b-col cols="12" sm="9" md="12" lg="12" xl="12">
                <b-form-group>
                  <b-form-input v-model="newPwd"
                                type="password"
                                :state="newPwd.length > 0 ? newPasswordState : null"
                                aria-describedby="input-live-feedback"
                                class="custom-text-field box-style"
                                autocomplete="off"
                                placeholder="Nuova password">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                                           v-if="newPwd.length < 6 && newPwd !== input.password">
                    La password deve essere composta da almeno 5 caratteri
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback"
                                           v-if="newPwd === input.password">
                    La nuova password non può essere uguale alla vecchia
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="9" md="12" lg="12" xl="12">
                <b-form-group>
                  <b-form-input v-model="confirmNewPwd"
                                :state="confirmNewPwd.length > 4 ? repeatPasswordState : null"
                                aria-describedby="input-live-feedback"
                                class="custom-text-field box-style"
                                type="password"
                                autocomplete="off"
                                placeholder="Conferma nuova password">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                                           v-if="confirmNewPwd.length >= newPwd.length && confirmNewPwd !== newPwd">
                    La password non corrisponde a quella inserita in precedenza
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback"
                                           v-if="confirmNewPwd === input.password">
                    La nuova password non può essere uguale alla vecchia
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-collapse>
          <b-row>
            <b-col cols="12" sm="9" md="12" lg="12" xl="12">
              <b-button v-show="showChangePwdButton && !showChangePwdCollapse"
                        id="change-password-button"
                        type="submit"
                        class="box-style"
                        v-b-toggle.my-collapse-change-pwd
                        :class="checkIsActive ? 'btn-custom-color' : ''"
                        @click="showChangePwdCollapse = true;"
                        :disabled="!input.username || !input.password">
                Cambio password
              </b-button>
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-col cols="12" sm="9" md="12" lg="12" xl="12">
              <b-button id="login-button"
                        type="submit"
                        v-if="!showChangePwdButton || showChangePwdCollapse"
                        class="box-style"
                        :class="checkIsActive ? 'btn-custom-color' : ''"
                        @click="doLogin"
                        v-on:keyup.enter="doLogin"
                        :disabled="!showChangePwdCollapse ? !input.username || !input.password : !input.username || !input.password || !newPasswordState || !repeatPasswordState">
                Accedi
              </b-button>
            </b-col>
          </b-row>

          <b-row align-h="center">
            <h6 class="question" @click="showAlertMessage = true">Hai dimenticato la password?</h6>
          </b-row>
        </b-col>
      </b-row>
      <b-modal
          ok-only
          v-model="showAlertMessage"
          title="Attenzione"
      >
        <h6>
          Per cambiare la password, è necessario contattare l'amministratore.
        </h6>

      </b-modal>
    </b-container>
  </div>

</template>
<script>
import Vue from "vue";

export default {
  name: 'Login',
  data() {
    return {
      isActive: false,
      input: {
        username: "",
        password: ""
      },
      showChangePwdButton: false,
      showChangePwdCollapse: false,
      newPwd: '',
      confirmNewPwd: '',
      showAlertMessage: false
    }
  },
  methods: {
    async doLogin() {
      if (this.$route.name === '/') {
        await Vue.myLogin(this.input.username, this.input.password).then(async (response) => {
          if (Vue.getLocalStorage('session') && !Vue.getLocalStorage('session').valid_pwd) {
            if (this.newPwd !== '' && this.confirmNewPwd !== '') {
              await this.changePassword(this.input.password, this.newPwd)
              this.updateLocalStorage()
              await this.$router.push({path: '/home'});
            } else {
              this.showChangePwdButton = true
              this.$root.$emit('errorNotification', 'expiredPassword')
            }
          } else if (response.status === 401 || (response.status === 200 && Vue.getLocalStorage('session').operator.auth_mask[0] === '0'))
            this.$root.$emit('errorNotification', 'accessDenied')
          else if (response.status === 200) {
            this.updateLocalStorage()
            this.$root.$emit('successNotification', 'login')
            await this.$router.push({path: '/home'});
          } else if (response.status === 400)
            this.$root.$emit('errorNotification', 'wrongPassword')
          else if (response.status === 404)
            this.$root.$emit('errorNotification', 'unknownOperator')

        })
      }
    },
    async changePassword(oldPw, newPw) {
      var session = Vue.getLocalStorage('session')
      session.operator.pwd = newPw
      session.operator.old_pwd = oldPw
      await Vue.put('/operator/' + session.operator.id.toString(), session.operator).then(
          async (response) => {
            if (response.status !== 200 && response.status !== 204) {
              this.$root.$emit('activeLoader', false)
              if (response.status === 400)
                this.$root.$emit('errorNotification', 'wrongPassword')
              else this.$root.$emit('errorNotification')
            } else {
              Vue.setLocalStorage('session', session)
              this.$root.$emit('activeLoader', false)
              this.$root.$emit('successNotification')
            }
          })
    },
    updateLocalStorage() {
      Vue.setLocalStorage('name', this.input.username);
      Vue.setLocalStorage('logged', true);
      Vue.setLocalStorage('timer', '');
    }
  },
  created() {
    Vue.setLocalStorage('logged', false);
    this.$root.$refs.Login = this;
  },
  computed: {
    checkIsActive() {
      return this.input.password && this.input.username
    },
    newPasswordState() {
      return this.newPwd.length > 4 && this.newPwd.length < 1000 && this.newPwd !== this.input.password
    },
    repeatPasswordState() {
      return this.confirmNewPwd.length === this.newPwd.length && this.confirmNewPwd === this.newPwd && this.confirmNewPwd !== this.input.password
    }
  },
  watch: {
    logged() {
      if (!this.logged) {
        this.$router.push('/')

      }
    }
  }
}
</script>

<style>

.logo-size {
  margin-top: 3rem;
  width: 16rem;
  border-radius: 25px;
}

/* bottone */
#login-button, #change-password-button {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem
}

.btn-custom-color {
  background: #FF5576;
  border: 0;
}

.custom-text-field {
  border: 1px white;
  padding-left: 30px;
}

.box-style {
  border-radius: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}


#input-username::placeholder, #input-password::placeholder {
  color: darkgrey;
}

.question {
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  margin-top: 1rem;
  cursor: pointer;
}

</style>
