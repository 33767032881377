<template>
  <b-container class="small-table-container">
    <b-row align-h="start">
      <b-col cols="12" sm="9" md="5" lg="4" xl="3">
        <div class="search">
          <i class="fa fa-search"></i>
          <b-form-input
              placeholder="Ricerca"
              class="search-input"
              v-model="searchWord"
              id="input-search"/>
        </div>
      </b-col>
      <div class="mb-3 ml-3">
        <b-button class="custom-table-button" v-b-toggle.my-collapse>
          <i class="fa fa-filter"></i>
        </b-button>
      </div>
      <b-col class="mb-3 mr-1">
        <b-button v-on:click="resetFilters()" class="ml-3 custom-table-button">
          Pulisci filtri
        </b-button>
      </b-col>
      <b-col :cols="!stackedModeOn ? 'auto' : ''" class="mb-3">
        <b-button v-if="session.operator.auth_mask[maskValue('OPERATORS', 'CREATE')] === '1'"
                  v-on:click="showAddModal = true" class="custom-table-button" :class="stackedModeOn ? 'ml-0' : 'ml-3'">
          Aggiungi
        </b-button>
      </b-col>
    </b-row>

    <b-collapse id="my-collapse">
      <b-row>
        <b-col cols="12" md="6" lg="6" xl="3">
          <label class="c-white">Operatori visualizzati:</label>

          <b-input-group class="search">
            <b-dropdown :text=selectedRemoved offset=""
                        v-model="selectedRemoved">
              <b-dropdown-item @click="selectedRemoved='Tutti'">Tutti</b-dropdown-item>
              <b-dropdown-item @click="selectedRemoved='Abilitati'">Abilitati</b-dropdown-item>
              <b-dropdown-item @click="selectedRemoved='Non abilitati'">Non abilitati</b-dropdown-item>

            </b-dropdown>
          </b-input-group>
        </b-col>
      </b-row>
    </b-collapse>

    <b-modal
        cancel-title="Annulla"
        no-close-on-backdrop
        v-model="showAddModal"
        size="xl"
        id="modal-add-operator"
        ref="modal"
        title="Aggiungi operatore"
        @ok="addOperator()"
        :ok-disabled='!firstNameState || !lastNameState || !usernameState || !companyState || !passwordState'
        @cancel="resetNewItem"
    >
      <b-row>
        <b-col :cols="stackedModeOn ? '12' : ''">
          <div :class="!stackedModeOn ? 'column-right' : ''" >
            <form ref="form">

              <b-form-group
                  label="Nome *"
              >
                <b-form-input
                    v-on:keypress="isLetter($event)"
                    :state="newItem.first_name.length > 0 ? firstNameState : null"
                    aria-describedby="input-live-feedback"
                    v-model.trim="newItem.first_name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.first_name.length >= 125">
                  Il campo nome deve contenere 125 caratteri al massimo
                </b-form-invalid-feedback>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.first_name.length > 0 && newItem.first_name.length < 3">
                  Il campo nome deve contenere almeno 3 caratteri
                </b-form-invalid-feedback>
              </b-form-group>
            </form>

            <form ref="form">
              <b-form-group
                  label="Cognome *"
              >
                <b-form-input
                    v-on:keypress="isLetter($event)"
                    :state="newItem.last_name.length > 0 ? lastNameState : null"
                    aria-describedby="input-live-feedback"
                    v-model.trim="newItem.last_name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.last_name.length >= 125">
                  Il campo cognome deve contenere 125 caratteri al massimo
                </b-form-invalid-feedback>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.last_name.length > 0 && newItem.last_name.length < 3">
                  Il campo nome deve contenere almeno 3 caratteri
                </b-form-invalid-feedback>
              </b-form-group>
            </form>
            <form ref="form">
              <b-form-group
                  label="Società"
              >
                <b-form-input
                    :state="newItem.company.length !== 0 ? companyState : null"
                    aria-describedby="input-live-feedback"
                    v-model.trim="newItem.company"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.company.length < 4">
                  Il campo società deve contenere almeno 4 caratteri
                </b-form-invalid-feedback>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.company.length > 50">
                  Il campo società deve contenere 50 caratteri al massimo
                </b-form-invalid-feedback>
              </b-form-group>
            </form>
            <form ref="form">
              <b-form-group
                  label="Username *"
              >
                <b-form-input
                    :state="newItem.username.length > 0 ? usernameState : null"
                    aria-describedby="input-live-feedback"
                    v-model.trim="newItem.username"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.username.length < 5">
                  Il campo username deve contenere almeno 5 caratteri
                </b-form-invalid-feedback>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.username.length >= 25">
                  Il campo username deve contenere 25 caratteri al massimo
                </b-form-invalid-feedback>
                <b-form-invalid-feedback id="input-live-feedback" v-if="checkExistingUsername">
                  L'username inserito è già presente in anagrafica
                </b-form-invalid-feedback>
              </b-form-group>
            </form>
            <form ref="form">
              <b-form-group
                  label="Password *"
              >
                <b-form-input
                    :state="newItem.pwd.length > 0 ? passwordState : null"
                    aria-describedby="input-live-feedback"
                    v-model.trim="newItem.pwd"
                    type="password"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.pwd.length < 5">
                  Il campo password deve contenere almeno 5 caratteri
                </b-form-invalid-feedback>
                <b-form-invalid-feedback id="input-live-feedback" v-if="newItem.pwd.length >= 1000">
                  Il campo password deve contenere 1000 caratteri al massimo
                </b-form-invalid-feedback>
              </b-form-group>
            </form>
          </div>
        </b-col>

        <b-col :cols="stackedModeOn ? '12' : ''">
          <div>
            <PermissionMask
                :s-n-m-p-read="newItem.auth_mask[maskValue('HOME', 'READ')] === '1'"
                :operators-create="newItem.auth_mask[maskValue('OPERATORS', 'CREATE')] === '1'"
                :operators-read="newItem.auth_mask[maskValue('OPERATORS', 'READ')] === '1'"
                :operators-update="newItem.auth_mask[maskValue('OPERATORS', 'UPDATE')] === '1'"
                :operators-delete="newItem.auth_mask[maskValue('OPERATORS', 'DELETE')] === '1'"
                :flashers-read="newItem.auth_mask[maskValue('FLASHERS', 'READ')] === '1'"
                :flashers-update="newItem.auth_mask[maskValue('FLASHERS', 'UPDATE')] === '1'"
                :flashers-delete="newItem.auth_mask[maskValue('FLASHERS', 'DELETE')] === '1'"
                :versions-create="newItem.auth_mask[maskValue('VERSIONS', 'CREATE')] === '1'"
                :versions-read="newItem.auth_mask[maskValue('VERSIONS', 'READ')] === '1'"
                :versions-update="newItem.auth_mask[maskValue('VERSIONS', 'UPDATE')] === '1'"
                :versions-delete="newItem.auth_mask[maskValue('VERSIONS', 'DELETE')] === '1'"
                :report-read="newItem.auth_mask[maskValue('REPORTS', 'READ')] === '1'"
                :monitor-create="newItem.auth_mask[maskValue('MONITOR', 'CREATE')] === '1'"
                :monitor-read="newItem.auth_mask[maskValue('MONITOR', 'READ')] === '1'"
                :monitor-update="newItem.auth_mask[maskValue('MONITOR', 'UPDATE')] === '1'"
                :monitor-delete="newItem.auth_mask[maskValue('MONITOR', 'DELETE')] === '1'"
                :personalization-obu-create="newItem.auth_mask[maskValue('PERSONALIZATION_OBU', 'CREATE')] === '1'"
                :personalization-obu-read="newItem.auth_mask[maskValue('PERSONALIZATION_OBU', 'READ')] === '1'"
                :personalization-obu-update="newItem.auth_mask[maskValue('PERSONALIZATION_OBU', 'UPDATE')] === '1'"
                :personalization-obu-delete="newItem.auth_mask[maskValue('PERSONALIZATION_OBU', 'DELETE')] === '1'"
                @updatePermission="newItem = editPermission(...arguments, newItem)"
            />
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-table
        id="operatorsTable"
        class="table"
        :items="items"
        :fields="fields"
        sort-icon-left
        striped
        responsive="sm"
        :fixed="fixed !== false ? true : null"
        :stacked="stackedModeOn"
        :current-page="currentPage"
        :per-page="perPage"
        :class="!stackedModeOn ? 'notStacked operators-table' : ''"
        :empty-text="'Non ci sono dati da visualizzare'"
        :show-empty="true"
    >

      <template v-slot:cell(edit)="row">
        <b-row :class="session.operator.auth_mask[maskValue('OPERATORS', 'UPDATE')] === '0' ||
        session.operator.auth_mask[maskValue('OPERATORS', 'DELETE')] === '0' ?
        'row-edit-delete-alone' :
        'row-edit-delete'">
          <b-button
              :disabled="row.item.removed"
              v-show="row.item.username !== 'superadmin' &&
             session.operator.auth_mask[maskValue('OPERATORS', 'UPDATE')] === '1'"
              @click="showEditModal = true; cloneRowEdit(row)">
            <i class="fa fa-edit"/>
          </b-button>
          <b-button
              class="enable-disable"
              v-b-tooltip.hover title='Disabilita'
              v-show="row.item.username !== 'superadmin' &&
             session.operator.auth_mask[maskValue('OPERATORS', 'DELETE')] === '1'"
              v-if="!row.item.removed"
              :disabled="row.item.removed"
              @click=" cloneRowEdit(row); showDeleteModal = true">
            <i class="fa fa-trash-alt"/>
          </b-button>
          <b-button
              class="enable-disable"
              v-b-tooltip.hover title='Abilita'
              v-if="row.item.removed &&
             session.operator.auth_mask[maskValue('OPERATORS', 'DELETE')] === '1'"
              @click="cloneRowEdit(row); showRestoreModal = true">
            <i class="fa fa-trash-restore-alt "/>
          </b-button>
        </b-row>

        <b-modal
            cancel-title="Annulla"
            v-model="showDeleteModal"
            ref="modal"
            title="Disabilita operatore"
            @ok="rowToUpdate.item.removed = true; editRow(rowToUpdate)"
        >
          <div>Sei sicuro di voler disabilitare l'operatore {{ rowToUpdate.item.username }}?</div>
        </b-modal>
        <b-modal
            cancel-title="Annulla"
            v-model="showRestoreModal"
            ref="modal"
            title="Abilita operatore"
            @ok="rowToUpdate.item.removed = false; editRow(rowToUpdate)"
        >
          <div>Sei sicuro di voler abilitare l'operatore {{ rowToUpdate.item.username }}?</div>
        </b-modal>
        <b-modal
            cancel-title="Annulla"
            no-close-on-backdrop
            size="xl"
            v-model="showEditModal"
            ref="modal"
            title="Modifica operatore"
            @ok="rowToUpdate.item.pwd = pwd; editRow(rowToUpdate)"
            :ok-disabled='!firstNameState || !lastNameState || !usernameState || !companyState'
        >
          <b-row>
            <b-col class="column-right">
              <form ref="form">
                <b-form-group
                    label="Nome *"
                >
                  <b-form-input
                      v-on:keypress="isLetter($event)"
                      :state="rowToUpdate.item.first_name.length > 0 ? firstNameState : null"
                      aria-describedby="input-live-feedback"
                      v-model.trim="rowToUpdate.item.first_name"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="rowToUpdate.item.first_name.length >= 125">
                    Il campo nome deve contenere 125 caratteri al massimo
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="rowToUpdate.item.first_name.length > 0 && rowToUpdate.item.first_name.length < 3">
                    Il campo nome deve contenere almeno 3 caratteri
                  </b-form-invalid-feedback>
                </b-form-group>
              </form>
              <form ref="form">
                <b-form-group
                    label="Cognome *"
                >
                  <b-form-input
                      v-on:keypress="isLetter($event)"
                      :state="rowToUpdate.item.last_name.length > 0 ? lastNameState : null"
                      aria-describedby="input-live-feedback"
                      v-model.trim="rowToUpdate.item.last_name"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="rowToUpdate.item.last_name.length >= 125">
                    Il campo cognome deve contenere 125 caratteri al massimo
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="rowToUpdate.item.last_name.length > 0 && rowToUpdate.item.last_name.length < 3">
                    Il campo cognome deve contenere almeno 3 caratteri
                  </b-form-invalid-feedback>
                </b-form-group>
              </form>
              <form ref="form">
                <b-form-group
                    label="Società"
                >
                  <b-form-input
                      :state="company.length > 0 ? companyState : null"
                      aria-describedby="input-live-feedback"
                      v-model="company"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="company.length < 4">
                    Il campo società deve contenere almeno 4 caratteri
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="company.length > 50">
                    Il campo società deve contenere 50 caratteri al massimo
                  </b-form-invalid-feedback>
                </b-form-group>
              </form>
              <form ref="form">
                <b-form-group
                    label="Username *"
                >
                  <b-form-input
                      :disabled="rowToUpdate.item.username === session.operator.username"
                      :state="rowToUpdate.item.username.length > 0 ? usernameState : null"
                      aria-describedby="input-live-feedback"
                      v-model.trim="rowToUpdate.item.username"
                  ></b-form-input>
                  <b-form-valid-feedback id="input-live-feedback"
                                         v-if="rowToUpdate.item.username === session.operator.username">
                    Il tuo username non può essere modificato
                  </b-form-valid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="rowToUpdate.item.username.length < 5">
                    Il campo username deve contenere almeno 5 caratteri
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="rowToUpdate.item.username.length >= 25">
                    Il campo username deve contenere 125 caratteri al massimo
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="checkExistingUsername">
                    L'username inserito è già presente in anagrafica
                  </b-form-invalid-feedback>
                </b-form-group>
              </form>
              <form ref="form">
                <b-form-group
                    label="Password *"
                >
                  <b-form-input
                      :state="pwd.length > 0 ? passwordStateMaxChar : null"
                      aria-describedby="input-live-feedback"
                      v-model.trim="pwd"
                      type="password"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="pwd.length < 5">
                    Il campo password deve contenere almeno 5 caratteri
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="pwd.length >= 1000">
                    Il campo password deve contenere 1000 caratteri al massimo
                  </b-form-invalid-feedback>
                </b-form-group>
              </form>
            </b-col>
            <b-col class="column-left">
              <PermissionMask
                  :s-n-m-p-read="rowToUpdate.item.auth_mask[maskValue('HOME', 'READ')] === '1'"
                  :operators-create="rowToUpdate.item.auth_mask[maskValue('OPERATORS', 'CREATE')] === '1'"
                  :operators-read="rowToUpdate.item.auth_mask[maskValue('OPERATORS', 'READ')] === '1'"
                  :operators-update="rowToUpdate.item.auth_mask[maskValue('OPERATORS', 'UPDATE')] === '1'"
                  :operators-delete="rowToUpdate.item.auth_mask[maskValue('OPERATORS', 'DELETE')] === '1'"
                  :flashers-read="rowToUpdate.item.auth_mask[maskValue('FLASHERS', 'READ')] === '1'"
                  :flashers-update="rowToUpdate.item.auth_mask[maskValue('FLASHERS', 'UPDATE')] === '1'"
                  :flashers-delete="rowToUpdate.item.auth_mask[maskValue('FLASHERS', 'DELETE')] === '1'"
                  :versions-create="rowToUpdate.item.auth_mask[maskValue('VERSIONS', 'CREATE')] === '1'"
                  :versions-read="rowToUpdate.item.auth_mask[maskValue('VERSIONS', 'READ')] === '1'"
                  :versions-update="rowToUpdate.item.auth_mask[maskValue('VERSIONS', 'UPDATE')] === '1'"
                  :versions-delete="rowToUpdate.item.auth_mask[maskValue('VERSIONS', 'DELETE')] === '1'"
                  :report-read="rowToUpdate.item.auth_mask[maskValue('REPORTS', 'READ')] === '1'"
                  :monitor-create="rowToUpdate.item.auth_mask[maskValue('MONITOR', 'CREATE')] === '1'"
                  :monitor-read="rowToUpdate.item.auth_mask[maskValue('MONITOR', 'READ')] === '1'"
                  :monitor-update="rowToUpdate.item.auth_mask[maskValue('MONITOR', 'UPDATE')] === '1'"
                  :monitor-delete="rowToUpdate.item.auth_mask[maskValue('MONITOR', 'DELETE')] === '1'"
                  :personalization-obu-create="rowToUpdate.item.auth_mask[maskValue('PERSONALIZATION_OBU', 'CREATE')] === '1'"
                  :personalization-obu-read="rowToUpdate.item.auth_mask[maskValue('PERSONALIZATION_OBU', 'READ')] === '1'"
                  :personalization-obu-update="rowToUpdate.item.auth_mask[maskValue('PERSONALIZATION_OBU', 'UPDATE')] === '1'"
                  :personalization-obu-delete="rowToUpdate.item.auth_mask[maskValue('PERSONALIZATION_OBU', 'DELETE')] === '1'"
                  @updatePermission="rowToUpdate.item = editPermission(...arguments, rowToUpdate.item)"
              />
            </b-col>
          </b-row>
        </b-modal>
      </template>
    </b-table>

    <b-row>
      <b-col>
        <b-pagination
            class="my-pagination "
            :per-page="perPage"
            v-model="currentPage"
            :size="stackedModeOn ? 'sm' : 'md'"
            aria-controls="flasherTable"
            :total-rows="numberOfRows"
        >
        </b-pagination>
      </b-col>
      <b-col cols="5" sm="1" md="3">
        <b-form-group horizontal :label="''" :label-cols="5">
          <b-form-select :options="pageOptions" v-model="perPage"/>
        </b-form-group>
      </b-col>
      <b-col cols="7" sm="2" md="2" lg="auto">
        <b-button
            class="my-button"
            @click="excel('Operators')"> Excel
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import PermissionMask from "@/components/vue-plugin/PermissionMask";
import myUsefulFunctions from "@/plugin/my-useful-functions";

export default {
  components: {PermissionMask},
  data() {
    return {
      session: '',
      pwd: '',
      showEditModal: false,
      showAddModal: false,
      rowToUpdate: {},
      currentRow: '',
      searchWord: null,
      originalItems: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      containerClass: '',
      stackedModeOn: null,
      sortDesc: false,
      fields: [
        {key: 'edit', label: ''},
        {key: 'first_name', label: 'Nome', sortable: true},
        {key: 'last_name', label: 'Cognome', sortable: true},
        {key: 'company', label: 'Società', sortable: false},
        {key: 'username', label: 'Username', sortable: false}
      ],
      items: [
        {
          first_name: '',
          company: '',
          last_name: '',
          username: '',
          auth_mask: '',
          operator_status: '',
          pwd: '',
        }
      ],
      newItem: {},
      checkExistingUsername: false,
      showDeleteModal: false,
      showRestoreModal: false,
      selectedRemoved: 'Abilitati',
      company: ''
    }
  },
  computed: {
    numberOfRows() {
      return this.items.length
    },
    firstNameState() {
      if (this.showEditModal)
        return this.rowToUpdate.item.first_name.length > 2 && this.rowToUpdate.item.first_name.length <= 125
      return this.newItem.first_name.trim().length <= 125 && this.newItem.first_name.trim().length > 2
    },
    lastNameState() {
      if (this.showEditModal)
        return this.rowToUpdate.item.last_name.length > 2 && this.rowToUpdate.item.last_name.length <= 125
      return this.newItem.last_name.length <= 125 && this.newItem.last_name.length > 2
    },
    usernameState() {
      if (this.showEditModal)
        return this.rowToUpdate.item.username.length > 4 && this.rowToUpdate.item.username.length <= 25 && !this.checkExistingUsername
      return this.newItem.username.length <= 25 && !this.checkExistingUsername && this.newItem.username.length > 4
    },
    companyState() {

      if (this.showEditModal)
        return this.company.length === 0 || (this.company.length >= 4 && this.company.length < 50)
      return this.newItem.company.length === 0 || (this.newItem.company.length >= 4 && this.newItem.company.length < 50)
    },
    passwordState() {
      return this.newItem.pwd.length > 4 && this.newItem.pwd.length <= 1000
    },
    passwordStateMaxChar() {
      return this.pwd.length <= 1000 && (this.pwd.length > 4 || this.pwd.length === 0)
    },
  },
  created() {
    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md'||  size === 'lg'
    this.session = Vue.getLocalStorage('session')
    this.resetNewItem()
    this.resetRowToUpdate()
    this.fetchData()
  },
  methods: {
    fetchData(query) {
      return Vue.myGet(this, query || '/operator?removed=false', 'operators').then((operators) => {
        this.items = operators
        this.originalItems = operators
        return operators
      })
    },
    resetFilters() {
      this.searchWord = null
      this.items = this.originalItems
      this.selectedRemoved = 'Abilitati'
    },
    excel(fileName, customFields) {
      return Vue.excel(
          customFields || this.fields,
          this.items,
          fileName
      )
    },
    editRow(item) {
      if (this.pwd !== '')
        item.item.pwd = this.pwd
      else
        delete item.item.pwd
      if(this.company === '' && item.item.company !== undefined && item.item.company !== '')
        item.item.company = ''
      if (this.company !== '') {
        item.item.company = this.company
        this.company = ''
      }
      item.item.auth_mask = item.item.auth_mask.trim()
      if (item.item.auth_mask[this.maskValue('MONITOR', 'READ')] === '1' && item.item.auth_mask[this.maskValue('MONITOR', 'CREATE')] !== '1') {
        this.editPermission(this.maskValue('MONITOR', 'CREATE'), item.item)
      }
      Vue.put('/operator/' + item.item.id.toString(), item.item).then(
          async (response) => {
            if (response.status !== 200 && response.status !== 204) {
              this.$root.$emit('activeLoader', false)
              this.resetRowToUpdate()
              this.$root.$emit('errorNotification')
            } else {
              await this.fetchData().finally(() => {
                    this.$root.$emit('activeLoader', false)
                    this.resetRowToUpdate()
                    this.$root.$emit('successNotification')
                    if (this.session.operator.username === item.item.username) {
                      this.session.operator = item.item
                      Vue.setLocalStorage('session', this.session)
                      this.$root.$emit('changeMask')
                    }
                  }
              )
            }
          }).catch(() => {
        this.$root.$emit('activeLoader', false)
        this.resetRowToUpdate()
        this.$root.$emit('errorNotification')
      })
    },
    async addOperator() {
      if (this.newItem.first_name === ''
          && this.newItem.company === ''
          && this.newItem.last_name === ''
          && this.newItem.username === ''
          && this.newItem.pwd === ''
      )
        return
      this.$root.$emit('activeLoader', true)
      Vue.myPost('/operator', this.newItem).then(
          async (response) => {
            if (response.status !== 200) {
              this.$root.$emit('activeLoader', false)
              this.resetNewItem()
              this.$root.$emit('errorNotification')
            } else {
              await this.fetchData().finally(() => {
                    this.$root.$emit('activeLoader', false)
                    this.resetNewItem()
                    this.$root.$emit('successNotification')
                  }
              )
            }
          }).catch(() => {
        this.$root.$emit('activeLoader', false)
        this.resetNewItem()
        this.$root.$emit('errorNotification')
      })
      this.resetNewItem()
    },
    cloneRowEdit(row) {
      this.rowToUpdate = _.cloneDeep(row);
      this.rowToUpdate.item.pwd = ''
      if (_.isUndefined(this.rowToUpdate.item.company))
        this.company = ''
      else this.company = this.rowToUpdate.item.company
    },
    resetRowToUpdate() {
      this.rowToUpdate = {
        item: {
          first_name: '',
          company: '',
          last_name: '',
          username: '',
          auth_mask: '',
          operator_status: '',
          pwd: '',
        }
      }
      this.pwd = ''
      this.selectedRemoved = 'Abilitati'
    },
    resetNewItem() {
      this.newItem = {
        first_name: '',
        last_name: '',
        company: '',
        username: '',
        auth_mask: '11111101111111010011111111',
        operator_status: true,
        pwd: '',
      }
    },
    async existingUsername(username, id) {
      return await Vue.myGet(this, '/operator?username=' + username).then((response) => {
        if (id)
          return response.length !== 0 && response[0].id !== id
        return response.length !== 0
      })
    },
    editPermission(index, item) {
      String.prototype.replaceAt = function (index, number) {
        return this.substr(0, index) + number + this.substr(index + number.length);
      }
      item.auth_mask = item.auth_mask.replaceAt(index, item.auth_mask[index] === '1' ? '0' : '1').trim()
      return item
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    }
  },
  props: [
    'fixed',
  ],
  watch: {
    searchWord() {
      if (this.searchWord && this.searchWord !== '' && !_.isNull(this.searchWord) && this.originalItems && this.originalItems.length !== 0) {
        this.items = myUsefulFunctions.filterSearchWord(this.originalItems, this.fields, this.searchWord)
      } else this.items = this.originalItems
    },
    async 'newItem.username'() {
      this.checkExistingUsername = await this.existingUsername(this.newItem.username)
    },
    async 'rowToUpdate.item.username'() {
      this.checkExistingUsername = await this.existingUsername(this.rowToUpdate.item.username, this.rowToUpdate.item.id)
    },
    selectedRemoved() {
      if (this.selectedRemoved === 'Tutti')
        this.fetchData('/operator')
      else this.fetchData('/operator?removed=' + (this.selectedRemoved === 'Non abilitati').toString())
    }

  }

}
</script>

<style>
.operators-table > .table tr td:first-child, .operators-table > .table tr th:first-child {
  width: 8rem;
}

.search-input {
  text-overflow: clip;
  overflow: hidden;
  padding-left: 2.3rem;
}

.column-right {
  border-right: 0.1rem;
  border-right-color: #999999;
  border-right-style: solid;
  padding-right: 3rem;
}

.column-left {
  padding-left: 2rem;
  right: 0;
}

.crud-icon {
  color: #FF5576;
  font-size: 2rem;
}

.row-edit-delete {
  justify-content: space-evenly;
  margin-left: 0;
}

.row-edit-delete-alone {
  justify-content: center;
  margin-left: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
</style>
