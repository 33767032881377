<template>
  <b-container class="w-100" style="padding-top: 0" ref="footer" @mousemove="showButton" @mouseleave="hideButton">
    <transition :name="!showFooter ? 'fade-down' : 'fade-up'">
      <b-row class="b-c-gray pt-3" v-show="showFooter">
        <b-container style="justify-content: center">
          <b-row align-h="center" style="height: 0">
            <transition :name="showFooter ? 'fade-down' : 'fade-up'">
              <i class="my-button-footer-down fas fa-chevron-down c-green p-4 fa-3x"
                 v-show="showFooter && showButtons" v-on:click="checkFooter"/>
            </transition>
          </b-row>

          <b-container style="justify-content: center; width: 50%;">
            <b-row align-h="center" v-on:click="showButtons = true">
              <b-col>
                <h5 class="c-fuxia">Contatti</h5>
                <b-row class="mt-1">
                  <b-col cols="1" sm="1" md="1" lg="1" xl="1">
                    <i class="fas fa-phone"/>
                  </b-col>
                  <b-col style="padding-right: 0">
                    +39 050 0986268
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col cols="1" sm="1" md="1" lg="1" xl="1">
                    <i class="far fa-envelope"/>
                  </b-col>
                  <b-col cols="4">
                    info@awtech.it
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col cols="1" sm="1" md="1" lg="1" xl="1">
                    <i class="fas fa-external-link-alt"/>
                  </b-col>
                  <b-col cols="4">
                    <a href="https://www.awtech.it" class="white-link">www.awtech.it</a>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>


          </b-container>
        </b-container>
      </b-row>


    </transition>
    <b-row class=" align-footer-text">
      <b-container class="mw-100 m-2">
        <b-row align-h="center" style="height: 0">
          <transition :name="showFooter ? 'fade-down' : 'fade-up'">
            <i class="my-button-footer-up hover-enabled fas fa-chevron-up c-green p-4 fa-3x"
               v-show="!showFooter && showButtons" v-on:click="checkFooter"/>
          </transition>
        </b-row>
        <b-row align-h="center" v-on:click="showButtons = true" v-if="!isMobile">
          <b-col>
            <b-container>
              <b-row>Copyright © AWTech - K1 Flasher</b-row>
            </b-container>
          </b-col>
        </b-row>
        <b-row align-h="center" v-on:click="showButtons = true" v-if="isMobile">
          <b-col>
            Copyright © AWTech - K1 Flasher
          </b-col>


        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import Vue from "vue"

export default {
  name: "Footer",
  watch: {
    '$route'(to) {
      const session = Vue.getLocalStorage('name')
      const showFooter = Vue.getLocalStorage('showFooter')
      this.logged = session
      if (to.path === '/home') {
        this.showFooter = false
      } else {
        this.showFooter = showFooter
      }
    }
  },
  data() {
    const hasScrollbar = window.scrollY > 0
    const session = Vue.getLocalStorage('name')
    return {
      hasScrollbar,
      buttons: [],
      isMD: false,
      isMobile: true,
      showFooter: false,
      showButtons: false,
      logged: session
    }
  },
  methods: {
    showButton() {
      this.showButtons = true
    },
    hideButton() {
      this.showButtons = false
    },
    checkFooter() {
      this.showFooter = !this.showFooter
      if (this.isMobile) {
        this.showButtons = false
      }
      Vue.setLocalStorage('showFooter', this.showFooter)
      this.$nextTick(() => {
        if (this.showFooter) {
          window.scrollTo(0, document.body.scrollHeight)
        }
      })
    },
  }
}
</script>

<style scoped>
.w-100 {
  max-width: 100% !important;
}

.my-button-footer-down {
  position: relative;
  bottom: 90px;
  color: #FF5576;
  text-shadow: 0 0 3px black;
}

.my-button-footer-up {
  position: relative;
  bottom: 85px;
  color: #FF5576;
  text-shadow: 0 0 3px black;
}

.white-link {
  color: white;
}

.c-fuxia {
  color: #FF5576;
}

.align-footer-text {
  text-align: center;
}
</style>
