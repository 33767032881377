const myFlasherStatusTranslate = {
    statusTranslate(statusId) {
        switch (statusId) {
            case 0:
                return 'Nessuno stato'
            case 1:
                return 'Inizializzazione'
            case 2:
                return 'Pronto per aggiornamento'
            case 3:
                return 'Aggiornamento in corso'
        }
    }
}

export default myFlasherStatusTranslate
