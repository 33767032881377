<template>
  <div :class="showLink && !stackedModeOn ?
   'sidebar sidebar-open' :
   !showLink && !stackedModeOn
   ? 'sidebar sidebar-close'
   : showLink && stackedModeOn ?
   'sidebar sidebar-responsive' : 'sidebar sidebar-close' ">
    <nav>
      <b-nav>
        <div style="position: relative">
          <div style="position: absolute">
            <b-container
                v-show="(showSidebar && !versionSelect && !flashersSelect && !reportLogsSelect && !reportLogsSerialSelect && !operatorsSelect && !monitorOperationsSelect && !personalizationOBUSelect) "></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i class="fas fa-bars fa-lg" @click="showMenu()"/>
              </div>
            </b-col>
            <b-col v-show="showLink" class="sidebar-links">Menù</b-col>
          </b-row>
        </b-nav-item>
      </b-nav>
      <b-nav v-if="session && session.operator && session.operator.auth_mask[maskValue('OPERATORS', 'READ')] === '1'">
        <div style="position: relative">
          <div style="position: absolute">
            <b-container v-show="operatorsSelect"></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i class="fas fa-users fa-lg" @click="getOperators"/>
              </div>
            </b-col>
            <b-col class="sidebar-links" v-show="showLink" @click="getOperators" key="5">Anagrafica operatori</b-col>
          </b-row>
        </b-nav-item>
      </b-nav>
      <b-nav v-show="session && session.operator && session.operator.auth_mask[maskValue('FLASHERS', 'READ')] === '1'">
        <div style="position: relative">
          <div style="position: absolute">
            <b-container v-show="flashersSelect"></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i class="fas fa-hdd fa-lg" @click="getFlashers"/>
              </div>
            </b-col>
            <b-col class="sidebar-links" v-show="showLink" @click="getFlashers" key="2">Gestione flasher</b-col>

          </b-row>
        </b-nav-item>
      </b-nav>
      <b-nav v-if="session && session.operator && session.operator.auth_mask[maskValue('VERSIONS', 'READ')] === '1'">
        <div style="position: relative">
          <div style="position: absolute">
            <b-container v-show="versionSelect"></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i class="fas fa-clone fa-lg" @click="getVersions"></i>
              </div>
            </b-col>
            <b-col class="sidebar-links " v-show="showLink" @click="getVersions" key="1">Gestione versioni</b-col>
          </b-row>
        </b-nav-item>
      </b-nav>
      <!-- Blocco Timeouts -->
      <b-nav v-if="session && session.operator && session.operator.auth_mask[maskValue('TIMEOUT_CONF', 'READ')] === '1'">
        <div style="position: relative">
          <div style="position: absolute">
            <b-container v-show="timeoutConfig"></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i class="fas fa-clone fa-lg" @click="getTimeouts"></i>
              </div>
            </b-col>
            <b-col class="sidebar-links " v-show="showLink" @click="getTimeouts" key="6">Configurazione Timeouts</b-col>
          </b-row>
        </b-nav-item>
      </b-nav>
      <!-- Blocco Timeouts -->
      <b-nav v-if="session && session.operator && session.operator.auth_mask[maskValue('REPORTS', 'READ')] === '1'">
        <div style="position: relative">
          <div style="position: absolute">
            <b-container v-show="reportLogsSerialSelect"></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i class="fas fa-file fa-lg" @click="getReportLogsSerial"></i>
              </div>
            </b-col>

            <b-col class="sidebar-links " @click=" getReportLogsSerial" v-show="showLink" key="4">Report flasher</b-col>
          </b-row>
          <b-modal
              cancel-title="Annulla"
              v-model="showModalSerial"
              ref="modal"
              title="Scegli flasher"
              @ok="replaceRoute"
              :ok-disabled=" flashers && flashers.length > 0 && (selectedFlasher.length === 0 || selectedFlasher === null)"
          >
            <form ref="form">
              <b-form-group
                  v-if="flashers && flashers.length > 0"
                  label="Seleziona seriale"
              >
                <multiselect
                    id="flasher-serial-select"
                    style="height: 80%;width: 80%"
                    v-show="flashers && flashers.length > 0"
                    :options="flashers"
                    label="flasher_serial"
                    track-by="flasher_serial"
                    v-model="selectedFlasher"
                    placeholder="Seleziona seriale del flasher"
                ></multiselect>

              </b-form-group>
              <div v-if="flashers && flashers.length === 0">
                <h6>Non sono presenti flasher da selezionare</h6>
              </div>
            </form>
          </b-modal>
        </b-nav-item>
      </b-nav>
      <b-nav v-if="session && session.operator && session.operator.auth_mask[maskValue('REPORTS', 'READ')] === '1'">
        <div style="position: relative">
          <div style="position: absolute">
            <b-container v-show="reportLogsSelect"></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i id="report-logs" class="fas fa-file fa-lg" @click="getReportLogs"></i>
              </div>
            </b-col>
            <b-col class="sidebar-links " v-show="showLink" @click="getReportLogs" key="3">Report logs</b-col>
          </b-row>
        </b-nav-item>
      </b-nav>
      <b-nav v-if="session && session.operator && session.operator.auth_mask[maskValue('MONITOR', 'READ')] === '1'">
        <div style="position: relative">
          <div style="position: absolute">
            <b-container v-show="monitorOperationsSelect"></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i id="operation-monitor" class="fa-heart-pulse fa-solid fa-lg" @click="getMonitorOperations"></i>
              </div>
            </b-col>

            <b-col class="sidebar-links " @click="getMonitorOperations" v-show="showLink" key="4">Monitor operazioni</b-col>
          </b-row>
          <b-modal
              cancel-title="Annulla"
              v-model="showModalSerial"
              ref="flasherModal"
              title="Scegli flasher"
              @ok="replaceRoute"
              :ok-disabled=" flashers && flashers.length > 0 && (selectedFlasher.length === 0 || selectedFlasher === null)"
          >
            <form ref="form">
              <b-form-group
                  v-if="flashers && flashers.length > 0"
                  label="Seleziona seriale"
              >
                <multiselect
                    v-show="flashers && flashers.length > 0"
                    :options="flashers"
                    id="flasher-serial-select"
                    label="flasher_serial"
                    track-by="flasher_serial"
                    v-model="selectedFlasher"
                    placeholder="Seleziona seriale del flasher"
                ></multiselect>

              </b-form-group>
              <div v-if="flashers && flashers.length === 0">
                <h6>Non sono presenti flasher da selezionare</h6>
              </div>
            </form>
          </b-modal>
          <b-modal
              ref="warningFlasherNotSupportMonitoringModal"
              title="Funzione non disponibile"
              no-close-on-backdrop
              ok-only
              @ok="$route.name !== 'home' ? $router.push({path: '/home'}) : null"
          >
            <div style="text-align: center">La funzione non è disponibile per il tipo di software utilizzato dal flasher selezionato</div>
          </b-modal>
          <b-modal
              ref="warningFlasherNotAssociatedWithVersionModal"
              title="Flasher non associato a versione"
              no-close-on-backdrop
              ok-only
              @ok="$route.name !== 'home' ? $router.push({path: '/home'}) : null"
          >
            <div style="text-align: center">La funzione non è disponibile: il flasher non è associato a nessuna versione </div>
          </b-modal>
        </b-nav-item>
      </b-nav>
      <b-nav  v-if="session && session.operator && session.operator.auth_mask[maskValue('PERSONALIZATION_OBU', 'READ')] === '1'">
        <div style="position: relative">
          <div style="position: absolute">
            <b-container v-show="personalizationOBUSelect"></b-container>
          </div>
        </div>
        <b-nav-item>
          <b-row>
            <b-col cols="2">
              <div class="icon-div">
                <i id="operation-monitor" class="fa-feather-pointed fa-solid fa-lg" @click="getPersonalizationOBU"></i>
              </div>
            </b-col>

            <b-col class="sidebar-links " @click="getPersonalizationOBU" v-show="showLink" key="4">Personalizzazione OBU</b-col>
          </b-row>
          <b-modal
              cancel-title="Annulla"
              v-model="showModalSerial"
              ref="flasherModal"
              title="Scegli flasher"
              @ok="replaceRoute"
              :ok-disabled=" flashers && flashers.length > 0 && (selectedFlasher.length === 0 || selectedFlasher === null)"
          >
            <form ref="form">
              <b-form-group
                  v-if="flashers && flashers.length > 0"
                  label="Seleziona seriale"
              >
                <multiselect
                    v-show="flashers && flashers.length > 0"
                    :options="flashers"
                    id="flasher-serial-select"
                    label="flasher_serial"
                    track-by="flasher_serial"
                    v-model="selectedFlasher"
                    placeholder="Seleziona seriale del flasher"
                ></multiselect>

              </b-form-group>
              <div v-if="flashers && flashers.length === 0">
                <h6>Non sono presenti flasher da selezionare</h6>
              </div>
            </form>
          </b-modal>
        </b-nav-item>
      </b-nav>
    </nav>

      <h6 class="version-style">{{ version }}</h6>
  </div>
</template>

<script>
import Vue from "vue";
import _, {isNil} from 'lodash'
import '@/plugin/my-mixin'
import myAuthorizationMaskIndexes from "@/plugin/my-authorization-mask-indexes";
import myUsefulFunctions from "@/plugin/my-useful-functions";


export default {
  data: () => {
    return {
      version: JSON.stringify(require('/package.json').version).split('"')[1],
      showSidebar: false,
      showLink: false,
      versionSelect: false,
      flashersSelect: false,
      timeoutConfig: false,
      reportLogsSelect: false,
      reportLogsSerialSelect: false,
      monitorOperationsSelect: false,
      personalizationOBUSelect: false,
      showModalSerial: false,
      selectedFlasher: '',
      flashers: [],
      operatorsSelect: false,
      stackedModeOn: '',
      session: '',
      fetched: false
    }
  },
  methods: {
    showMenu() {
      this.resetSelection()
      if (this.showSidebar && this.showLink) {
        this.hide()
        if (this.$route.name !== 'home')
          this.$router.push('/home')
      } else this.show()
    },
    show() {
      this.showSidebar = true;
      setTimeout(() => {
        this.showLink = true;
      }, 10);
      if(this.stackedModeOn) this.$root.$emit('hideNet', true)
    },
    hide() {
      if(this.stackedModeOn)
        this.$root.$emit('hideNet', false)
      this.showSidebar = false;
      setTimeout(() => {
        this.showLink = false;
      }, 10);
    },
    getVersions() {
      if (!this.versionSelect) {
        this.resetSelection('versions')
        this.show()
      } else if (!this.showSidebar && !this.showLink) this.show()
      else this.hide()
      if ((this.$route.name !== 'versions' && !this.stackedModeOn) || (this.stackedModeOn && !this.showSidebar))
        this.$router.push('/home/versions')
    },
    getTimeouts() {
      if (!this.timeoutConfig) {
        this.resetSelection('timeouts')
        this.show()
      } else if (!this.showSidebar && !this.showLink) this.show()
      else this.hide()
      if((this.$route.name !== 'timeouts' && !this.stackedModeOn) || (this.stackedModeOn && !this.showSidebar))
        this.$router.push('/home/timeouts')
    },
    getFlashers() {
      if (!this.flashersSelect) {
        this.resetSelection('flashers')
        this.show()
      } else if (!this.showSidebar) {
        this.show();
      } else this.hide()
      if ((this.$route.name !== 'flasher' && !this.stackedModeOn) || (this.stackedModeOn && !this.showSidebar))
        this.$router.push('/home/flashers')
    },
    getReportLogs() {
      if (!this.reportLogsSelect) {
        this.resetSelection('logs')
        this.show()
      } else if (!this.showSidebar && !this.showLink) this.show()
      else this.hide()
      if ((this.$route.name !== 'reportLogs' && !this.stackedModeOn) || (this.stackedModeOn && !this.showSidebar))
        this.$router.push('/home/logs')
    },
    async getReportLogsSerial() {
      if (!this.reportLogsSerialSelect) {
        this.flashersSelect = false;
        this.versionSelect = false;
        this.reportLogsSelect = false;
        this.operatorsSelect = false;
        this.monitorOperationsSelect = false;
        this.personalizationOBUSelect = false;
        this.show()
      } else if (!this.showSidebar && !this.showLink) this.show()
      else this.hide()
      this.selectedFlasher = ''
      this.showModalSerial = true
      this.flashers = await myUsefulFunctions.getNotRemovedFlashers()
      Vue.setLocalStorage('flashers', this.flashers)
      this.reportLogsSerialSelect = true;
    },
    async getMonitorOperations() {
      if (!this.monitorOperationsSelect) {
        this.flashersSelect = false;
        this.versionSelect = false;
        this.reportLogsSelect = false;
        this.operatorsSelect = false;
        this.reportLogsSerialSelect = false;
        this.personalizationOBUSelect = false;
        this.show()
      } else if (!this.showSidebar && !this.showLink) this.show()
      else this.hide()
      this.selectedFlasher = ''
      this.showModalSerial = true
      this.flashers = await myUsefulFunctions.getNotRemovedFlashers()
      Vue.setLocalStorage('flashers', this.flashers)
      this.monitorOperationsSelect = true;
    },
    getOperators() {
      if (!this.operatorsSelect) {
        this.resetSelection('operators')
        this.show()
      } else if (!this.showSidebar && !this.showLink) this.show()
      else this.hide()
      if ((this.$route.name !== 'operators' && !this.stackedModeOn) || (this.stackedModeOn && !this.showSidebar))
        this.$router.push('/home/operators')
    },
    async getPersonalizationOBU() {
      if (!this.personalizationOBUSelect) {
        this.flashersSelect = false;
        this.versionSelect = false;
        this.reportLogsSelect = false;
        this.operatorsSelect = false;
        this.reportLogsSerialSelect = false;
        this.monitorOperationsSelect = false;
        this.show()
      } else if (!this.showSidebar && !this.showLink) this.show()
      else this.hide()
      this.selectedFlasher = ''
      this.showModalSerial = true
      this.flashers = await myUsefulFunctions.getNotRemovedFlashers()
      Vue.setLocalStorage('flashers', this.flashers)
      this.personalizationOBUSelect = true;
    },
    replaceRoute() {
      if (this.selectedFlasher !== '' && this.reportLogsSerialSelect)
        this.$router.replace('/home/logs/' + this.selectedFlasher.flasher_serial)
      else if (this.selectedFlasher !== '' && this.monitorOperationsSelect)
        this.$router.replace('/home/monitor_operations/' + this.selectedFlasher.flasher_serial)
      else if (this.selectedFlasher !== '' && this.personalizationOBUSelect)
        this.$router.replace('/home/personalization_obu/' + this.selectedFlasher.flasher_serial)
    },
    resetSelection(except) {
      this.versionSelect = except && except === 'versions';
      this.flashersSelect = except && except === 'flashers';
      this.reportLogsSelect = except && except === 'logs';
      this.reportLogsSerialSelect = except && except === 'logsSerial';
      this.operatorsSelect = except && except === 'operators';
      this.monitorOperationsSelect = except && except === 'monitorOperations';
      this.personalizationOBUSelect = except && except === 'personalizationObu';
      this.timeoutConfig = except && except === 'timeouts';
      },
    reloadSession() {
      this.session = Vue.getLocalStorage('session')

    }
  },
  async created() {
    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md'
    this.$root.$on('changeMask', () => {
      this.reloadSession()
    })
    this.session = Vue.getLocalStorage('session')
  },
  mounted() {
    this.$root.$on('updateFlashers', async () => {
      this.flashers = await myUsefulFunctions.getNotRemovedFlashers()
      Vue.setLocalStorage('flashers', this.flashers)
    })
  },
  watch: {
    async '$route'() {
      if (this.$route.name !== '/'){
        this.reloadSession()
        this.flashers = Vue.getLocalStorage('flashers')
      }
      if (this.$route.name === 'user' || (!this.stackedModeOn && this.$route.name === 'home')) {
        this.showSidebar = false
        this.showLink = false
        this.resetSelection()
      }
      if (this.$route.name === 'operators' && this.session && this.session.operator && this.session.operator.auth_mask[myAuthorizationMaskIndexes.CRUD('OPERATORS', 'READ')] === '0')
        await this.$router.push('/home')
      if (this.$route.name === 'timeouts' && this.session && this.session.operator && this.session.operator.auth_mask[myAuthorizationMaskIndexes.CRUD('TIMEOUT_CONF', 'READ')] === '0')
        await this.$router.push('/home')
      if (!this.stackedModeOn && this.$route.name === 'home')
        this.hide()
      if (this.showSidebar && this.stackedModeOn && this.$route.name !== 'home')
        await this.$router.push('/home')
    },
    selectedFlasher() {
      if (this.selectedFlasher !== '' && !_.isNil(this.selectedFlasher)) {
        if (this.monitorOperationsSelect) {
          if (this.selectedFlasher.swType !== 6 && this.selectedFlasher.swType !== 7 && this.selectedFlasher.swType !== 8
            && this.selectedFlasher.swType !== 9) {
            this.$refs.flasherModal.hide()
            this.$refs.warningFlasherNotSupportMonitoringModal.show()
            this.monitorOperationsSelect = false
          } else if (isNil(this.selectedFlasher.swType)) {
            this.$refs.flasherModal.hide()
            this.$refs.warningFlasherNotAssociatedWithVersionModal.show()
          }
        }
        this.hide()
      }
      if (_.isNull(this.selectedFlasher))
        this.selectedFlasher = ''
    },
    showSidebar() {
      if (this.showSidebar && this.stackedModeOn && this.$route.name !== 'home')
        this.$router.replace('/home')
    },
    session() {
      if (this.$route.name === 'operators' && this.session && this.session.operator && this.session.operator.auth_mask[myAuthorizationMaskIndexes.CRUD('OPERATORS', 'READ')] === '0')
        this.$router.push('/home')
    }
  },
}
</script>
<style scoped>

.sidebar {
  box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.25),
  inset 0px -11px 8px -10px rgba(0, 0, 0, 0.25);
  background-color: white;
  transition: all .5s ease-out;
}

.sidebar-open {
  min-width: 18rem;
}

.sidebar-close {
  width: 3.5rem;
}

.sidebar-responsive {
  width: 100%;
}

.fas, .fa-solid {
  cursor: pointer;
  display: inline;
  color: black;
}

.sidebar .control i {
  cursor: pointer;
  display: flex;
}

.sidebar.show {
  bottom: 0;
}

.sidebar-links {
  color: black;
  font-size: 1.1rem;
}

.container {
  position: absolute;
  width: 10px;
  margin-left: 0;
  height: 2.7rem;
  background-color: #0D4394;
  padding: 0;
}

.col-2 {
  max-width: unset;
}

.icon-div {
  width: 1rem
}

.version-style{
  position: absolute;
  bottom: 0px;
  margin-left: 10px;
  width: 100%;
}
</style>





