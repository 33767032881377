/* eslint-disable no-param-reassign */
import XLSX from 'xlsx'
import {find} from 'lodash'

const MyExcelFromDate = {
    install(Vue) {
        Vue.excel = (fields, items, filename, counters) => {
            const mainArray = []
            const fieldsArray = []
            const fieldsTitle = []
            const colums = []
            const rows = [{}]
            const counter = {}
            const skipFields = [
                'actions',
                'view',
                'select',
                'selected',
                'info',
                'edit',
                'delete'
            ]

            if (counters) {
                counters.forEach(obj => {
                    counter[obj.key] = 0
                })
            }

            if (fields && fields.length) {
                fields.forEach(field => {
                    if (field.key === 'success')
                        field.label = 'Operazione avvenuta con'
                    const skipCurrentField = find(skipFields, obj => obj === field.key)
                    if (!skipCurrentField && !field.skipInExcel && !field.hideInExcel) {
                        fieldsArray.push(field.key)
                        fieldsTitle.push(field.label)
                        colums.push({wch: (field.label && field.label.length) || 1})
                    }
                })
            }

            mainArray.push(fieldsTitle)

            if (items && items.length) {
                items.forEach(item => {
                    const row = []
                    fieldsArray.forEach((field, fieldIndex) => {
                        const skipCurrentField = find(
                            skipFields,
                            obj => obj === field.key
                        )
                        if (!skipCurrentField) {
                            let value;
                            if(field === 'description_excel' ) {
                                if (item[field] !== '' && item[field].split('\n').length === 4)
                                    rows.push({hpt: 65})
                                    else if(item[field] !== '' && item[field].split('\n').length !== 4)
                                    rows.push({hpt: 50})

                                else
                                    rows.push({hpt: 15})
                            }
                            if (field.split('.').length === 2) {
                                value = item[field.split('.')[0]][field.split('.')[1]]
                            } else if (field.split('.').length === 3) {
                                value = item[field.split('.')[0]][field.split('.')[1]][field.split('.')[2]]
                            } else if (field === 'versions') {
                                value = item['additions']['active_version_string']
                            } else if (field === 'success') {
                                if (item[field]) value = 'Successo'
                                else value = 'Errore'
                            } else value = item[`${field}`]
                            row.push(value)
                            if (counters) {
                                counters.forEach(obj => {
                                    counter[obj.key] +=
                                        obj.key === field && obj.increaseValue === value ? 1 : 0
                                })
                            }
                            const currentMaxLength = colums[fieldIndex].wch
                            const newMaxLength = (value && value.length) || 1
                            if (newMaxLength > currentMaxLength) {
                                colums[fieldIndex].wch = newMaxLength
                            }
                            if(field === 'description_excel' && item[field] !== '') {
                                colums[fieldIndex].wch = item[field].split('\n')[0].length
                            }
                        }
                    })
                    mainArray.push(row)
                })
            }

            if (counters) {
                counters.forEach(obj => {
                    mainArray.push([])
                    mainArray.push([`${[obj.label]}`], `${counter[obj.key]}`)
                })
            }

            const ws = XLSX.utils.aoa_to_sheet(mainArray)

            ws['!cols'] = colums
            ws['!rows'] = rows

            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, filename)
            XLSX.writeFile(wb, `${filename}.xlsx`)
            return mainArray
        }

        Vue.excelFromCustomMatrix = (matrix, filename) => {
            const ws = XLSX.utils.aoa_to_sheet(matrix)
            const wb = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(wb, ws, filename)
            XLSX.writeFile(wb, `${filename}.xlsx`)
        };
    }
}

export default MyExcelFromDate
