<template>
  <div class="mw-100 home-background" :style="`display: ${logged && $route.name !== '/' ? 'flex' : ''}` ">
    <vue-element-loading :active="loading" spinner="spinner" color=blue class="spinner"/>

    <SideBar v-show="logged && $route.name !== '/'"/>
    <v-idle
        v-if="logged"
        class="visibility"
        @idle="onIdle"
        :duration="3600"/>
    <b-modal
        cancel-title="Annulla"
        id="sessionExpiredModal"
        title="Attenzione"
    >
      <h6>La sessione risulta inattiva da più di 60 minuti, eseguire nuovamente l'accesso!</h6>
    </b-modal>
    <div class="row-snmp" :class="!stackedModeOn && (this.$route.name !== 'home' ||
                      !session || !showNet ||
                      !session.operator ||
                      session.operator.auth_mask[maskValue('HOME', 'READ')] !== '1' ) ? ' visibility' : (this.$route.name !== 'home' ||
                      !session || !showNet ||
                      !session.operator ||
                      session.operator.auth_mask[maskValue('HOME', 'READ')] !== '1' )  ? 'table-responsive-home visibility' : stackedModeOn ? 'table-responsive-home' : null"
         v-if="this.$route.name === 'home' &&
                      session && showNet &&
                      session.operator &&
                      session.operator.auth_mask[maskValue('HOME', 'READ')] === '1'">
      <b-row style="justify-content: flex-start" class="row-snmp">
        <b-col :cols="stackedModeOn ? 12 : 8" :class="!stackedModeOn ? 'row-snmp' : null">
          <d3-network class="network"
                      v-if="this.$route.name === 'home' &&
                      session &&
                      session.operator &&
                      session.operator.auth_mask[maskValue('HOME', 'READ')] === '1'"
                      :net-nodes=nodes
                      :net-links=links :options=options
                      @node-click="nodeClick"
          />
        </b-col>
        <b-col :cols="stackedModeOn ? 12 : 4">
          <div class="table-description-obu" v-if="this.$route.name === 'home' &&
                      session &&
                      session.operator &&
                      session.operator.auth_mask[maskValue('HOME', 'READ')] === '1'">
            <b-row>
              <b-col>
                <label class="c-white">Aggiornamento ogni:</label>
                <b-input-group class="mb-2">
                  <b-dropdown class="my-btn-secondary my-dropdown" :text="secondsDelay + ' secondi'"
                              v-model="secondsDelay">
                    <b-dropdown-item @click="secondsDelay='30'">30 secondi</b-dropdown-item>
                    <b-dropdown-item @click="secondsDelay='60'">60 secondi</b-dropdown-item>
                    <b-dropdown-item @click="secondsDelay='90'">90 secondi</b-dropdown-item>
                    <b-dropdown-item @click="secondsDelay='120'">120 secondi</b-dropdown-item>
                  </b-dropdown>
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
                v-show="showOBUDetails"
                id="obuDescription"
                ref="obuDescription"
                class="table table-font"
                :items="items"
                :fields="fields"
                striped
                :sort-desc=true
                responsive="sm"
                :stacked="true"
                :empty-text="'Non ci sono dati da visualizzare'"
                :show-empty="true"
            >
              <template v-slot:cell(success)="row">
                <i v-b-tooltip.hover title='Operazione avvenuta con successo' v-if="row.item.success === true"
                   class="fas fa-circle c-green"/>
                <i v-b-tooltip.hover title='Operazione avvenuta con errore' v-if="row.item.success === false"
                   class="fas fa-circle c-red"/>
              </template>

              <template v-slot:cell(description)="row">
                <div class="description-text-home" v-if="row.item.description_excel">
                  <i v-if="row.item.build_package" class="fa fa-archive mr-2"/>
                  {{ row.item.build_package ? row.item.build_package : '' }} <br>
                  <i class="fa fa-window-maximize mr-2" v-if="row.item.sw_description"/>
                  {{ row.item.sw_description ? row.item.sw_description : '' }} <br>
                  <div>
                    <i class="fa fa-file mr-2" v-if="row.item.file_description && row.item.file_name"/>
                    {{ row.item.file_description ? row.item.file_description : '' }}
                    {{ row.item.file_name ? '-' + row.item.file_name : '' }}<br>
                  </div>
                </div>
              </template>
            </b-table>
            <b-button  v-show="showOBUDetails" @click="hideTable">Nascondi</b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <router-view v-if="this.$route.name !== 'home' && !loading"/>
  </div>
</template>

<script>
import SideBar from "@/fragments/SideBar";
import VueElementLoading from 'vue-element-loading'
import Vue from 'vue'

import D3Network from 'vue-d3-network'
import myOperations from "@/plugin/conf";
import myUsefulFunctions from "@/plugin/my-useful-functions";
import _ from "lodash";

export default {
  components: {SideBar, VueElementLoading, D3Network},
  data() {
    var logged = Vue.getLocalStorage('logged')
    return {
      logged: logged,
      showErrorNotification: false,
      showSuccessNotification: false,
      loading: false,
      stackedModeOn: '',
      nodes: [],
      links: [],
      session: '',
      flashers: [],
      operationCodes: [],
      versions: [],
      operation: [],
      status: [],
      operationDes: [],
      flasherSerials: [],
      vehicles: [],
      software: [],
      build: [],
      fileDescription: [],
      fileName: [],
      obu: [],
      firstTime: true,
      options: {

      },
      fields: [
        {key: 'flasher_serial', label: 'Seriale del flasher', sortable: false},
        {key: 'obu_id', label: 'ID OBU', sortable: true,},
        {key: 'operation_code_des', label: 'Tipo di operazione', sortable: true},
        {key: 'firmware_version', label: 'Versione del software', sortable: true},
        {key: 'description', label: 'Tipo del software', sortable: false,},
        {key: 'status_object.status_translate', label: 'Stato del flasher', sortable: true},
        {key: 'date_string', label: 'Data', sortable: true},
        {key: 'vehicle_type_string', label: 'Tipo di veicolo', sortable: false},
        {key: 'success', label: '', value: Boolean}
      ],
      items: [
        {
          id: '',
          obu_id: '',
          operation_code_id: '',
          operation_code_des: '',
          firmware_version_id: '',
          firmware_version: '',
          status_id: '',
          status_object: {},
          timestamp: '',
          success: '',
          date_string: '',
          sw_description: '',
          build_package: '',
          file_description: '',
          file_name: '',
          vehicle_type_id: '',
          vehicle_type_string: '',
        }
      ],
      showOBUDetails: false,
      fetched: false,
      indexOfFirstChild: '',
      indexOfLastLink: '',
      lastNodeSelected: '',
      showNet: true,
      secondsDelay: '30',
      allNodes: [],
      allLinks: []
    }
  },
  watch: {
    async '$route'() {
      this.logged = Vue.getLocalStorage('logged')
      this.session = Vue.getLocalStorage('session')
      this.showNet = true
      if (this.logged && !this.fetched) {
        this.fetched = true
      }
      if (this.$route.name === 'home') {
        await this.fetchData()
        this.timer = setInterval(this.fetchData, this.secondsDelay * 1000);
        Vue.setLocalStorage('timerHome', this.timer)
      } else {
        clearInterval(this.timer)
        this.hideTable()
      }
    },
    secondsDelay() {
      clearInterval(this.timer)
      this.timer = setInterval(this.fetchData, this.secondsDelay * 1000);
      Vue.setLocalStorage('timerHome', this.timer)
    },
  },
  async created() {
    this.session = Vue.getLocalStorage('session')

    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md'||  size === 'lg'
    this.options = {
      linkWidth: 2,
      nodeLabels: true,
      force: this.stackedModeOn ? 3000 : 15000,
      canvas: false
    }
    this.$root.$on('successNotification', (type) => {
      this.makeToast('success', type)
    })
    this.$root.$on('errorNotification', (type, params, errMsg) => {
      this.makeToast('danger', type, params, errMsg)
    })
    this.$root.$on('activeLoader', (active) => {
      this.loading = active
    })
    this.$root.$on('hideNet', (bool) => {
      this.showNet = !bool
    })
    if (this.$route.path !== '/') {
     await this.fetchData()
    }

  },
  mounted() {
    this.$root.$on('updateFlashers', async () => {
      await this.fetchData()
    })
  },
  methods: {
    onIdle() {
      this.$root.$bvModal.show('sessionExpiredModal')
      this.$root.$refs.Header.logout()
    },
    makeToast(variant, type, params, errMsg) {
      this.$root.$bvToast.toast(variant === 'success' && type === 'login'
          ? 'Accesso eseguito'
          : variant === 'success'
              ? 'Operazione eseguita con successo'
              : variant === 'danger' && type === 'existingVersion'
                  ? 'Errore, la versione inserita è già presente'
                  : variant === 'danger' && type === 'missingFile'
                      ? 'Errore, nessun file selezionato'
                      : variant === 'danger' && type === 'existingUrl'
                          ? 'Errore, la URI inserita risulta già associata ad un\'altra versione'
                          : variant === 'danger' && type === 'existingPackage'
                              ? 'Errore, il file inserito risulta già associato ad un\'altra versione'
                              : variant === 'danger' && type === 'wrongPassword'
                                  ? 'Errore, la password inserita non è corretta'
                                  : variant === 'danger' && type === 'unknownOperator'
                                      ? 'Errore, i dati inseriti non sono corretti'
                                      : variant === 'danger' && type === 'accessDenied'
                                          ? 'Errore, accesso negato'
                                          : variant === 'danger' && type === 'expiredPassword'
                                              ? 'La password inserita risulta scaduta, scegli la nuova password'
                                              : variant === 'danger' && type === 'delete' && params
                                                  ? 'Errore, impossibile eliminare la versione perchè è già stata associata a ' + params + '.\n' +
                                                  'Per procedere all\'eliminazione della versione è necessario recarsi nella sezione "Gestione Flasher" e indicarne una nuova'
                                                  : variant === 'danger' && type === 'missingRecipe'
                                                  && errMsg === 'File type in recipe.json is not valid.'
                                                      ? 'Errore, il tipo di file in recipe.json non è valido'
                                                      : variant === 'danger' && type === 'missingRecipe'
                                                      && errMsg === 'The archive name must contain a maximum of 100 characters.'
                                                          ? 'Errore, Il nome dell\'archivio deve essere composto da 100 caratteri al massimo.'
                                                          : variant === 'danger' && type === 'missingRecipe'
                                                          && errMsg === 'The URL must contain a maximum of 250 characters.'
                                                              ? 'Errore, la URL deve essere composta da 250 caratteri al massimo.'
                                                      : variant === 'danger' && type === 'missingRecipe'
                                                      && errMsg === 'File name in recipe.json is not valid.'
                                                          ? 'Errore, il nome del file in recipe.json non è valido'
                                                          : variant === 'danger' && type === 'missingRecipe'
                                                          && errMsg === 'File name in recipe.json is void.'
                                                              ? 'Errore, il nome del file in recipe.json è vuoto'
                                                              : variant === 'danger' && type === 'missingRecipe'
                                                              && errMsg === 'File description in recipe.json is void.'
                                                                  ? 'Errore, la descrizione del file in recipe.json è vuota'
                                                                  : variant === 'danger' && type === 'missingRecipe'
                                                                  && errMsg === 'Software description in recipe.json is void'
                                                                      ? 'Errore, la descrizione del software in recipe.json è vuota'
                                                                      : variant === 'danger' && type === 'missingRecipe'
                                                                      && errMsg === 'The software type in recipe.json structure is incorrect.'
                                                                          ? 'Errore, il tipo di software nella struttura di recipe.json non è corretta'
                                                                          : variant === 'danger' && type === 'missingRecipe'
                                                                          && errMsg === 'File recipe.json structure is void.'
                                                                              ? 'Errore, la struttura del file recipe.json è vuota'
                                                                              : variant === 'danger' && type === 'missingRecipe'
                                                                              && errMsg === 'File recipe.json structure is malformed.'
                                                                                  ? 'Errore, la struttura del file recipe.json è malformata'
                                                                                  : variant === 'danger' && type === 'missingRecipe'
                                                                                  && errMsg === 'File recipe.json structure is incorrect.'
                                                                                      ? 'Errore, la struttura del file recipe.json non è corretta'
                                                                                      : variant === 'danger' && type === 'missingRecipe'
                                                                                      && errMsg === 'File recipe.json is not present in the archive'
                                                                                          ? 'Errore, il file recipe.json non è presente nell\'archivio'
                                                                                          : variant === 'danger' && type === 'missingRecipe'
                                                                                          && errMsg === 'The name of the folder contained in the archive and the name of the archive itself cannot contain whitespaces.'
                                                                                              ? 'Errore, il nome della cartella contenuta nell\'archivio e il nome dell\'archivio stesso non possono contenere spazi bianchi.'
                                                                                              : variant === 'danger' && type === 'missingRecipe'
                                                                                              && errMsg === 'The name of the folder contained in the archive must be the same as the name of the archive itself.'
                                                                                                  ? 'Errore, il nome della cartella contenuta nell\'archivio dev\'essere uguale al nome dell\'archivio stesso.'
                                                                                                  : variant === 'danger' && type === 'missingRecipe'
                                                                                                  && errMsg === 'The name of the archive must be at least of 10 characters.'
                                                                                                      ? 'Errore, Il nome dell\'archivio deve essere di almeno 10 caratteri.'
                                                                                                                  : variant === 'danger' && type === 'telepassServiceError'
                                                                                                                  && errMsg === 'TLP - Error during authentication'
                                                                                                                      ? 'Errore, impossibile comunicare con il centro Telepass'
                                                                                          : 'Si è verificato un errore'
          , {
            title: variant === 'success' ? `Ok` : 'Errore',
            autoHideDelay: variant === 'danger' && type === 'delete' ? 23000 : 10000,
            variant: variant,
            solid: true,

          })
    },
    async fetchData() {
      if (this.$route.path !=='/') {
        this.$root.$emit('activeLoader', true)
        this.nodes = []
        this.links = []
        if (this.versions.length === 0) {
          this.versions = await myUsefulFunctions.getVersions()
        }
        if (this.status.length === 0) {
          this.status = await myUsefulFunctions.getStatus()
        }
        if (this.operationDes.length === 0) {
          this.operationDes = await myUsefulFunctions.getOperationDes()
        }
        if (this.operationCodes.length === 0) {
          this.operationCodes = await myUsefulFunctions.getOperationCodes()
        }
        if (this.vehicles.length === 0) {
          this.vehicles = await myUsefulFunctions.getVehicle()
        }
        if (this.software.length === 0) {
          this.software = await myUsefulFunctions.getSoftwareDescription()
        }
        if (this.build.length === 0) {
          this.build = await myUsefulFunctions.getBuild()
        }
        if (this.fileDescription.length === 0) {
          this.fileDescription = await myUsefulFunctions.getFileDescription();
        }
        if (this.fileName.length === 0) {
          this.fileName = await myUsefulFunctions.getFilename()
        }
        if (this.nodes.length === 0) {
          this.nodes.push({
            id: this.nodes.length,
            name: Vue.getBaseUrl(),
            _size: this.stackedModeOn || this.flashers.length > 10 ? 20 : 50,
            _color: 'blue',
            svgSym: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32"><path d="M12 30c-6.626 0-12-1.793-12-4 0-1.207 0-2.527 0-4 0-0.348 0.174-0.678 0.424-1 1.338 1.723 5.99 3 11.576 3s10.238-1.277 11.576-3c0.25 0.322 0.424 0.652 0.424 1 0 1.158 0 2.387 0 4 0 2.207-5.375 4-12 4zM12 22c-6.626 0-12-1.793-12-4 0-1.208 0-2.526 0-4 0-0.212 0.080-0.418 0.188-0.622v0c0.061-0.128 0.141-0.254 0.236-0.378 1.338 1.722 5.99 3 11.576 3s10.238-1.278 11.576-3c0.096 0.124 0.176 0.25 0.236 0.378v0c0.107 0.204 0.188 0.41 0.188 0.622 0 1.158 0 2.386 0 4 0 2.207-5.375 4-12 4zM12 14c-6.626 0-12-1.792-12-4 0-0.632 0-1.3 0-2 0-0.636 0-1.296 0-2 0-2.208 5.374-4 12-4s12 1.792 12 4c0 0.624 0 1.286 0 2 0 0.612 0 1.258 0 2 0 2.208-5.375 4-12 4zM12 4c-4.418 0-8 0.894-8 2s3.582 2 8 2 8-0.894 8-2-3.582-2-8-2z"></path></svg>'
          })
        }

        this.flashers = Vue.getLocalStorage('flashers')
        await this.getFlashers().then(async () => {
          await this.initializeTables()
        })
        this.options = {
          linkWidth: 2,
          nodeLabels: true,
          force: this.stackedModeOn ? 3000 : this.flashers.length > 10 ? 6000 : 15000,
          canvas: false
        }
        this.$root.$emit('activeLoader', false)
      }
    },
    async getFlashers() {
      this.flashers.forEach(item => {
        this.links.push({sid: 0, tid: this.nodes.length})
        this.nodes.push({
          id: this.nodes.length,
          name: item.flasher_serial,
          _size: this.stackedModeOn || this.flashers.length > 10  ? 20 : 70,
          svgSym: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hdd-fill" viewBox="0 0 16 16">\n' +
              '<path d="M0 10a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-1zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM.91 7.204A2.993 2.993 0 0 1 2 7h12c.384 0 .752.072 1.09.204l-1.867-3.422A1.5 1.5 0 0 0 11.906 3H4.094a1.5 1.5 0 0 0-1.317.782L.91 7.204z"/>\n' +
              '</svg>'
        })
      })
    },
    async initializeTables() {
      this.allNodes = this.nodes
      this.allLinks = this.links
      this.nodes = []
      this.links = []
      await Promise.all(this.allNodes.map(async (item) => {
        if (item.id > 0) {
          item.details = await myOperations.getOperations({
            operationCodes: this.operationDes,
            status: this.status,
            versions: this.versions,
            vehicles: this.vehicles,
            records: 200,
            serial: item.name,
            showingOperations: 'flasher'
          })
          if (item.details.length > 0) {
            item._color = _.first(item.details).success ? 'green' : 'red'
            item.details = [_.first(item.details)]
          }
          const thisFlasher = _.find(this.flashers, flasher => flasher.flasher_serial === item.name)
          if (thisFlasher.swType && (thisFlasher.swType === 6 || thisFlasher.swType === 7)) {
            await Vue.myGet(this, '/dock/fs/' + item.name).then(async (docksResponse) => {
              if (!_.isNil(docksResponse) && !_.isNil(docksResponse.dock)) {

                if (!_.isNil(_.find(docksResponse.dock, (obj) => !_.isNil(obj.operation_code_counter)
                    && (obj.operation_code_counter['7'] >= 3
                        || obj.operation_code_counter['8'] >= 3
                        || (obj.operation_code_counter['22'] > 0 && obj.operation_code_counter['21'] === 1))))) {
                  item._color = 'yellow'
                }
              }
            }).catch(async (error) => {
              console.log(error)
            })
          }
          if (this.lastNodeSelected !== '' && item.name === this.lastNodeSelected.name && this.showOBUDetails) {
            this.items = item.details
          }
        }
      })).then(() => {
        this.nodes = _.cloneDeep(this.allNodes)
        this.links = _.cloneDeep(this.allLinks)
        if (!_.isNil(this.$refs.obuDescription)) {
          this.$refs.obuDescription.refresh()
        }

      })
    },
    async nodeClick(event, node) {
      if (node && node.id === 0) {
        if (this.nodes.length > 1) {
          this.allNodes = this.nodes
          this.nodes = this.nodes.slice(0, 1)
          this.lastNodeSelected = ''
          this.allLinks = this.links
          this.links = []
          this.hideTable()
        } else {
          this.nodes = this.allNodes
          this.links = this.allLinks
        }
      } else if (node && this.flashers.find(item => item.flasher_serial === node.name)) {
        this.resetSizes()
        if (node && this.lastNodeSelected === node) {
          this.lastNodeSelected = ''
          this.hideTable()
          return
        }
        this.indexOfFirstChild = this.nodes.length
        this.indexOfLastLink = this.links.length
        this.lastNodeSelected = node || this.lastNodeSelected
        this.items = node.details
        this.showOBUDetails = true;

      }
    },
    resetSizes() {
      if (this.indexOfFirstChild !== '')
        this.nodes = this.nodes.slice(0, this.indexOfFirstChild)
      if (this.indexOfLastLink !== '')
        this.links = this.links.slice(0, this.indexOfLastLink)
      this.nodes.forEach(item => {
        if (item.index !== 0)
          item._size = this.stackedModeOn|| this.flashers.length > 10  ? 20 : 70
      })
    },
    hideTable() {
      this.showOBUDetails = false
    },
  },
}
</script>
<style src="vue-d3-network/dist/vue-d3-network.css"></style>

<style>
.mw-100 {
  height: 100% !important;
}

.home-background {
  background-image: url('~@/assets/background4.jpg');
  background-size: cover, contain;
}

.navbar-toggler-icon {
  color: black;
}

.svg, .net, .network {
  height: 100%;
  width: 100%;
}

.svg {
  vertical-align: middle;
}

.node-label {
  font-size: 1rem;
  fill: white;
}

.node {
  stroke: none;
}

.link {
  stroke: rgba(11, 97, 172, 0.76);
}

.node:hover {
  stroke: none;
}

.row-snmp, .network {
  width: 100%;
  height: 100%;
}

.table-description-obu {
  margin: 1rem;
}

.visibility {
  visibility: hidden;
  width: 0;
}

.table-responsive-home{
  margin: 0;
}

.description-text-home{
  display: flex;
  justify-content: center;
}
</style>
