const myOperationCodeTranslate = {
    operationCode(code) {
        switch (code) {
            case 1:
                return 'Flasher avviato'
            case 2:
                return 'Software di collaudo arrestato'
            case 3:
                return 'Dati di trasferimento impostati'
            case 4:
                return 'File trasferito con successo'
            case 5:
                return 'Controllo EC21 passato'
            case 6:
                return 'Installazione avviata. OBU in fase di riavvio'
            case 7:
                return 'Arresto software di collaudo non riuscito'
            case 8:
                return 'Impostazione dati di trasferimento non riuscita'
            case 9:
                return 'Trasferimento file non completato: errore di comunicazione con l\'OBU'
            case 10:
                return 'Controllo EC21 fallito'
            case 11:
                return 'La OBU non risponde al comando di esecuzione'
            case 12:
                return 'Indirizzo per il download non indicato'
            case 13:
                return 'Download non completato: errore della risorsa'
            case 14:
                return 'OBU disconnessa, operazioni interrotte'
            case 15:
                return 'OK, convalida dal servizio centrale ottenuta'
            case 16:
                return 'Tipo di veicolo assegnato correttamente'
            case 17:
                return 'KO, convalida dal servizio centrale non ottenuta'
            case 18:
                return 'Errore nell\'assegnamento del tipo di veicolo'
            case 19:
                return 'In attesa di convalida da parte del servizio centrale'
            case 20:
                return 'Seriale disponibile'
            case 21:
                return 'OBU id letto dal lettore barcode'
            case 22:
                return 'OBU id scaduto'
            case 23:
                return 'OBU id non disponibile'
            case 24:
                return 'OBU connesso'
            case 25:
                return 'Flashatura conclusa: lotto chiuso'
            case 26:
                return 'Nuovo lotto di lavoro avviato'
            case 27:
                return 'Assegnazione modello OK'
            case 28:
                return 'Assegnazione modello KO'
            case 29:
                return 'Installazione bootstrap avviata'
            case 30:
                return 'Assegnazione item code OK'
            case 31:
                return 'Assegnazione item code KO'
            case 32:
                return 'Disconnesso dall\'operatore'
            case 33:
                return 'Pronto per verifica con proxy'
            case 34:
                return 'Errore di timeout durante verifica con proxy'
            case 100:
                return 'Errori nella fase di flashatura'

            default:
                return ''

        }
    }
}

export default myOperationCodeTranslate
