const myAuthorizationMaskIndexes = {
    CRUD(section, operation){
        switch (section){
            case 'LOGIN':
                return 0;
            case 'HOME': {
                switch (operation) {
                    case 'READ':
                        return 1;
                }
                return
            }
            case 'OPERATORS': {
                switch (operation) {
                    case 'CREATE':
                        return 2;
                    case 'READ':
                        return 3;
                    case 'UPDATE':
                        return 4;
                    case 'DELETE':
                        return 5;
                }
                return
            }

            case 'FLASHERS':{
                switch (operation) {
                    case 'READ':
                        return 7;
                    case 'UPDATE':
                        return 8;
                    case 'DELETE':
                        return 9;
                }
                return
            }

            case 'VERSIONS':{
                switch (operation) {
                    case 'CREATE':
                        return 10;
                    case 'READ':
                        return 11;
                    case 'UPDATE':
                        return 12;
                    case 'DELETE':
                        return 13;
                }
                return
            }

            case 'REPORTS':{
                switch (operation){
                    case 'READ':
                        return 15;
                }
                return
            }

            case 'MONITOR':{
                switch (operation) {
                    case 'CREATE':
                        return 18;
                    case 'READ':
                        return 19;
                    case 'UPDATE':
                        return 20;
                    case 'DELETE':
                        return 21;
                }
                return
            }

            case 'PERSONALIZATION_OBU':{
                switch (operation) {
                    case 'CREATE':
                        return 22;
                    case 'READ':
                        return 23;
                    case 'UPDATE':
                        return 24;
                    case 'DELETE':
                        return 25;
                }
                return
            }
            case 'TIMEOUT_CONF': {
                switch (operation) {
                    case 'CREATE':
                        return 26;
                    case 'READ':
                        return 27;
                    case 'UPDATE':
                        return 28;
                    case 'DELETE':
                        return 29;
                }
                return
            }
        }
    },
    pathNameForTranslate(pathName){
    switch (pathName){
        case 'operators':
            return 'OPERATORS'
        case 'versions':
            return 'VERSIONS'
        case 'flasher':
            return 'FLASHERS'
        case 'reportLogs':
            return 'REPORTS'
        case 'reportSerialLogs':
            return 'REPORTS'
        case 'monitorOperations':
            return 'MONITOR'
        case 'personalizationObu':
            return 'PERSONALIZATION_OBU'
        case 'timeouts':
            return 'TIMEOUT_CONF'
    }
}
}
export default myAuthorizationMaskIndexes
