<template>
  <div :class="!stackedModeOn ? 'table-container' : 'table-responsive'">
    <b-row>
      <b-col cols="12" sm="9" md="6" lg="4" xl="4">
        <label class="c-white">Aggiornamento ogni:</label>
        <b-input-group class="mb-2">
          <b-dropdown class="my-btn-secondary my-dropdown" :text="secondsDelay + ' secondi'"
                      v-model="secondsDelay">
            <b-dropdown-item @click="secondsDelay='5'">5 secondi</b-dropdown-item>
            <b-dropdown-item @click="secondsDelay='10'">10 secondi</b-dropdown-item>
            <b-dropdown-item @click="secondsDelay='15'">15 secondi</b-dropdown-item>
            <b-dropdown-item @click="secondsDelay='30'">30 secondi</b-dropdown-item>
          </b-dropdown>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table
        id="logsTable"
        class="table table-font"
        :items="items"
        :fields="fields"
        striped
        :sort-by="sortBy"
        :sort-desc=true
        responsive="sm"
        :fixed="fixed !== false ? true : null"
        :stacked="stackedModeOn"
        :current-page="currentPage"
        :per-page="perPage"
        :class="!stackedModeOn ? 'notStacked logs-table' : ''"
        :empty-text="'Non ci sono dati da visualizzare'"
        :show-empty="true"
    >
      <template v-slot:cell(success)="row">
        <i v-b-tooltip.hover title='Operazione avvenuta con successo' v-if="row.item.success === true"
           class="fas fa-circle c-green"/>
        <i v-b-tooltip.hover title='Operazione avvenuta con errore' v-if="row.item.success === false"
           class="fas fa-circle c-red"/>
      </template>

      <template v-slot:cell(description)="row">
        <div class="description-text" v-if="row.item.description_excel">
          <i v-if="row.item.build_package && row.item.build_package.trim() !== ''" class="fa fa-archive"/>
          {{ row.item.build_package ? row.item.build_package : '' }} <br>
          <i class="fa fa-window-maximize" v-if="row.item.sw_description && row.item.sw_description.trim() !== ''"/>
          {{ row.item.sw_description ? row.item.sw_description : '' }} <br>
          <div>
            <i class="fa fa-file" v-if="(row.item.file_description && row.item.sw_description.trim() !== '') || (row.item.file_name && row.item.file_name.trim() !== '')"/>
            {{ row.item.file_description ? row.item.file_description + '-' : '' }}
            {{ row.item.file_name ? row.item.file_name : '' }}<br>
          </div>
        </div>
      </template>
      <template v-slot:cell(operation_code_des)="row">
        <h v-b-tooltip.hover :title='row.item.operation_code_id'> {{row.item.operation_code_des}}</h>
      </template>
    </b-table>

    <b-row>
      <b-col>
        <b-pagination
            class="my-pagination "
            :per-page="perPage"
            v-model="currentPage"
            :size="stackedModeOn ? 'sm' : 'md'"
            aria-controls="logsTable"
            :total-rows="numberOfRows"
        >
        </b-pagination>
      </b-col>
      <b-col cols="5" sm="1" md="3">
        <b-form-group horizontal :label="''" :label-cols="5">
          <b-form-select :options="pageOptions" v-model="perPage"/>
        </b-form-group>
      </b-col>
      <b-col cols="7" sm="2" md="2" lg="auto">
        <b-button
            class="my-button"
            @click="excel('Operazioni_' + $route.params.id)"> Excel
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import myOperations from "@/plugin/conf";
import myUsefulFunctions from "@/plugin/my-useful-functions";
import _ from "lodash";


export default {
  data() {
    return {
      sortBy: 'timestamp',
      searchWord: null,
      originalItems: [],
      operationCodes: null,
      operationDes: [],
      currentPage: 1,
      perPage: 100,
      pageOptions: [5, 10, 25, 50, 100],
      containerClass: '',
      stackedModeOn: null,
      sortDesc: false,
      startDate: '',
      endDate: '',
      startTime: '00:00:00',
      endTime: '00:00:00',
      timer: '',
      refresh: false,
      versions: [],
      fields: [
        {key: 'flasher_serial', label: 'Seriale del flasher', sortable: false},
        {key: 'obu_id', label: 'ID OBU', sortable: true,},
        {key: 'operation_code_des', label: 'Tipo di operazione', sortable: true},
        {key: 'firmware_version', label: 'Versione del software', sortable: true},
        {key: 'description', label: 'Tipo del software', sortable: false,},
        {key: 'status_object.status_translate', label: 'Stato del flasher', sortable: true},
        {key: 'date_string', label: 'Data', sortable: true},
        {key: 'vehicle_type_string', label: 'Tipo di veicolo', sortable: false},
        {key: 'success', label: '', value: Boolean}
      ],
      items: [
        {
          id: '',
          obu_id: '',
          operation_code_id: '',
          operation_code_des: '',
          firmware_version_id: '',
          firmware_version: '',
          status_id: '',
          status_object: '',
          timestamp: '',
          success: '',
          date_string: '',
          vehicle_type_string: ''
        }
      ],
      numberOfRecords: '500',
      status: [],
      vehicles: [],
      secondsDelay: '10'
    }
  },
  computed: {
    numberOfRows() {
      return this.items.length
    },
  },
  created() {
    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md' ||  size === 'lg'
    this.fetchData()
    this.timer = setInterval(this.fetchData, this.secondsDelay * 1000);
    Vue.setLocalStorage('timer', this.timer)
  },
  methods: {
    async fetchData() {
      if (!this.refresh) {
        this.refresh = true
        this.versions = await myUsefulFunctions.getVersions()
        this.status = await myUsefulFunctions.getStatus()
        this.operationDes = await myUsefulFunctions.getOperationDes()
        this.operationCodes = await myUsefulFunctions.getOperationCodes()
        this.vehicles = await myUsefulFunctions.getVehicle()
      }
      this.items = await myOperations.getOperations({
        operationCodes: this.operationDes,
        status: this.status,
        versions: this.versions,
        vehicles: this.vehicles,
        records: this.numberOfRecords,
        serial: this.$route.params.id})
      this.originalItems = this.items
    },
    excel(fileName, customFields) {
      let newFields = _.cloneDeep(this.fields)
      newFields.forEach((field) => {
        if (field.key === 'description')
          field.key = 'description_excel'
      })
      return Vue.excel(
          customFields || newFields,
          this.items,
          fileName
      )
    },
  },
  props: [
    'fixed',
  ],
  watch: {
    '$route.params.id'() {
      this.fetchData()
      this.timer = setInterval(this.fetchData, this.secondsDelay * 1000);
      Vue.setLocalStorage('timer', this.timer)
    },
    secondsDelay() {
      clearInterval(this.timer)
      this.timer = setInterval(this.fetchData, this.secondsDelay * 1000);
      Vue.setLocalStorage('timer', this.timer)
    }
  }
}

</script>

<style>
.margin-bottom-multiselect {
  margin-bottom: 2rem;
}

.b-table-sticky-header > .table, .table-responsive > .table, [class*="table-responsive-"] > .table {
  margin-bottom: 1rem;
}

.table-responsive {
  margin: 1rem;
}

.row {
  margin-right: 0;
}

.table-font {
  font-size: 0.9rem;
  line-height: 1;
}

.my-dropdown, .btn {
  border-radius: 15px;
  font-size: 1.2rem;
}

.toggle-button-margins {
  margin-bottom: 2.5rem;
}

.c-green {
  color: seagreen;
}

.c-red {
  color: red;
}

.c-white {
  color: white;
}

.table-font {
  word-break: break-word;
}

.dropdown-toggle {
  white-space: unset;
}

@keyframes spinning {
  0% {
    transform: rotate(0)
  }
  to {
    transform: rotate(2turn)
  }
}

</style>
