import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import MyLocalStorage from "./plugin/my-local-storage";
import router from "./router";
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free/css/all.css'
import ToggleButton from 'vue-js-toggle-button'
import Multiselect from 'vue-multiselect'
import ElementLoading from 'vue-element-loading'
import LiquorTree from 'liquor-tree'
import '@/plugin/my-mixin'
import Vidle from 'v-idle'
import {
    fas,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    fas,
)
Vue.use(MyLocalStorage)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToggleButton)
Vue.use(LiquorTree)
Vue.use(Vidle)

Vue.component('ElementLoading', ElementLoading)
Vue.component('multiselect', Multiselect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.directive('uppercase', {
    update (el) {
        el.value = el.value.toUpperCase()
    },
})
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
