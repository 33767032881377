<template>
  <div :class="!stackedModeOn ? 'table-container' : 'table-responsive'">
    <b-row align-v="center">
      <b-col cols="12" sm="9" md="6" lg="4" xl="3">
        <div class="search">
          <span class="fa fa-search"></span>
          <b-form-input
              class="search-input"
              v-model="searchWord"
              id="input-search"
              placeholder="Ricerca"/>
        </div>
      </b-col>
      <div class="mb-3 ml-3">
        <b-button class="custom-table-button" v-b-toggle.my-collapse>
          <i class="fa fa-filter"></i>
        </b-button>
      </div>
      <b-col class="mb-3 mr-1">
        <b-button v-on:click="resetFilters()" class="ml-3 custom-table-button">
          Pulisci filtri
        </b-button>
      </b-col>
      <b-col cols="auto" class="mb-3">
        <b-button
            v-if="session && session.operator && session.operator.auth_mask[maskValue('VERSIONS', 'CREATE')] === '1'"
            v-on:click="showAddModal = true" class="custom-table-button" :class="stackedModeOn ? 'ml-0' : 'ml-3'">
          Aggiungi
        </b-button>
      </b-col>
    </b-row>
    <b-modal
        cancel-title="Annulla"
        no-close-on-backdrop
        v-model="showAddModal"
        id="modal-add-version"
        ref="modal"
        title="Aggiungi versione"
        @ok="addVersion()"
        @cancel="resetNewItem('cancel')"
        :ok-disabled='!firmwareVersionState || !firmwareURIState'
    >
      <form ref="form">
        <b-form-group
            label="Versione del software *"
        >
          <b-form-input
              :state="newItem.firmware_version.length > 0 ? firmwareVersionState : null"
              aria-describedby="input-live-feedback"
              id="firmware-version-input"
              v-model="newItem.firmware_version"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="newItem.firmware_version && !isFirmwareVersionValid(newItem.firmware_version) && newItem.firmware_version.length > 0">
            Il campo deve contenere una stringa avente questa struttura: numero.numero.numero
          </b-form-invalid-feedback>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="newItem.firmware_version && newItem.firmware_version.length > 32">
            Il campo può contenere 32 caratteri al massimo
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
      <form ref="form">
        <b-form-group
            label="URL o file del software *"
        >
          <b-form-input
              :state="newItem.firmware_uri.length > 0 ? firmwareURIState : null"
              aria-describedby="input-live-feedback"
              id="firmware-url-input"
              v-model="newItem.firmware_uri"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="newItem.firmware_uri && !isUrlValid(newItem.firmware_uri) && this.newItem.firmware_uri.length > 0">
            Il campo deve contenere una URI valida contenente 250 caratteri al massimo
          </b-form-invalid-feedback>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="file !== null && this.newItem.firmware_uri.length > 0">
            Puoi inserire solo uno tra il file e la URL
          </b-form-invalid-feedback>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="file !== null && file !== undefined && !isFileValid(file)">
            Il file inserito non ha un formato valido
          </b-form-invalid-feedback>
          <form ref="form">
            <b-form-group
            >
              <b-form-file accept=".zip" v-model="file" class="mt-3" plain></b-form-file>
            </b-form-group>
          </form>

        </b-form-group>
      </form>
      <form ref="form">
        <b-form-group
            label="Note"
        >
          <b-form-input
              id="notes-input"
              v-model="newItem.notes"
          ></b-form-input>
        </b-form-group>
      </form>
      <b-form-group
          label="Data di scadenza"
      >
        <b-input-group class="search">
          <b-form-datepicker
              id="datepicker-new-item"
              :min="minDate"
              v-model="newItem.valid_date"
              class="mb-2"
              placeholder="Seleziona data">
          </b-form-datepicker>
          <div class="input-group-append">
            <b-button class="fas fa-trash mb-2"
                      :disabled="!newItem.valid_date"
                      @click="newItem.valid_date = '';">
            </b-button>
          </div>
        </b-input-group>
      </b-form-group>
    </b-modal>


    <b-collapse id="my-collapse">
      <b-row>
        <b-col cols="12" md="6" lg="6" xl="3">
          <label class="c-white">Versioni visualizzate:</label>

          <b-input-group class="search">
            <b-dropdown :text=selectedValid offset=""
                        v-model="selectedValid">
              <b-dropdown-item @click="selectedValid='Tutte'">Tutte</b-dropdown-item>
              <b-dropdown-item @click="selectedValid='Valide'">Valide</b-dropdown-item>
              <b-dropdown-item @click="selectedValid='Non valide'">Non valide</b-dropdown-item>
              <b-dropdown-item @click="selectedValid='Scadono nel periodo'">Scadono nel periodo</b-dropdown-item>

            </b-dropdown>
          </b-input-group>
        </b-col>
        <b-col cols="12" md="6" lg="6" xl="3" v-if="selectedValid === 'Scadono nel periodo'">
          <label class="c-white ">Data di scadenza da: </label>
          <b-input-group class="search">
            <b-form-datepicker
                id="datepicker-start"
                :max="endDate"
                v-model="startDate"
                class="mb-2"
                placeholder="Seleziona data">
            </b-form-datepicker>
            <div class="input-group-append">
              <b-button class="fas fa-trash mb-2"
                        :disabled="!startDate"
                        @click="startDate = ''; items=notValid">
              </b-button>
            </div>
          </b-input-group>
        </b-col>
        <b-col cols="12" md="6" lg="6" xl="3" v-if="selectedValid === 'Scadono nel periodo'">
          <label class="c-white "> a: </label>
          <b-input-group class="search">
            <b-form-datepicker
                :min="startDate"
                id="datepicker-end"
                v-model="endDate"
                :clear-button="true"
                class="mb-2"
                placeholder="Seleziona data">
            </b-form-datepicker>
            <div class="input-group-append">
              <b-button class="fas fa-trash mb-2"
                        :disabled="!endDate"
                        @click="endDate = ''; items=notValid">

              </b-button>
            </div>
          </b-input-group>
        </b-col>
      </b-row>
    </b-collapse>

    <b-table
        id="versionsTable"
        class="table"
        :items="items"
        :fields="fields"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        sort-icon-left
        striped
        responsive="sm"
        :fixed="fixed !== false ? true : null"
        :stacked="stackedModeOn"
        :current-page="currentPage"
        :per-page="perPage"
        :class="!stackedModeOn ? 'notStacked version-table' : ''"
        :empty-text="'Non ci sono dati da visualizzare'"
        :show-empty="true"
    >

      <template v-slot:cell(selected)="row">
        <b-row class="row-edit-delete">
          <b-button
              v-if="session && session.operator && session.operator.auth_mask[maskValue('VERSIONS', 'UPDATE')] === '1'"
              v-on:click="showEditModal = true; cloneRowEdit(row);">
            <i class="fa fa-edit"/>
          </b-button>
          <b-button
              class="delete-button"
              v-b-tooltip.hover title='Elimina'
              v-if="!row.item.isUsed && session && session.operator && session.operator.auth_mask[maskValue('VERSIONS', 'DELETE')] === '1'"
              @click="rowToUpdateRemoved = row.item; showDeleteModal = true">
            <i class="fa fa-trash-alt"/>
          </b-button>
          <b-button
              class="delete-button"
              v-b-tooltip.hover title='Scarica'
              @click.prevent="downloadVersion(row.item)"
              >
            <i class="fa fa-file-download "/>
          </b-button>
        </b-row>
        <b-modal
            cancel-title="Annulla"
            v-model="showDeleteModal"
            ref="modal"
            title="Elimina versione"
            @ok="deleteVersion(rowToUpdateRemoved.id, true)"
        >
          <div>Sei sicuro di voler eliminare la versione {{ rowToUpdateRemoved.firmware_version }}?</div>
        </b-modal>
        <b-modal
            cancel-title="Annulla"
            no-close-on-backdrop
            size="xl"
            v-model="showEditModal"
            id="modal-edit-version"
            ref="modal"
            title="Modifica versione"
            @ok="editVersion(rowToEdit)"
            :ok-disabled='!firmwareVersionState || !firmwareURIState'
            @cancel="resetNewItem('cancel')"
        >
          <form ref="form">
            <b-form-group
                label="Versione del software *"
            >
              <b-form-input
                  :state="rowToEdit.firmware_version.length > 0 ? firmwareVersionState : null"
                  aria-describedby="input-live-feedback"
                  id="firmware-version-input"
                  v-model="rowToEdit.firmware_version"
                  :disabled="rowToEdit.isUsed"
              ></b-form-input>
              <b-form-valid-feedback id="input-live-feedback" v-if="rowToEdit.isUsed">
                Non è possibile modificare il nome della versione, la versione è in uso
              </b-form-valid-feedback>
              <b-form-invalid-feedback id="input-live-feedback"
                                       v-if="rowToEdit.firmware_version && !isFirmwareVersionValid(rowToEdit.firmware_version) && rowToEdit.firmware_version.length > 0">
                Il campo deve contenere una stringa avente questa struttura: numero.numero.numero
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-live-feedback"
                                       v-if="rowToEdit.firmware_version && rowToEdit.firmware_version.length > 32">
                Il campo può contenere 32 caratteri al massimo
              </b-form-invalid-feedback>
            </b-form-group>
          </form>
          <form ref="form">
            <b-form-group
                label="URL o file del software *"
            >
              <b-form-input
                  :state="(rowToEdit.firmware_uri !== undefined && rowToEdit.firmware_uri.length > 0) || file !== null ? firmwareURIState : null"
                  aria-describedby="input-live-feedback"
                  id="firmware-url-input"
                  v-model="rowToEdit.firmware_uri"
                  :disabled="rowToEdit.isUsed"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback"
                                       v-if="rowToEdit.firmware_uri !== undefined && !isUrlValid(rowToEdit.firmware_uri) && rowToEdit.firmware_uri.length > 0">
                Il campo deve contenere una URI valida contenente 250 caratteri al massimo
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-live-feedback"
                                       v-if="file !== null && rowToEdit.firmware_uri !== undefined && rowToEdit.firmware_uri.length > 0">
                Puoi inserire solo uno tra il file e la URL
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-live-feedback"
                                       v-if="file !== null && file !== undefined && !isFileValid(file)">
                Il file inserito non ha un formato valido
              </b-form-invalid-feedback>
              <b-form-valid-feedback id="input-live-feedback" v-if="rowToEdit.isUsed">
                Non è possibile modificare la URI o il file associato alla versione, la versione è in uso
              </b-form-valid-feedback>
              <form ref="form">
                <b-form-group
                >
                  <b-form-file
                      accept=".zip"
                      v-model="file"
                      class="mt-3"
                      plain
                      :disabled="rowToEdit.isUsed"
                  ></b-form-file>
                </b-form-group>
              </form>

            </b-form-group>
          </form>
          <form ref="form">
            <b-form-group
                label="Note"
            >
              <b-form-input
                  id="notes-input"
                  v-model="rowToEdit.notes"
              ></b-form-input>
            </b-form-group>
          </form>
          <b-form-group
              label="Data di scadenza"
          >
            <b-input-group class="search">
              <b-form-datepicker
                  :min="minDate"
                  id="datepicker-edit-item"
                  v-model="rowToEdit.valid_date"
                  class="mb-2"
                  placeholder="Seleziona data">
              </b-form-datepicker>
              <div class="input-group-append">
                <b-button class="fas fa-trash mb-2"
                          :disabled="!rowToEdit.valid_date"
                          @click="rowToEdit.valid_date = '';">
                </b-button>
              </div>
            </b-input-group>
          </b-form-group>
        </b-modal>
      </template>

      <template v-slot:cell(firmware_uri)="row">
        <div v-b-tooltip.hover class="uri-hidden" :title=' row.item.firmware_uri  ' v-if="row.item.firmware_uri">
          {{ row.item.firmware_uri }}
        </div>
      </template>

      <template v-slot:cell(description)="row">
        <i @click="showFirmwareDesModal = true; currentRow = row.item" v-if="row.item.firmware_popup"
           class="fa fa-eye"/>
        <b-modal
            cancel-title="Annulla"
            v-model="showFirmwareDesModal"
            id="modal-firmware-description"
            ref="firmwareDesModal"
            title="Descrizione del software"
        >
          <tree
              :data="currentRow.firmware_popup"
          >
          </tree>

        </b-modal>
      </template>

      <template v-slot:cell(valid_date)="row">
        <div v-if="row.item.valid_date">{{ row.item.formatted_date }}
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col>
        <b-pagination
            class="my-pagination"
            :per-page="perPage"
            v-model="currentPage"
            :size="stackedModeOn ? 'sm' : 'md'"
            aria-controls="versionsTable"
            :total-rows="numberOfRows"
        >
        </b-pagination>
      </b-col>
      <b-col cols="5" sm="1" md="3">
        <b-form-group horizontal :label="''" :label-cols="5">
          <b-form-select :options="pageOptions" v-model="perPage"/>
        </b-form-group>
      </b-col>
      <b-col cols="7" sm="2" md="2" lg="auto">
        <b-button
            class="custom-table-button"
            @click="excel('Versioni')"> Excel
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import myUsefulFunctions from "@/plugin/my-useful-functions";

export default {
  data() {
    return {
      currentItem: {},
      searchWord: null,
      originalItems: [],
      startDate: '',
      endDate: '',
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      containerClass: '',
      stackedModeOn: null,
      sortBy: 'valid_date',
      sortDesc: true,
      newItem: {
        firmware_version: '',
        firmware_uri: '',
        firmware_des: '',
        notes: '',
        valid_date: '',
        recipe: ''
      },
      showAddModal: false,
      showEditModal: false,
      showUploadModal: false,
      rowToEdit: {
        firmware_version: '',
        firmware_uri: '',
        firmware_des: '',
        notes: '',
        valid_date: '',
        recipe: '',
        software_type: ''
      },
      file: null,
      fields: [
        {key: 'selected', label: ''},
        {key: 'firmware_version', label: 'Versione del software', sortable: true},
        {key: 'software_type', label: 'Tipo del software', sortable: true},
        {key: 'firmware_uri', label: 'URL del software', sortable: true},
        {key: 'description', label: 'Descrizione del software', sortable: false},
        {key: 'notes', label: 'Note', sortable: false},
        {key: 'formatted_date', label: 'Data di scadenza', sortable: true}
      ],
      items: [
        {
          firmware_popup: [],
          firmware_version: '',
          firmware_uri: '',
          firmware_des: '',
          notes: '',
          valid_date: '',
          description_excel: '',
          files: [],
          recipe: {
            software: {
              swType: "",
              swDescription: "",
              file: [
                {
                  fileType: "",
                  fileName: "",
                  fileDescription: ""
                }
              ]
            }
          },
          isUsed: Boolean,
          formatted_date: ''
        },
      ],
      minDate: new Date(Date.now()),
      selectedValid: 'Valide',
      notValid: [],
      showFirmwareDesModal: false,
      currentRow: {},
      showDeleteModal: false,
      rowToUpdateRemoved: '',
      session: '',
      originalItem: {}
    }
  },
  computed: {
    numberOfRows() {
      return this.items.length
    },
    firmwareVersionState() {
      if (this.showEditModal)
        return this.rowToEdit.firmware_version.length > 0 && this.isFirmwareVersionValid(this.rowToEdit.firmware_version) && this.rowToEdit.firmware_version.length <= 32
      return this.newItem.firmware_version.length > 0 && this.isFirmwareVersionValid(this.newItem.firmware_version) && this.newItem.firmware_version.length <= 32
    },
    firmwareURIState() {
      if (this.showEditModal)
        return (!_.isUndefined(this.rowToEdit.firmware_uri) && this.isUrlValid(this.rowToEdit.firmware_uri) && this.file === null && this.rowToEdit.firmware_uri.length > 0) || (this.file !== null && (_.isUndefined(this.rowToEdit.firmware_uri) || this.rowToEdit.firmware_uri.length === 0) && this.isFileValid(this.file))
      return (!_.isUndefined(this.newItem.firmware_uri) && this.isUrlValid(this.newItem.firmware_uri) && this.file === null && this.newItem.firmware_uri.length > 0) || (this.file !== null && (_.isUndefined(this.newItem.firmware_uri) || this.newItem.firmware_uri.length === 0) && this.isFileValid(this.file))
    }
  },
  mounted() {
    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md'||  size === 'lg'
    this.session = Vue.getLocalStorage('session')
    this.fetchData()
  },
  methods: {
    resetFilters() {
      this.startDate = ''
      this.endDate = ''
      this.searchWord = ''
      this.items = _.cloneDeep(this.originalItems)
      this.selectedValid = 'Valide'
    },
    excel(fileName, customFields) {
      let newFields = _.cloneDeep(this.fields)
      newFields.forEach((field) => {
        if (field.key === 'description')
          field.key = 'description_excel'
      })
      return Vue.excel(
          customFields || newFields,
          this.items,
          fileName
      )
    },
    addVersion() {
      if (this.newItem.firmware_version === ''
          && this.newItem.firmware_uri === ''
          && this.newItem.firmware_des === ''
          && this.newItem.notes === ''
          && this.newItem.valid_date === ''
      ) {
        this.$root.$emit('errorNotification')
        return
      }
      const duplicateVersion = this.originalItems.find(originalItem =>
          this.newItem.firmware_version === originalItem.firmware_version)
      const duplicateUrl = this.originalItems.find(originalItem =>
          this.newItem.firmware_uri === originalItem.firmware_uri
      )
      let alreadyAssociatedVersion = undefined;
      if (!_.isNull(this.file)) {
        alreadyAssociatedVersion = this.originalItems.find(originalItem =>
            !_.isNull(originalItem.firmware_uri) && !_.isUndefined(originalItem.firmware_uri) ? this.file.name === originalItem.firmware_uri.split(/_(.+)/)[1] : null
        )
      }
      if (duplicateVersion) {
        this.$root.$emit('errorNotification', 'existingVersion')
      } else if (duplicateUrl) {
        this.$root.$emit('errorNotification', 'existingUrl')
      } else if (!_.isUndefined(alreadyAssociatedVersion)) {
        this.$root.$emit('errorNotification', 'existingPackage')
      } else {
        if (this.newItem.firmware_des === '') {
          this.newItem.firmware_des = 'placeholder'
        }
        this.$root.$emit('activeLoader', true)

        Vue.myPost('/version', this.newItem).then(
            async (response) => {
              if (response.status !== 200) {
                this.$root.$emit('activeLoader', false)
                if (response.status === 400) {
                  this.$root.$emit('errorNotification', 'missingRecipe', null, response.data.errMsg)
                } else
                  this.$root.$emit('errorNotification')
              } else {

                if (!_.isNull(this.file)) {
                  await this.uploadFile(response.data.id, 'CREATE')
                } else {
                  await this.fetchData().finally(() => {
                    this.$root.$emit('activeLoader', false)
                    this.$root.$emit('successNotification')
                  })
                }
              }
            })
      }
      this.resetNewItem()
    },
    async fetchData() {
      const allVersions = await myUsefulFunctions.getVersions()
      this.originalItems = myUsefulFunctions.translateSomeFields(allVersions)
      await Vue.myGet(this, '/version?valid=true', 'items').then((versions) => {
        this.items = myUsefulFunctions.translateSomeFields(versions)
      })
    },
    async fetchAllData() {
      await Vue.myGet(this, '/version', 'items').then((versions) => {
        this.originalItems = myUsefulFunctions.translateSomeFields(versions)
        this.items = _.cloneDeep(this.originalItems)
      })
    },
    editVersion(item) {
      const duplicateVersion = this.originalItems.find(originalItem =>
          item.firmware_version === originalItem.firmware_version
      )
      this.originalItem = duplicateVersion
      const duplicateUrl = this.originalItems.find(originalItem =>
          item.firmware_uri === originalItem.firmware_uri
      )
      let alreadyAssociatedVersion = undefined;
      if (this.file !== null) {
        alreadyAssociatedVersion = this.originalItems.find(originalItem =>
            !_.isUndefined(originalItem.firmware_uri) ?
            this.file.name === originalItem.firmware_uri.split(/_(.+)/)[1] : null
        )
      }
      const notChange = this.originalItems.find(originalItem =>
          item.id === originalItem.id
          && item.firmware_version === originalItem.firmware_version
          && item.firmware_uri === originalItem.firmware_uri
          && item.firmware_des === originalItem.firmware_des
          && item.notes === originalItem.notes
          && item.valid_date === originalItem.valid_date
      )
      if (notChange && this.file === null)
        return
      if (!_.isUndefined(duplicateVersion) && duplicateVersion.id !== item.id) {
        this.$root.$emit('errorNotification', 'existingVersion')
      } else if (!_.isUndefined(duplicateUrl) && duplicateUrl.id !== item.id) {
        this.$root.$emit('errorNotification', 'existingUrl')
      } else if (!_.isUndefined(alreadyAssociatedVersion) && !_.isNull(alreadyAssociatedVersion) && alreadyAssociatedVersion && alreadyAssociatedVersion.id !== item.id) {
        this.$root.$emit('errorNotification', 'existingPackage')
      } else {
        this.$root.$emit('activeLoader', true)
        Vue.put('/version/' + item.id, item).then(async (response) => {
          if (response.status !== 200 && response.status !== 204) {
            this.$root.$emit('activeLoader', false)
            this.$root.$emit('errorNotification')
          } else {
            if (this.file !== null) {
              await this.uploadFile(item.id, 'UPDATE')
            } else {
              await this.fetchData().finally(() => {
                this.$root.$emit('activeLoader', false)
                this.$root.$emit('successNotification')
              })
            }
          }

        })
      }
      this.resetRowToEdit()
    },
    uploadFile(id, mode) {
      if (!_.isNull(this.file)) {
        const formData = new FormData();
        formData.append('file', this.file)
        formData.append('version_id', id)
        this.$root.$emit('activeLoader', true)
        Vue.myPost('/upload', formData).then(async (response) => {
          this.file = null
          if (response.status !== 200) {
            if (response.status === 400) {
              this.$root.$emit('errorNotification', 'missingRecipe', null, response.data.errMsg)
              if (mode === 'CREATE')
                await this.deleteVersion(id, false).finally(() => {
                  this.$root.$emit('activeLoader', false)
                })
              else {
                Vue.put('/version/' + this.originalItem.id, this.originalItem).then(async (response) => {
                  if (response.status !== 200 && response.status !== 204) {
                    this.$root.$emit('activeLoader', false)
                  } else {
                    await this.fetchData().finally(() => {
                      this.$root.$emit('activeLoader', false)
                    })
                  }
                })
              }
            } else
              this.$root.$emit('errorNotification')
          } else {
            await this.fetchData().finally(() => {
              this.$root.$emit('activeLoader', false)
              this.$root.$emit('successNotification')
            })
          }
        })
      }
      if (_.isNull(this.file)) {
        this.$root.$emit('errorNotification', 'missingFile')
      }
    },
    getItems(startDate, endDate, searchWord) {
      let allItems = _.cloneDeep(this.originalItems)

      if (searchWord && searchWord !== '' && !_.isNull(searchWord)) {
        allItems = myUsefulFunctions.filterSearchWord(allItems, this.fields, searchWord)
      }
      return allItems
    },
    resetNewItem(command) {
      if (command === 'cancel')
        this.file = null
      this.newItem = {
        firmware_version: "",
        firmware_uri: "",
        firmware_des: "",
        notes: "",
        valid_date: "",
        recipe: ""
      }
    },
    cloneRowEdit(row) {
      this.rowToEdit = _.cloneDeep(row.item);
    },
    isUrlValid(userInput) {
      var res = userInput.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=+/]{2,250}\.(zip)$\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g);
      return res != null;
    },
    isFileValid(file) {
      var res = file.name.match(/[-a-zA-Z0-9@:%._~#=+/]{2,300}\.(zip)$\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g);
      return res != null;
    },
    isFirmwareVersionValid(firmwareVersion) {
      var res = firmwareVersion.match(/(^\d+\.\d+\.\d+$)/g);
      return res != null;
    },
    resetRowToEdit() {
      this.rowToEdit = {
        firmware_version: '',
        firmware_uri: '',
        firmware_des: '',
        notes: '',
        valid_date: '',
        recipe: ''
      }
    },
    async dateFilter(start, end) {
      if (start && start !== '' && end && end !== '') {
        await Vue.myGet(this, '/version?valid_date_from=' + start + '&valid_date_to=' + end).then((filtered) => {
          this.items = myUsefulFunctions.translateSomeFields(filtered)
        })
      }
      if (start && start !== '' && end === '') {
        await Vue.myGet(this, '/version?valid_date_from=' + start).then((filtered) => {
          this.items = myUsefulFunctions.translateSomeFields(filtered)
        })
      }
      if (end && end !== '' && start === '') {
        await Vue.myGet(this, '/version?valid_date_to=' + end).then((filtered) => {
          this.items = myUsefulFunctions.translateSomeFields(filtered)
        })
      }
    },

    async validFilter(bool) {
      return await Vue.myGet(this, '/version?valid=' + bool.toString()).then((filtered) => {
        this.items = myUsefulFunctions.translateSomeFields(filtered)
        if (!bool && this.notValid.length === 0)
          this.notValid = this.items
      })
    },
    translateDate(date) {
      return myUsefulFunctions.translateDate(date)
    },
    async deleteVersion(version_id, show_notification) {
      await Vue.myDelete('/version/' + version_id.toString()).then(async (response) => {
        if (response.status !== 200 && response.status !== 204) {
          this.$root.$emit('activeLoader', false)
          if (response.status === 409) {
            const flasherArray = response.data.errMsg.split(':')[1, response.data.errMsg.split(':').length - 1]
            show_notification ? this.$root.$emit('errorNotification', 'delete', flasherArray) : null
          } else
            show_notification ? this.$root.$emit('errorNotification') : null
        } else {
          await this.fetchData().finally(() => {
            this.$root.$emit('activeLoader', false)
            show_notification ? this.$root.$emit('successNotification') : null
            this.resetFilters()
          })
        }
      })
    },
  downloadVersion(item) {
    this.$root.$emit('activeLoader', true)
    var n = item.firmware_uri.lastIndexOf('/');
    var name = item.firmware_uri.substring(n + 1);
    Vue.myGet(this, '/download/' + name, null, true)
        .then(response => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(response)
          link.download = name
          document.body.appendChild(link);
          link.click()
          this.$root.$emit('activeLoader', false)
        }).catch(() => {
      this.$root.$emit('activeLoader', false)
      this.$root.$emit('errorNotification')
    })

  }
  },
  props: [
    'fixed',
  ],
  watch: {
    startDate() {
      this.dateFilter(this.startDate, this.endDate)
    },
    endDate() {
      this.dateFilter(this.startDate, this.endDate)
    },
    searchWord() {
      this.items = this.getItems(this.startDate, this.endDate, this.searchWord)
    },
    file() {
      if (this.showEditModal) {
        if (this.file !== null && this.rowToEdit.firmware_uri && this.rowToEdit.firmware_uri.length > 0)
          this.rowToEdit.firmware_uri = ''
      } else if (this.showAddModal) {
        if (this.file !== null && this.newItem.firmware_uri && this.newItem.firmware_uri.length > 0)
          this.newItem.firmware_uri = ''
      }
    },
    selectedValid() {
      if (this.selectedValid === 'Tutte')
        this.fetchAllData()
      else this.validFilter(this.selectedValid === 'Valide')

    }
  }
}
</script>
<style>
html, body {
  overflow-y: auto;
}

.table {
  background-color: white;
  border-radius: 25px;
  text-align: center;
}

.small-table-container {
  width: 100%;
  padding-top: 2rem;
  max-width: 95rem;
}

.notStacked > table td, .notStacked > table th {
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.notStacked > table tr:first-child th {
  border-top: 0;
}

.notStacked > table tr:last-child th {
  border-bottom: 0;
}

.table tr:last-child td {
  border-bottom: 0;
}

.table tr td:first-child,
.table tr th:first-child {
  border-left: 0;
}

.table tr td:last-child,
.table tr th:last-child {
  border-right: 0;
}

.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 0;
}

.search {
  position: relative;
  color: black;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}


.form-control {
  background: white;
  border-radius: 15px;
  font-size: 1.2rem;
}

.search .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}


.custom-control-label::before {
  position: absolute;
  display: flex;
  width: 1.2rem;
  height: 1.2rem;
  pointer-events: none;
  background-color: #fff;
}

.table thead th {
  vertical-align: inherit;
  border-bottom: 2px solid gray;
}

.table th, .table td {
  vertical-align: inherit;
  word-break: break-word;
}

.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  word-wrap: break-word;
}

.custom-control {
  z-index: unset;
}


.col-form-label {
  padding-bottom: 0;
  padding-top: 0;
}

.custom-table-button {
  margin-right: 0;
  margin-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 15px;
  font-size: 1.2rem;
  background-color: #FF5576;
  border: 0;
}

.pagination {
  font-size: 1.2rem;
}

.input-group > .input-group-append > .btn {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.edit-row-button {
  padding: 0.2rem;
}

.uri-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-text {
  text-align: start;
  word-break: break-all;
}

.version-table > .table tr td:first-child, .version-table > .table tr th:first-child {
  width: 10rem;
}

.version-table > .table tr td:nth-child(2), .version-table > .table tr th:nth-child(2) {
  width: 8rem;
}


.version-table > .table tr td:nth-child(3), .version-table > .table tr th:nth-child(3) {
  width: 8rem;
}

.version-table > .table tr td:nth-child(5), .version-table > .table tr th:nth-child(5) {
  width: 8rem;
}

.version-table > .table tr td:nth-child(6), .version-table > .table tr th:nth-child(6) {
  width: 15rem;
}

.version-table > .table tr td:nth-child(7), .version-table > .table tr th:nth-child(7) {
  width: 8rem;
}
</style>





