import _ from "lodash";
import Vue from 'vue'
import myUsefulFunctions from "@/plugin/my-useful-functions";

const myOperations = {
    async getOperations({operationCodes, status, versions, vehicles, records, serial, option, version, OBUID, fileName,
                            fileDescription, software, build, statusId, startDate, startTime, endDate, endTime, vehicle,
                            operationStatus, searchWord, showingOperations, completeStartDateTime, completeEndDateTime}) {
        let path = '/operation?'
        if (!_.isNil(showingOperations) && !showingOperations.includes('Tutte')) {
            if (showingOperations.includes('flasher'))
                path = path + 'obu_id=-&'
        }
        if (!_.isNil(serial) && !serial.includes('Seleziona'))
            path = path + 'flasher_serial=' + serial + '&'
        if (!_.isNil(option) && option !== '' && operationStatus.includes('Seleziona')) {
            if (option.id === 100) {
                let operationsList = await myUsefulFunctions.getOperationCodes()
                for (let operationIndex=0; operationIndex < operationsList.length; operationIndex++) {
                    if(operationsList[operationIndex].fromFlasherFailure == 1) 
                    {
                        path = path + 'operation_code_id=' + operationsList[operationIndex].id + '&'
                        
                    }
                }
            } else {
                path = path + 'operation_code_id=' + option.id + '&'
            }
        }
        if (!_.isNil(version) && version !== '') {
            path = path + 'firmware_version_id=' + version.id + '&'
        }
        if (!_.isNil(OBUID) && OBUID !== '')
            path = path + 'obu_id=' + OBUID + '&'
        if (!_.isNil(software) && !software.includes('Seleziona'))
            path = path + 'sw_description=' + software + '&'
        if (!_.isNil(fileDescription) && !fileDescription.includes('Seleziona'))
            path = path + 'file_description=' + fileDescription + '&'
        if (!_.isNil(fileName) && !fileName.includes('Seleziona'))
            path = path + 'file_name=' + fileName + '&'
        if (!_.isNil(build) && !build.includes('Seleziona'))
            path = path + 'build_package=' + build + '&'
        if (!_.isNil(statusId) && statusId !== null && !statusId.includes('Seleziona'))
            path = path + 'status_id=' + statusId + '&'
        if (!_.isNil(startDate) && startDate !== '') {
            let rightDate = new Date(startDate + ' ' + startTime)
            let date = new Date((rightDate).toUTCString())
            path = path + 'valid_date_from=' + date.toISOString() + '&'
        }
        if (!_.isNil(completeStartDateTime) && completeStartDateTime !== '') {
           // completeStartDateTime = completeStartDateTime.setHours(completeStartDateTime.getHours() + 2)
            path = path + 'valid_date_from=' + new Date((completeStartDateTime).toUTCString()).toISOString() + '&'
        }
        if (!_.isNil(endDate) && endDate !== '') {
            let rightDate = new Date(endDate + ' ' + endTime)
            rightDate.setSeconds(rightDate.getSeconds() + 1)
            let date = new Date((rightDate).toUTCString())
            path = path + 'valid_date_to=' + date.toISOString() + '&'
        }
        if (!_.isNil(completeEndDateTime) && completeEndDateTime !== '') {
            path = path + 'valid_date_to=' + new Date(completeEndDateTime.toUTCString()).toISOString() + '&'
        }
        if (!_.isNil(vehicle) && !_.isNil(vehicle.id)) {
            path = path + 'vehicle_type_id=' + vehicle.id + '&'
        }
        if (!_.isNil(operationStatus) && !operationStatus.includes('Seleziona') && operationStatus!='Tutte') {
            path = path + 'operation_code_success=' + (operationStatus === 'Successo').toString() + '&'
        }
        if (searchWord && searchWord !== '' && !_.isNil(searchWord)) {
            path = path + 'full_search=' + searchWord + '&'
        }
        if (records)
            path = path + 'latest=' + records
        let retrieveOperation = []
        await Vue.myGet(this, path, 'operation').then((operation) => {
            if (!_.isNil(operation) && operation !== [] && operation.length > 0) {
                if (!_.isNil(showingOperations) && showingOperations.includes('OBU')) {
                    operation = operation.filter(item => !_.isNil(item.obu_id) && item.obu_id !== 'H' && item.obu_id !== '-')
                }
                operation = operation.filter(item => item.operation_code_id !== 20)
                operation.forEach(
                    async (item) => {
                        if (!_.isNil(item.vehicle_type_id) && item.vehicle_type_id !== 0) {
                            item.vehicle_type_string = !_.isNil(vehicles.find(vehicle => vehicle.id === item.vehicle_type_id)) ? vehicles.find(vehicle => vehicle.id === item.vehicle_type_id).Type : ''
                        }
                        const statusDes = status.find(s => s.id === item.status_id);
                        const operationDes = operationCodes.find(op => op.id === item.operation_code_id).name
                        item.flasher_serial = item.flasher_serial.trim()
                        item.status_object = statusDes
                        item.operation_code_des = operationDes
                        item.timestamp = new Date(item.timestamp_out)
                        item.success = operationCodes.find(op => op.id === item.operation_code_id).success
                        item.description = ''
                        item.description = !_.isNil(item.build_package) ? item.description.concat(item.build_package + ' ') : item.description
                        item.description = !_.isNil(item.sw_description) ? item.description.concat(item.sw_description + ' ') : item.description
                        item.description = !_.isNil(item.file_description) ? item.description.concat(item.file_description + ' - ') : item.description
                        item.description = !_.isNil(item.file_name) ? item.description.concat(item.file_name) : item.description
                        item.description_excel = ''
                        item.description_excel = !_.isNil(item.build_package) ? item.description_excel.concat('Pacchetto di build: ' + item.build_package + ' \n') : item.description_excel
                        item.description_excel = !_.isNil(item.sw_description) ? item.description_excel.concat('Tipo di software: ' + item.sw_description + ' \n') : item.description_excel
                        item.description_excel = !_.isNil(item.file_description) ? item.description_excel.concat('Tipo di file: ' + item.file_description + ' \n') : item.description_excel
                        item.description_excel = !_.isNil(item.file_name) ? item.description_excel.concat('Nome del file: ' + item.file_name + ' ') : item.description_excel
                        item.date_string = item.timestamp.toLocaleDateString("it-IT", {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })

                        if (_.isNil(item.obu_id) || item.obu_id === '-') {
                            item._rowVariant = 'info'
                        }

                        if(!_.isUndefined(item.firmware_version_id)) {
                            const obj = versions.find(version => version.id === item.firmware_version_id)
                            if (!_.isNil(obj)) {
                                item.firmware_version = obj.firmware_version
                                retrieveOperation.push(item)
                            }
                        }
                        else {
                            retrieveOperation.push(item)
                        }
                    })
            }
        })
        return retrieveOperation
    },
}
export default myOperations
