<template>
  <b-container>
    <b-card class="m-3">
      <b-row align-v="center">
        <b-col cols="12" md="3" lg="2" sm="12">
          <font-awesome-icon icon="user-circle"/>
        </b-col>
        <b-col cols="12" md="9" lg="10">
          <b-card-text>
            <b-row>
              <b-col cols="12" md="5" lg="4">
                <h3 class="text title">Username:</h3>
              </b-col>
              <b-col cols="12" md="7" lg="8">
                <h3>{{ session.operator.username }}</h3>
              </b-col>
              <b-col cols="12" md="5" lg="4">
                <h3 class="text title">Nome:</h3>
              </b-col>
              <b-col cols="12" md="7" lg="8">
                <h3 class="text">{{ session.operator.first_name }}</h3>
              </b-col>
              <b-col cols="12" md="5" lg="4">
                <h3 class="text title">Cognome:</h3>
              </b-col>
              <b-col cols="12" md="7" lg="8">
                <h3 class="text">{{ session.operator.last_name }}</h3>
              </b-col>
              <b-col cols="12" md="5" lg="4">
                <h3 class="text title">Società:</h3>
              </b-col>
              <b-col cols="12" md="7" lg="8">
                <h3 class="text">{{ session.operator.company !== undefined ? session.operator.company : '' }}</h3>
              </b-col>
            </b-row>
          </b-card-text>
        </b-col>
      </b-row>

    </b-card>
    <b-row class="m-1" v-show="session.operator.username !== 'superadmin'">
      <b-col cols="12" sm="9" md="12" lg="12" xl="12">
        <b-button
            id="update-password-button"
            @click="showChangePasswordModal = true"
        >
          Cambia password
        </b-button>
      </b-col>
    </b-row>
    <b-modal
        cancel-title="Annulla"
        no-close-on-backdrop
        size="sm"
        v-model="showChangePasswordModal"
        id="modal-change-password"
        ref="modal"
        title="Cambia password"
        @ok="changePassword(oldPassword, newPassword)"
        :ok-disabled='oldPassword.length === 0 || newPassword.length === 0 || !newPasswordState || !oldPasswordState || !repeatPasswordState'
    >
      <form ref="form">
        <b-form-group
            label="Vecchia password *"
        >
          <b-form-input
              :state="oldPassword.length > 0 ? oldPasswordState : null"
              aria-describedby="input-live-feedback"
              id="old-password-input"
              v-model="oldPassword"
              type="password"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="oldPassword.length < 6">
            La password è composta da almeno 5 caratteri
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
      <form ref="form">
        <b-form-group
            label="Nuova password *"
        >
          <b-form-input
              :state="newPassword.length > 0 ? newPasswordState : null"
              aria-describedby="input-live-feedback"
              id="new-password-input-1"
              v-model="newPassword"
              type="password"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="newPassword.length < 6 && newPassword !== oldPassword">
            La password deve essere composta da almeno 5 caratteri
          </b-form-invalid-feedback>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="newPassword === oldPassword">
            La nuova password non può essere uguale alla vecchia
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
      <form ref="form">
        <b-form-group
            label="Conferma nuova password *"
        >
          <b-form-input
              :state="repeatNewPassword.length > 4 ? repeatPasswordState : null"
              aria-describedby="input-live-feedback"
              id="new-password-input-2"
              v-model="repeatNewPassword"
              type="password"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="repeatNewPassword.length >= newPassword.length && repeatNewPassword !== newPassword">
            La password non corrisponde a quella inserita in precedenza
          </b-form-invalid-feedback>
          <b-form-invalid-feedback id="input-live-feedback"
                                   v-if="repeatNewPassword === oldPassword">
            La nuova password non può essere uguale alla vecchia
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from "vue"

export default {
  name: "UserProfile",
  data() {
    const session = Vue.getLocalStorage('session')
    return {
      session: session,
      showChangePasswordModal: false,
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: ''
    }
  },
  mounted() {
    this.$root.$on('changeMask', () => {
      this.session = Vue.getLocalStorage('session')
    })
  },
  methods: {
    changePassword(oldPw, newPw) {
      this.$root.$refs.Login.changePassword(oldPw, newPw)
      this.resetFields()
    },
    resetFields() {
      this.newPassword = ''
      this.oldPassword = ''
      this.repeatNewPassword = ''
    }
  },
  computed: {
    oldPasswordState() {
      return this.oldPassword.length > 4 && this.oldPassword.length < 1000
    },
    newPasswordState() {
      return this.newPassword.length > 4 && this.newPassword.length < 1000 && this.newPassword !== this.oldPassword
    },
    repeatPasswordState() {
      return this.repeatNewPassword.length === this.newPassword.length && this.repeatNewPassword === this.newPassword && this.repeatNewPassword !== this.oldPassword
    }
  },
}
</script>

<style scoped>

.card {
  border-radius: 25px;
  margin: 10px;
  padding: 1.5rem;
}

.svg-inline--fa {
  vertical-align: center;
  width: auto;
  height: auto;
  color: black;
}

.text {
  line-height: 50px;
}

.title {
  color: gray;
}

.container {
  justify-content: center;
  max-width: 85%;
  margin-top: 5rem;
  margin-bottom: 3rem;
  word-break: break-word;
  padding-right: unset;
  padding-left: unset;
}

</style>


