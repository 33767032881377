<template>
  <div :class="!stackedModeOn ? 'table-container' : 'table-responsive'">
    <b-row>
      <b-col cols="12" sm="9" md="6" lg="4" :xl="toggleButton ? '4' : '3'">
        <div v-if="!toggleButton" class="search">
          <span class="fa fa-search"></span>
          <b-form-input class="search-input" v-model="searchWord" id="input-search" debounce="500"
            placeholder="Ricerca" />
        </div>
        <div v-else>
          <label class="c-white">Aggiornamento ogni:</label>
          <b-input-group class="mb-2">
            <b-dropdown class="my-btn-secondary my-dropdown" :text="secondsDelay + ' secondi'" v-model="secondsDelay">
              <b-dropdown-item @click="secondsDelay = '5'">5 secondi</b-dropdown-item>
              <b-dropdown-item @click="secondsDelay = '10'">10 secondi</b-dropdown-item>
              <b-dropdown-item @click="secondsDelay = '15'">15 secondi</b-dropdown-item>
              <b-dropdown-item @click="secondsDelay = '30'">30 secondi</b-dropdown-item>
            </b-dropdown>
          </b-input-group>
        </div>

      </b-col>
      <div v-show="!toggleButton" class=" ml-3">
        <b-button id="filter-button" class="custom-table-button" v-b-toggle.my-collapse>
          <i class="fa fa-filter"></i>
        </b-button>
      </div>
      <b-col class="mb-3 mr-1">
        <b-button id="clean-filters" v-show="!toggleButton" @click="resetFilters()" class="ml-3 custom-table-button">
          Pulisci filtri
        </b-button>
      </b-col>


      <b-col cols="auto">
        <toggle-button class="toggle-button-margins my-btn-secondary" v-model="toggleButton" id="switch"
          :labels="{ checked: 'Tempo reale', unchecked: 'Storico' }" :height="40.8" :width="150" :font-size="15" />
      </b-col>
    </b-row>

    <b-collapse v-if="!toggleButton" id="my-collapse">
      <div class="mb-4">
        <b-row class="mb-0" style="height: 100%">
          <b-col cols="12" xl="3" lg="6" md="12" sm="12" class="grid-view">
            <b-card class="rounded-boxes mb-2" :class="!stackedModeOn ? '' : 'pb-0'">
              <b-col cols="12">
                <label>Seriale Flasher:</label>
                <b-input-group>
                  <b-dropdown id="flasher-serial-select" class="my-btn-secondary my-dropdown" :text=selectedSerial
                              offset=""
                              v-model="selectedSerial">
                    <b-dropdown-text class="dropdown-text-width">
                      Mostra flasher:
                    </b-dropdown-text>
                    <b-drop-down-item>
                      <toggle-button class="ml-4" v-model="toggleActiveFlashers" id="switch"
                          :labels="{checked: 'Attivi', unchecked: 'Tutti'}" :height="20.4" :width="75"
                          :font-size="15"
                      />
                    </b-drop-down-item>
                    <b-dropdown-text class="dropdown-text-width" v-if="filteredFlasherSerials.length === 0">Non ci sono opzioni
                      disponibili
                    </b-dropdown-text>
                    <b-dropdown-item v-if="selectedSerial !== 'Seleziona seriale del flasher'"
                                    @click="selectedSerial = 'Seleziona seriale del flasher'">Tutti
                    </b-dropdown-item>
                    <b-dropdown-item :id="item.index" v-for="item in filteredFlasherSerials" :key="item"
                                    @click="selectedSerial = item.serial">{{
                        item.serial
                      }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group>
              </b-col>

              <b-col cols="12">
                <label>Stato Flasher:</label>
                <b-input-group>
                  <b-dropdown id="status-select" class="my-btn-secondary my-dropdown "
                    :text="selectedStatus.status_translate || selectedStatus" v-model="selectedStatus">
                    <b-dropdown-item v-if="selectedStatus !== 'Seleziona stato del flasher'"
                      @click="selectedStatus = 'Seleziona stato del flasher'">Tutti
                    </b-dropdown-item>
                    <b-dropdown-item :key="item.id" v-for="item in status" @click="selectedStatus = item">
                      {{ item.status_translate }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group>
              </b-col>
            </b-card>

            <b-card class="rounded-boxes" :class="!stackedModeOn ? 'mb-0' : 'mb-2'">
              <b-col cols="12">
                <label>Visualizza:</label>
                <b-input-group>
                  <b-dropdown id="flasherObuAll-select" :disabled="disableShowingOperation"
                    class="my-btn-secondary my-dropdown" :text=selectShowingOperations offset=""
                    v-model="selectShowingOperations">
                    <b-dropdown-item v-if="selectShowingOperations !== 'Tutte le operazioni'"
                      @click="selectShowingOperations = 'Tutte le operazioni'">Tutte le operazioni
                    </b-dropdown-item>
                    <b-dropdown-item :id="item.index" v-for="item in showingOperations" :key="item"
                      @click="selectShowingOperations = item">{{
                        item
                      }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group>
              </b-col>

              <b-col cols="12" class="margin-bottom-multiselect">
                <label>ID OBU:</label>
                <b-input-group class="search">
                  <multiselect style="width: 80%" :select-label="''" :deselect-label="''" :selected-label="''"
                    :disabled=disableOBUIDFilter id="obu-select" :options="obu" v-model="selectedOBUID"
                    placeholder="ID OBU" @cancel="selectedOBUID = ''"></multiselect>
                  <div style="width: 20%" class="input-group-append">
                    <b-button class="fas fa-trash" :disabled="!selectedOBUID"
                      @click="selectedOBUID = ''; disableShowingOperation = false">
                    </b-button>
                  </div>
                </b-input-group>
              </b-col>
            </b-card>
          </b-col>

          <b-col cols="12" xl="3" lg="6" md="12" sm="12" class="grid-view" :class="!stackedModeOn ? 'mb-0' : 'mb-2'">
            <b-card class="rounded-boxes" :class="!stackedModeOn ? '' : 'pb-3'">
              <b-col cols="12" class="margin-bottom-multiselect">
                <label>Versione software:</label>
                <b-input-group class="search">
                  <multiselect :select-label="''" :deselect-label="''" :selected-label="''" id="version-select"
                    style="margin-bottom: 0; width: 80%" :options="versions" label="software_description"
                    track-by="software_description" v-model="selectedVersion" placeholder="Seleziona versione">
                  </multiselect>
                  <div style="width: 20%" class="input-group-append">
                    <b-button class="fas fa-trash" :disabled="!selectedVersion" @click="selectedVersion = '';">
                    </b-button>
                  </div>
                </b-input-group>
              </b-col>
              <b-col>
                <div class="d-flex justify-content-end">
                  <toggle-button v-model="toggleActiveSoftwareVersions" id="switch-software-version"
                    :labels="{ checked: 'Attivi', unchecked: 'Tutti' }" :height="20.4" :width="75" :font-size="15"
                    @change="handleSoftwareVersions" />
                </div>
              </b-col>
            </b-card>
          </b-col>


          <b-col cols="12" xl="3" lg="6" md="12" sm="12" class="grid-view">
            <b-card class="rounded-boxes" :class="!stackedModeOn ? 'mb-5' : 'mb-2'">
              <b-col cols="12" class="margin-bottom-multiselect">
                <label>Tipo di operazione:</label>
                <b-input-group class="search">
                  <multiselect style="width: 80%" :select-label="''" :deselect-label="''" :selected-label="''"
                    id="operation-type-select" :options="this.operationDes" label="name" track-by="name"
                    v-model="selectedOption" placeholder="Seleziona operazione"></multiselect>
                  <div style="width: 20%" class="input-group-append">
                    <b-button class="fas fa-trash" :disabled="!selectedOption" @click="selectedOption = '';">
                    </b-button>
                  </div>
                </b-input-group>
              </b-col>

              <b-col cols="12" class="margin-bottom-multiselect">
                <label>N° log:</label>
                <b-input-group class="mb-2">
                  <b-form-input @keypress="isNumber($event)" id="record-select" v-model="numberOfRecords"
                    :placeholder=numberOfRecords :state="numberOfRecords !== '500' ? isValidNumberOfRecords : null"
                    type="number" max="9999"></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback" v-if="numberOfRecords > 9999">
                    Il numero di record non può superare 9999
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback id="input-live-feedback-2" v-if="numberOfRecords <= 0">
                    Il numero di record non può essere negativo o nullo
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>

              <b-col cols="12">
                <label>Operazione avvenuta con:</label>
                <b-input-group class="mb-2">
                  <b-dropdown id="success-select" class="my-btn-secondary my-dropdown" :text=selectedOperationStatus
                    offset="" v-model="selectedOperationStatus">
                    <b-dropdown-item v-if="selectedOperationStatus !== 'Seleziona stato dell\'operazione'"
                      @click="selectedOperationStatus = 'Seleziona stato dell\'operazione'">Tutte
                    </b-dropdown-item>
                    <b-dropdown-item @click="selectedOperationStatus = 'Errore'">Errore</b-dropdown-item>
                    <b-dropdown-item @click="selectedOperationStatus = 'Successo'">Successo</b-dropdown-item>
                  </b-dropdown>
                </b-input-group>
              </b-col>
            </b-card>

            <b-card class="rounded-boxes" :class="!stackedModeOn ? 'mb-0' : 'mb-3'">
              <b-col cols="12">
                <label>Tipo di veicolo:</label>
                <b-input-group class="search" style="margin-bottom: 0">
                  <b-dropdown id="vehicle-select" class="my-btn-secondary my-dropdown"
                    :text="selectedVehicleFilter.Type || selectedVehicleFilter" v-model="selectedVehicleFilter">
                    <b-dropdown-item v-if="selectedVehicleFilter !== 'Seleziona tipo di veicolo'"
                      @click="selectedVehicleFilter = 'Seleziona tipo di veicolo';">Tutti
                    </b-dropdown-item>
                    <b-dropdown-item :key="item.id" v-for="item in vehicles" @click="selectedVehicleFilter = item;">
                      {{ item.Type }}
                    </b-dropdown-item>

                  </b-dropdown>
                </b-input-group>
              </b-col>
            </b-card>
          </b-col>

          <b-col cols="12" xl="3" lg="6" md="12" sm="12" :class="!stackedModeOn ? 'flex-view' : 'grid-view'">
            <b-card class="rounded-boxes">
              <b-row align-v="center" class="mb-6">
                <b-col cols="12">
                  <label>Da:</label>
                  <b-input-group class="search">
                    <b-form-datepicker :state="isValidDate" id="datepicker-start" :max="endDate" v-model="startDate"
                      class="mb-2" placeholder="Seleziona data">

                    </b-form-datepicker>
                    <div class="input-group-append">
                      <b-button class="fas fa-trash mb-2" :disabled="!startDate"
                        @click="startDate = ''; startTime = '00:00:00'; items = originalItems">
                      </b-button>
                    </div>
                  </b-input-group>
                </b-col>
                <b-col cols="12">
                  <b-input-group class="search">
                    <b-form-input v-model="startTime" id="input-start-time" type="time" value="HH:mm:ss"
                      placeholder="Seleziona orario di inizio" :state="startDate !== '' ? isValidTime : null" step="2"
                      required></b-form-input>
                    <b-form-timepicker id="timepicker-start" v-model="startTime" show-seconds button-only right
                      aria-describedby="input-live-feedback">
                    </b-form-timepicker>
                    <div class="input-group-append">
                      <b-button class="fas fa-trash" :disabled="!startTime"
                        @click="startTime = '00:00:00'; items = originalItems">
                      </b-button>
                    </div>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <label>a:</label>
                  <b-input-group class="search">
                    <b-form-datepicker :state="isValidDate" id="datepicker-end" :min="startDate" v-model="endDate"
                      :clear-button="true" placeholder="Seleziona data">
                    </b-form-datepicker>
                    <div class="input-group-append">
                      <b-button class="fas fa-trash" :disabled="!endDate"
                        @click="endDate = ''; endTime = '00:00:00'; items = originalItems">

                      </b-button>
                    </div>
                  </b-input-group>
                </b-col>

                <b-col cols="12">
                  <b-input-group class="search">
                    <b-form-input v-model="endTime" id="input-end-time" type="time" value="HH:mm:ss"
                      placeholder="Seleziona orario di fine" :state="endDate !== '' ? isValidTime : null" step="2"
                      required></b-form-input>
                    <b-form-timepicker aria-describedby="input-live-feedback" id="timepicker-end" v-model="endTime"
                      button-only right :clear-button="true" placeholder="Seleziona orario di fine" show-seconds>
                    </b-form-timepicker>
                    <div class="input-group-append">
                      <b-button class="fas fa-trash" :disabled="!endTime"
                        @click="endTime = '00:00:00'; items = originalItems">

                      </b-button>
                    </div>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card>
            <b-row>


            </b-row>
          </b-col>


        </b-row>
      </div>
    </b-collapse>
    <b-table id="logsTable" tbody-tr-class="trasparent" class="table table-font" :items="items" :fields="fields" hover
      :sort-by="sortBy" :sort-desc=true responsive="sm" :fixed="fixed !== false ? true : null" :stacked="stackedModeOn"
      :current-page="currentPage" :per-page="perPage" :class="!stackedModeOn ? 'notStacked logs-table' : ''"
      :empty-text="'Non ci sono dati da visualizzare'" :show-empty="true">
      <template v-slot:cell(success)="row">
        <i v-if="row.item.success" v-b-tooltip.hover :title="'Operazione avvenuta con successo'"
          class="fas fa-circle c-green" />
        <i v-else v-b-tooltip.hover :title="'Operazione avvenuta con errore'" class="fas fa-circle c-red" />
      </template>

      <template v-slot:cell(description)="row">
        <div class="description-text" v-if="row.item.description_excel">
          <i v-if="row.item.build_package && row.item.build_package.trim() !== ''" class="fa fa-archive" />
          {{ row.item.build_package ? row.item.build_package : '' }} <br>
          <i class="fa fa-window-maximize" v-if="row.item.sw_description && row.item.sw_description.trim() !== ''" />
          {{ row.item.sw_description ? row.item.sw_description : '' }} <br>
          <div>
            <i class="fa fa-file"
              v-if="(row.item.file_description && row.item.sw_description.trim() !== '') || (row.item.file_name && row.item.file_name.trim() !== '')" />
            {{ row.item.file_description ? row.item.file_description + '-' : '' }}
            {{ row.item.file_name ? row.item.file_name : '' }}<br>
          </div>
        </div>
      </template>
      <template v-slot:cell(operation_code_des)="row">
        <i v-b-tooltip.hover :title='row.item.operation_code_id'> {{row.item.operation_code_des}}</i>
      </template>
    </b-table>

    <b-row>
      <b-col>
        <b-pagination class="my-pagination " :per-page="perPage" v-model="currentPage" :size="stackedModeOn ? 'sm' : 'md'"
          aria-controls="logsTable" :total-rows="numberOfRows">
        </b-pagination>
      </b-col>
      <b-col cols="5" sm="1" md="3">
        <b-form-group horizontal :label="''" :label-cols="5">
          <b-form-select :options="pageOptions" v-model="perPage" />
        </b-form-group>
      </b-col>
      <b-col cols="7" sm="2" md="2" lg="auto">
        <b-button class="my-button"
          @click="excel('Operazioni'); fields.find(field => field.key === 'success').label = ''"> Excel
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import myOperations from "@/plugin/conf";
import moment from 'moment';
import myUsefulFunctions from "@/plugin/my-useful-functions";

export default {
  data() {
    return {

      sortBy: 'timestamp',
      selectedStatus: 'Seleziona stato del flasher',
      selectedOperationStatus: 'Tutte',
      selectedOption: '',
      toggleButton: true,
      toggleActiveFlashers: true,
      searchWord: null,
      originalItems: [],
      status: [],
      operationCodes: null,
      operationDes: [],
      currentPage: 1,
      perPage: 100,
      pageOptions: [5, 10, 25, 50, 100],
      containerClass: '',
      stackedModeOn: null,
      sortDesc: false,
      startDate: '',
      endDate: '',
      startTime: '00:00:00',
      endTime: '00:00:00',
      timer: '',
      refresh: false,
      versions: [],
      fields: [
        { key: 'flasher_serial', label: 'Seriale del flasher', sortable: false },
        { key: 'obu_id', label: 'ID OBU', sortable: true, },
        { key: 'operation_code_des', label: 'Tipo di operazione', sortable: true },
        { key: 'firmware_version', label: 'Versione del software', sortable: true },
        { key: 'description', label: 'Tipo del software', sortable: false, },
        { key: 'status_object.status_translate', label: 'Stato del flasher', sortable: true },
        { key: 'date_string', label: 'Data', sortable: true },
        { key: 'vehicle_type_string', label: 'Tipo di veicolo', sortable: false },
        { key: 'success', label: '', value: Boolean }
      ],
      items: [
        {
          id: '',
          obu_id: '',
          operation_code_id: '',
          operation_code_des: '',
          firmware_version_id: '',
          firmware_version: '',
          status_id: '',
          status_object: {},
          timestamp: '',
          success: '',
          date_string: '',
          sw_description: '',
          build_package: '',
          file_description: '',
          file_name: '',
          vehicle_type_id: '',
          vehicle_type_string: '',
        }
      ],
      numberOfRecords: '500',
      flasherSerials: [],
      selectedSerial: 'Seleziona seriale del flasher',
      selectedVersion: '',
      software: [],
      fileDescription: [],
      obu: [],
      build: [],
      fileName: [],
      vehicles: [],
      selectedTypeFile: 'Seleziona tipo di file',
      selectedSoftware: 'Seleziona tipo di software',
      selectedOBUID: '',
      selectedFileName: 'Seleziona nome del file',
      selectedBuild: 'Seleziona nome pacchetto',
      format: 'hh:mm:ss',
      minTime: '',
      maxTime: '',
      selectedVehicleFilter: 'Seleziona tipo di veicolo',
      secondsDelay: '10',
      showingOperations: [
        'Operazioni sugli OBU',
        'Operazioni del flasher'
      ],
      selectShowingOperations: 'Tutte le operazioni',
      disableOBUIDFilter: false,
      disableShowingOperation: false,
      toggleActiveSoftwareVersions: true
    }
  },
  computed: {
    filteredFlasherSerials() {
      return this.flasherSerials.filter(item => (this.toggleActiveFlashers ? !item.removed : true))
    },
    numberOfRows() {
      return this.items.length
    },
    isValidNumberOfRecords() {
      return this.numberOfRecords < 10000 && this.numberOfRecords > 0
    },
    isValidTime() {
      if (this.startDate === this.endDate && this.startDate !== '')
        return moment(this.startTime, this.format).isBefore(moment(this.endTime, this.format))
      return true
    },
    isValidDate() {
      var newMonth = new Date(this.startDate).getMonth() + 3
      return new Date(new Date(this.startDate).setMonth(newMonth)) > new Date(this.endDate) || this.startDate === '' || this.endDate === ''
    }
  },
  created() {
    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md' || size === 'lg'
    this.fetchData()
    if (this.toggleButton) {
      this.timer = setInterval(this.fetchData, this.secondsDelay * 1000);
      Vue.setLocalStorage('timer', this.timer)
    }
  },
  methods: {
    async fetchData() {
      if (!this.refresh) {
        this.refresh = true
        this.status = await myUsefulFunctions.getStatus()
        this.operationDes = await myUsefulFunctions.getOperationDes()
        this.operationCodes = await myUsefulFunctions.getOperationCodes()
        this.vehicles = await myUsefulFunctions.getVehicle()
        // this.flasherSerials = await myUsefulFunctions.getFlasherSerials()
        this.flasherSerials = await myUsefulFunctions.getFlasherSerialsWActive()
        this.obu = await myUsefulFunctions.getObu()
        this.software = await myUsefulFunctions.getSoftwareDescription()
        this.build = await myUsefulFunctions.getBuild()
        this.fileDescription = await myUsefulFunctions.getFileDescription();
        this.fileName = await myUsefulFunctions.getFilename()
        this.handleSoftwareVersions()
      }
      this.items = await myOperations.getOperations({
        operationCodes: this.operationDes,
        status: this.status,
        versions: this.versions,
        vehicles: this.vehicles,
        records: this.numberOfRecords
      })
      this.originalItems = this.items
    },
    async handleSoftwareVersions() {
      if (this.toggleActiveSoftwareVersions) {
        this.versions= await Vue.myGet(this, '/version?valid=true', 'versions')
      }
      else {
        this.versions = await myUsefulFunctions.getVersions()
      }

      
      this.versions.forEach(element => {
        let swTypeStr = ''
        let swDescr = ''
        try {
          const obj = JSON.parse(element.recipe);
          swTypeStr = 'swType: ' + obj.software.swType
          swDescr = obj.software.swDescription;
        }
        catch
        {    
          swTypeStr = 'swType: None'
          swDescr = element.notes
        }
        element.software_description = (swDescr + ' - ' + swTypeStr + ' - ' + element.firmware_version)
      });
      this.filterFunction()
    },
    resetFilters() {
      if (this.selectedStatus !== 'Seleziona stato del flasher')
        this.selectedStatus = 'Seleziona stato del flasher'
      if (this.selectedOperationStatus !== 'Seleziona stato dell\'operazione')
        this.selectedOperationStatus = 'Seleziona stato dell\'operazione'
      if (this.selectedSerial !== 'Seleziona seriale del flasher')
        this.selectedSerial = 'Seleziona seriale del flasher'
      if (this.numberOfRecords !== '500')
        this.numberOfRecords = '500'
      if (this.searchWord !== null)
        this.searchWord = null
      if (this.startDate)
        this.startDate = ''
      if (this.endDate)
        this.endDate = ''
      if (this.startTime)
        this.startTime = '00:00:00'
      if (this.endTime)
        this.endTime = '00:00:00'
      if (this.selectedOption)
        this.selectedOption = ''
      if (this.selectedVersion !== '')
        this.selectedVersion = ''
      if (this.selectedOBUID !== '')
        this.selectedOBUID = ''
      if (this.selectedFileName !== 'Seleziona nome del file')
        this.selectedFileName = 'Seleziona nome del file'
      if (this.selectedTypeFile !== 'Seleziona tipo di file')
        this.selectedTypeFile = 'Seleziona tipo di file'
      if (this.selectedSoftware !== 'Seleziona tipo di software')
        this.selectedSoftware = 'Seleziona tipo di software'
      if (this.selectedBuild !== 'Seleziona nome pacchetto')
        this.selectedBuild = 'Seleziona nome pacchetto'
      if (!_.isUndefined(this.selectShowingOperations) && !this.selectShowingOperations.includes('Tutte'))
        this.selectShowingOperations = 'Tutte le operazioni'
      this.selectedVehicleFilter = 'Seleziona tipo di veicolo'
      this.items = this.originalItems
      this.disableOBUIDFilter = false;
      this.disableShowingOperation = false
    },
    excel(fileName, customFields) {
      let newFields = _.cloneDeep(this.fields)
      newFields.forEach((field) => {
        if (field.key === 'description')
          field.key = 'description_excel'
      })
      return Vue.excel(
        customFields || newFields,
        this.items,
        fileName
      )
    },
    async filterFunction() {

      if (this.isValidTime && this.isValidDate) {

        this.items = await myOperations.getOperations({
          operationCodes: this.operationDes,
          status: this.status,
          versions: this.versions,
          vehicles: this.vehicles,
          records: this.numberOfRecords,
          serial: this.selectedSerial,
          option: this.selectedOption,
          version: this.selectedVersion,
          OBUID: !_.isNull(this.selectedOBUID) ? this.selectedOBUID : '',
          fileName: this.selectedFileName,
          fileDescription: this.selectedTypeFile,
          software: this.selectedSoftware,
          build: this.selectedBuild,
          statusId: this.selectedStatus.id ? this.selectedStatus.id.toString() : this.selectedStatus,
          startDate: this.startDate,
          startTime: this.startTime,
          endDate: this.endDate,
          endTime: this.endTime,
          vehicle: this.selectedVehicleFilter,
          operationStatus: this.selectedOperationStatus,
          searchWord: this.searchWord,
          showingOperations: this.selectShowingOperations
        }
        )
      }
    },

    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  props: [
    'fixed',
  ],
  watch: {
    async startDate() {
      await this.filterFunction()
    },
    async endDate() {
      await this.filterFunction()
    },
    async searchWord() {
      await this.filterFunction()
    },
    selectedOperationStatus() {
      this.filterFunction()
    },
    async startTime() {
      await this.filterFunction()
    },
    async endTime() {
      await this.filterFunction()
    },
    toggleButton() {
      this.resetFilters()
      if (this.toggleButton) {
        this.fetchData()
        this.timer = setInterval(this.fetchData, this.secondsDelay * 1000);
        Vue.setLocalStorage('timer', this.timer)
      } else {
        clearInterval(this.timer);
        Vue.setLocalStorage('timer', '')
        this.fetchData()
      }
    },
    async selectedOption() {
      await this.filterFunction()
    },
    async numberOfRecords() {
      if (this.isValidNumberOfRecords)
        await this.filterFunction()
    },
    async selectedSerial() {
      await this.filterFunction()
    },
    async selectedVersion() {
      await this.filterFunction()
    },
    selectedOBUID() {
      if ((this.selectedOBUID !== '' && !this.disableShowingOperation) || _.isNull(this.selectedOBUID))
        this.disableShowingOperation = !this.disableShowingOperation;
      this.filterFunction()
    },
    async selectedFileName() {
      await this.filterFunction()
    },
    async selectedTypeFile() {
      await this.filterFunction()
    },
    async selectedSoftware() {
      await this.filterFunction()
    },
    async selectedBuild() {
      await this.filterFunction()
    },
    async selectedStatus() {
      await this.filterFunction()
    },
    async selectedVehicleFilter() {
      await this.filterFunction()
    },

    async selectShowingOperations() {
      this.disableOBUIDFilter = !_.isUndefined(this.selectShowingOperations) && this.selectShowingOperations.includes('flasher');
      await this.filterFunction()
    },

    secondsDelay() {
      clearInterval(this.timer);
      this.timer = setInterval(this.fetchData, this.secondsDelay * 1000);
      Vue.setLocalStorage('timer', this.timer)
    },
    isValidTime() {
      if (!this.isValidTime) {
        this.items = []
      }
    },
    isValidDate() {
      if (!this.isValidDate) {
        this.items = []
      }
    }
  },
}

</script>

<style>
.table-container {
  width: 100%;
  margin: 3rem;
}

.margin-bottom-multiselect {
  margin-bottom: 2rem;
}

.b-table-sticky-header>.table,
.table-responsive>.table,
[class*="table-responsive-"]>.table {
  margin-bottom: 1rem;
}

.table-responsive {
  margin: 1rem;
}

.row {
  margin-right: 0;
}

.table-font {
  font-size: 0.9rem;
  line-height: 1;
}

.dropdown-menu,
.my-btn-secondary {
  margin-right: 0;
  margin-bottom: 2rem;
  border-radius: 15px;
  font-size: 1.2rem;
}

.my-btn-secondary {
  min-width: auto;

}

.my-dropdown,
.btn {
  border-radius: 15px;
  font-size: 1.2rem;
}

.toggle-button-margins {
  margin-bottom: 2.5rem;
}

.c-green {
  color: seagreen;
}

.c-red {
  color: red;
}

.c-white {
  color: white;
}

.table-font {
  word-break: break-word;
}

.dropdown-toggle {
  white-space: unset;
}

/* multiselect style */
fieldset[disabled] .multiselect {
  pointer-events: none
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  background: #fff;
  display: block
}

.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent
}

.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(.41, .26, .2, .62);
  animation-iteration-count: infinite
}

.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(.51, .09, .21, .8);
  animation-iteration-count: infinite
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity .4s ease-in-out;
  opacity: 1
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 1.2rem;
  -ms-touch-action: manipulation;
  touch-action: manipulation
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  border-radius: 0;
  text-align: left;
  color: #35495e
}

.multiselect * {
  box-sizing: border-box
}

.multiselect:focus {
  outline: none
}

.multiselect--disabled {
  border-radius: 15px;
  background: #ededed;
  pointer-events: none;
  opacity: .6
}

.multiselect--active {
  z-index: 50
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.multiselect--active .multiselect__select {
  transform: rotate(180deg)
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  width: 100%;
  transition: border .1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.multiselect__input:-ms-input-placeholder {
  color: #35495e
}

.multiselect__input::placeholder {
  color: #35495e
}

.multiselect__tag~.multiselect__input,
.multiselect__tag~.multiselect__single {}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none
}

.multiselect__tags-wrap {
  display: inline
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 0.1rem 3rem 0 1rem;
  border-radius: 0;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 1.2rem;

}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 0.1rem 3rem 0 1rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 15px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 1.2rem;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 0;
  border-radius: 0px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: break-spaces;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all .2s ease;
  border-radius: 15px
}

.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 1.2rem
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff
}

.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 2px;
  white-space: break-spaces;
  border-radius: 15px;
  border: 1px solid #e8e8e8
}

.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  border-radius: 15px;
}

.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  text-align: center;
  transition: transform .2s ease
}

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ""
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 1px;
  margin-top: 1px;
}

.multiselect--active .multiselect__placeholder {
  display: none
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8
}

.multiselect__content::webkit-scrollbar {
  display: none
}

.multiselect__element {
  display: block
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 1.2rem
}

.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6
}

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all .15s ease
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top
}

[dir=rtl] .multiselect {
  text-align: right
}

[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px
}

[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px
}

[dir=rtl] .multiselect__content {
  text-align: right
}

[dir=rtl] .multiselect__option:after {
  left: 0
}

[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px
}

[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px
}

@keyframes spinning {
  0% {
    transform: rotate(0)
  }

  to {
    transform: rotate(2turn)
  }
}

.logs-table>.table tr td:last-child,
.logs-table>.table tr th:last-child {
  width: 3rem;

}

.dropdown-text-width {
  min-width: 15rem;
}

#timepicker-start,
#timepicker-end {
  border-radius: 0;
}

.rounded-boxes {
  border-radius: 15px;
}

.card-body {
  padding-bottom: 0;
}

.grid-view {
  display: grid;
}

.flex-view {
  display: flex;
}

.contents-view {
  display: contents;
}

#record-select {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.table {
  border-radius: 25px;
  text-align: center;
  overflow: clip;
}

.mb-6 {
  margin-bottom: 4rem !important;
}
</style>
