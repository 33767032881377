/* eslint-disable no-param-reassign */

const MyGetWindowSize = {
    install(Vue) {
        Vue.myGetWindowSize = () => {
            const innerWidth = window.innerWidth
            let size = 'cols'
            if (innerWidth < 576) {
                size = 'cols'
            }
            if (innerWidth >= 576) {
                size = 'sm'
            }
            if (innerWidth >= 768) {
                size = 'md'
            }
            if (innerWidth >= 992) {
                size = 'lg'
            }
            if (innerWidth >= 1200) {
                size = 'xl'
            }
            return size
        }
    }
}

export default MyGetWindowSize
