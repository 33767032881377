import _ from "lodash";
import Vue from "vue";
import myFlasherStatusTranslate from "@/plugin/my-flasher-status-translate";
import myOperationCodeTranslate from "@/plugin/my-operation-code-translate";

const myUsefulFunctions = {
    translateDate(date) {
        if (date)
            return new Date(date).toLocaleDateString("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })
        return ''
    },
    filterSearchWord(items, fields, word) {
        const editedItems = []
        items.forEach(item => {
            let pushed = false
            fields.forEach((field) => {

                const itemValue =
                    field.key === 'versions' && item['additions']['active_version_string']
                        ? JSON.stringify(item['additions']['active_version_string']).toLowerCase()
                        : field.key.split('.').length === 2 && item[field.key.split('.')[0]][field.key.split('.')[1]]
                        ? JSON.stringify(item[field.key.split('.')[0]][field.key.split('.')[1]]).toLowerCase()
                        : field.key.split('.').length === 3 && item[field.key.split('.')[0]][field.key.split('.')[1]][field.key.split('.')[2]]
                            ? JSON.stringify(item[field.key.split('.')[0]][field.key.split('.')[1]][field.key.split('.')[2]]).toLowerCase()
                            : field.key && item[field.key]
                                ? JSON.stringify(item[field.key]).toLowerCase()
                                : ''
                if (itemValue.indexOf(word.toLowerCase()) > -1 && !pushed) {
                    editedItems.push(item)
                    pushed = true
                }
            })
        })
        items = editedItems
        return items
    },
    translateSomeFields(items) {
        items.forEach((version) => {
            version.description_excel = ''
            version.description_excel = !_.isNil(version.firmware_uri) ? version.description_excel.concat('Pacchetto di build: ' + version.firmware_uri.split(/_(.+)/)[1] + ' \n') : version.description_excel
            if (!_.isNil(version.valid_date)) {
                version.formatted_date = this.translateDate(version.valid_date)
            }
            if (JSON.parse(version.recipe).software !== undefined) {
                const obj = JSON.parse(version.recipe).software
                version.description_excel = !_.isNil(obj.swDescription) ? version.description_excel.concat('Tipo di software: ' + obj.swDescription) : version.description_excel
                version.software_type = !_.isNil(obj.swDescription) ? obj.swDescription : ''
                if (!_.isNil(obj.file)) {
                    version.files = []
                    let children = []
                    for (let i = 0; i < obj.file.length; i++) {
                        version.files[i] = obj.file[i].fileDescription + ' (Type ' + obj.file[i].fileType + ')' + ' - ' + obj.file[i].fileName
                        children.push({text: version.files[i]})
                        version.description_excel = version.description_excel.concat('\nTipo di file: ' + obj.file[i].fileDescription + ' (Type ' + obj.file[i].fileType + ')')
                        version.description_excel = version.description_excel.concat('\nNome del file: ' + obj.file[i].fileName)
                    }
                    version.firmware_popup = [
                        {
                            text: !_.isNil(version.firmware_uri) ? version.firmware_uri.split(/_(.+)/)[1] : '',
                            children: [{text: obj.swDescription, children: children}]
                        }
                    ]
                }
            }
        })
        return items
    },

    async getFileDescription() {
        let fileDescription = []
        await Vue.myGet(this, '/operation/filedescription').then((file_description) => {
            if (!_.isNil(file_description) && file_description.length > 0) {
                file_description.forEach((item) => {
                    if (item.file_description)
                        fileDescription.push(
                            item.file_description
                        )
                })
                fileDescription.sort((a, b) => a.localeCompare(b, 'es', {sensitivity: 'base'}))
            }
        })
        return fileDescription
    },

    async getSoftwareDescription() {
        let softwareList = []
        await Vue.myGet(this, '/operation/software').then((software) => {
            if (!_.isNil(software) && software.length > 0) {
                software.forEach((item) => {
                    if (item.sw_description && item.sw_description.trim())
                        softwareList.push(
                            item.sw_description
                        )
                })
                softwareList.sort((a, b) => a.localeCompare(b, 'es', {sensitivity: 'base'}))
            }
        })
        return softwareList
    },

    async getStatus() {
        let statusList = []
        await Vue.myGet(this, '/status', 'status').then(async (status) => {
            if (!_.isNil(status) && status.length > 0) {
                status.forEach((state) => {
                    state.status_translate = myFlasherStatusTranslate.statusTranslate(state.id)
                    statusList.push(state)
                })
                statusList.sort((a, b) => a.status_translate.localeCompare(b.status_translate, 'es', {sensitivity: 'base'}))
            }
        })
        return statusList;
    },

    async getOperationCodes() {
        let operationCodesList = []
        await Vue.myGet(this, '/operation_code', 'operationCodes').then(async (operationCodes) => {
            if (!_.isNil(operationCodes)) {
                operationCodesList = operationCodes
            }
        })
        return operationCodesList
    },

    async getOperationDes() {
        let operationDes = []
        const flashingOpCode = {
            id: 100,
            name: "",
            success: false
          };
        await Vue.myGet(this, '/operation_code', 'operationCodes').then((operationCodes) => {
            if (!_.isNil(operationCodes) && operationCodes.length > 0) {
                operationCodes.forEach((item) => {
                    item.name = myOperationCodeTranslate.operationCode(item.id)
                    operationDes.push(item)
                })
                flashingOpCode.name= myOperationCodeTranslate.operationCode(flashingOpCode.id)
                operationDes.push(flashingOpCode)
                operationDes.sort((a, b) => a.name.localeCompare(b.name, 'es', {sensitivity: 'base'}))
            }
        })
        return operationDes
    },

    async getVehicle() {
        let vehicles = []
        await Vue.myGet(this, '/vehicle').then((vehicle) => {
            if (!_.isNil(vehicle) && vehicle.length > 0)
                vehicle.shift()
                vehicles = vehicle.reverse()
              /*  vehicle.forEach((item,index) => {
                    if (index < 2) vehicles.push(item)
                })*/
        })
        return vehicles
    },

    async getFlasherSerials() {
        let flasherSerials = []
        await Vue.myGet(this, '/flasher').then((flashers) => {
            if (!_.isNil(flashers) && flashers.length > 0) {
                flashers.forEach((item) => {
                    flasherSerials.push(item.flasher_serial.trim())
                })
                flasherSerials.sort((a, b) => a.localeCompare(b, 'es', {sensitivity: 'base'}))
            }
        })

        return flasherSerials
    },

    // returns a list of flasher ids and their active state
    async getFlasherSerialsWActive() {
        let flasherSerials = []
        await Vue.myGet(this, '/flasher').then((flashers) => {
            if (!_.isNil(flashers) && flashers.length > 0) {
                flashers.forEach((item) => {
                    flasherSerials.push({
                            serial: item.flasher_serial.trim(),
                            removed: item.removed
                        })
                })
            }
        })

        return flasherSerials
    },

    async getFlashers({id, flasherSerial}) {
        let flashers = []
        await Vue.myGet(this, id ? '/flasher/' + id : flasherSerial ? '/flasher?flasher_serial=' + flasherSerial : '/flasher').then((result) => {
            if (!_.isNil(result) && result.length > 0) {
                flashers = result
                flashers.sort((a, b) => a.toString().localeCompare(b, 'es', {sensitivity: 'base'}))
            }
        })
        return flashers
    },

    async getNotRemovedFlashers() {
        let notRemovedFlashers = []
        return await Vue.myGet(this, '/flasher?removed=false', 'flasher').then(async (flashers) => {
            notRemovedFlashers = flashers
                    await Promise.all(notRemovedFlashers.map(async (item) => {
                        await Vue.myGet(this, '/flasher_version/' + item.id + '/active', 'flasher').then(async (flasherVersion) => {
                            await Vue.myGet(this, '/version/' + flasherVersion.id_version, 'flasher').then((version) => {
                                if(JSON.parse(version.recipe).software !== undefined) {
                                    const obj = JSON.parse(version.recipe).software
                                    item.swType = parseInt(obj.swType)
                                }
                            }).catch(() => {
                                item.swType = null
                            })
                        })
                    })).then(() => {
                        return notRemovedFlashers
                    })
            return notRemovedFlashers
        })
    },

    async getObu() {
        let obuList = []
        await Vue.myGet(this, '/operation/obu').then((obu) => {
            if (!_.isNil(obu) && obu.length > 0) {
                obu.forEach((item) => {
                    if (item.obu_id)
                        obuList.push(item.obu_id)
                })
                obuList.sort()
            }
        })
        return obuList
    },

    async getBuild() {
        let buildList = []
        await Vue.myGet(this, '/operation/build').then((build) => {
            if (!_.isNil(build) && build.length > 0) {
                build.forEach((item) => {
                    if (item.build_package)
                        buildList.push(item.build_package)
                })
                buildList.sort((a, b) => a.localeCompare(b, 'es', {sensitivity: 'base'}))
            }
        })
        return buildList
    },

    async getFilename() {
        let fileNames = []
        await Vue.myGet(this, '/operation/filename').then((file_name) => {
            if (!_.isNil(file_name) && file_name.length > 0) {
                file_name.forEach((item) => {
                    if (item.file_name)
                        fileNames.push(item.file_name)
                })
                fileNames.sort((a, b) => a.localeCompare(b, 'es', {sensitivity: 'base'}))
            }
        })
        return fileNames
    },

    async getVersions() {
        let versionsList = []
        await Vue.myGet(this, '/version', 'versions').then((versions) => {
            if (!_.isNil(versions) && versions.length > 0) {
                versionsList = versions
                versionsList.sort((a, b) => a.firmware_version < b.firmware_version ? -1 : 1)
            }
        })
        return versionsList
    },
}

export default myUsefulFunctions
