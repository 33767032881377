import Router from 'vue-router'
import Login from "../components/Login.vue"
import Home from "../components/Home.vue"
import Vue from "vue";
import UserProfile from "../components/UserProfile.vue";
import VersionTable from "@/components/VersionTable";
import MyGetWindowSize from '@/plugin/my-get-window-size'
import MyExcelFromDate from '@/plugin/my-excel-from-table'
import MyServerInteraction from '@/plugin/my-server-interaction'
import FlasherTable from "@/components/FlasherTable";
import LogsTable from "@/components/LogsTable";
import LogsTableSerial from "@/components/LogsTableSerial";
import OperatorsTable from "@/components/OperatorsTable";
import MonitorOperationsTable from "@/components/MonitorOperationsTable";
import myAuthorizationMaskIndexes from "@/plugin/my-authorization-mask-indexes";
import PathNotActive from "@/components/vue-plugin/PathNotActive";
import PersonalizationObuTable from "@/components/PersonalizationObuTable";
import TimeoutTable from "@/components/TimeoutTable";

Vue.use(Router)
Vue.use(MyGetWindowSize)
Vue.use(MyExcelFromDate)
Vue.use(MyServerInteraction)
const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "/",
            component: Login
        },
        {
            path: "/home",
            name: "home",
            component: Home,
            meta: {requiresAuth: true}
        },
        {
            path: "/home/user",
            name: "user",
            component: UserProfile,
            meta: {requiresAuth: true}
        },
        {
            path: "/home/versions",
            name: "versions",
            component: VersionTable,
            meta: {requiresAuth: true}
        },
        {
            path: "/home/flashers",
            name: "flasher",
            component: FlasherTable,
            meta: {requiresAuth: true}
        },
        {
            path: "/home/logs",
            name: "reportLogs",
            component: LogsTable,
            meta: {requiresAuth: true},
            children: []
        },
        {
            path: "/home/logs/:id",
            name: "reportSerialLogs",
            component: LogsTableSerial,
            meta: {requiresAuth: true}
        },
        {
            path: "/home/operators",
            name: "operators",
            component: OperatorsTable,
            meta: {requiresAuth: true}
        },
        {
            path: "/home/monitor_operations/:id",
            name: "monitorOperations",
            component: MonitorOperationsTable,
            meta: {requiresAuth: true}
        },
        {
            path: "/home/personalization_obu/:id",
            name: "personalizationObu",
            component: PersonalizationObuTable,
            meta: {requiresAuth: true}
        },
        {
            path: '/path_not_active',
            name: 'PathNotActive',
            component: PathNotActive
        },
        {
            path: '/home/timeouts',
            name: 'timeouts',
            component: TimeoutTable
        }
    ]
})

router.beforeEach((to, from, next) => {
    let loggedIn = Vue.getLocalStorage('logged')
    let session =  Vue.getLocalStorage('session')
    let auth_mask = session !== undefined && session !== null && session.operator !== undefined ? session.operator.auth_mask : null
    if (loggedIn || to.name === '/') {
        let timer = Vue.getLocalStorage('timer')
        clearInterval(timer);
        let timerPersonalizationObu = Vue.getLocalStorage('timerPersonalizationObu')
        clearInterval(timerPersonalizationObu);
    }

    let translatePathName = myAuthorizationMaskIndexes.pathNameForTranslate(to.name)
    if (auth_mask && auth_mask[myAuthorizationMaskIndexes.CRUD(translatePathName, 'READ')] === '0'){
        next({
            path: '/path_not_active',
        })
    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn) {
            next({
                path: '/',
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
