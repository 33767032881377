<template>
  <div id="app" class="page_container">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <div class="main_page_container">
      <Header/>
      <Home/>
    </div>
    <Footer class="footer"/>

  </div>
</template>

<script>

import Header from "@/fragments/Header.vue";
import Home from "@/components/Home"
import Footer from "@/fragments/Footer";

export default {
  name: 'App',
  components: {Header, Home, Footer},
  data() {
    return {
      transition: 'slide-right',
    }
  },
}
</script>

<style>
/* main components */
html, body {
  height: 100%;
  overflow-x: hidden !important;
}

.page_container {
  background-color: #0D4394;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.shadow {
  box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.25),
  inset 0px -11px 8px -10px rgba(0, 0, 0, 0.25);
}

.custom-font {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
}

.footer {
  left: 0;
  background-color: black;
  color: white;
  font-size: 15px;
  padding-top: 8px;
  bottom: 0;
}

.main_page_container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  max-width: 100%;
}
</style>
