<template>
  <div :class="!stackedModeOn ? 'table-container' : 'table-responsive'">
    <b-row align-v="center">
      <b-col cols="auto" class="mb-3">
        <b-button
            v-if="session && session.operator && session.operator.auth_mask[maskValue('TIMEOUT_CONF', 'CREATE')] === '1'"
            v-on:click="showAddModal = true" class="custom-table-button" :class="stackedModeOn ? 'ml-0' : 'ml-3'">
          Aggiungi Timeout
        </b-button>
      </b-col>
    </b-row>
    <!-- Add new timeout config modal -->
    <b-modal
        cancel-title="Annulla"
        no-close-on-backdrop
        v-model="showAddModal"
        id="modal-add-timeout"
        ref="modal"
        title="Aggiungi Timeout"
        @ok="addTimeout()"
        @cancel="resetNewItem()"
        :ok-disabled='!swTypeState || !timeDeltaState'
    >
      <form ref="form">
        <b-form-group label="Codice tipologia software">
          <b-form-input
              :state="newItem.sw_type.length > 0 ? swTypeState : null"
              aria-describedby="input-live-feedback"
              id="sw-type-input"
              v-model="newItem.sw_type"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
                                  v-if="!isSwTypeValid(newItem.sw_type) && newItem.sw_type.length > 0">
            Il software type deve essere rappresentato da un numero intero positivo
          </b-form-invalid-feedback>
          <b-form-invalid-feedback id="input-live-feedback"
                                  v-if="newItem.sw_type.length > 32">
            Il campo può contenere 32 caratteri al massimo
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
      <form ref="form">
        <b-form-group label="Timeout delta (minuti)">
          <b-form-input
              :state="newItem.timeout.length > 0 ? timeDeltaState : null"
              aria-describedby="input-live-feedback"
              id="time-delta-input"
              v-model="newItem.timeout"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
                                  v-if="newItem.timeout && !isTimeoutValid(newItem.timeout) && newItem.timeout.length > 0">
            Il timeout deve essere espresso come un numero positivo maggiore di zero
          </b-form-invalid-feedback>
          <b-form-invalid-feedback id="input-live-feedback"
                                  v-if="newItem.timeout && newItem.timeout.length > 32">
            Il campo può contenere 32 caratteri al massimo
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
      <form ref="form">
        <b-form-group label="Descrizione (opt)">
          <b-form-input
              :state="newItem.description > 0 ? descriptionState : null"
              aria-describedby="input-live-feedback"
              id="description-input"
              v-model="newItem.description"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
                                  v-if="newItem.description && newItem.description.length > 32">
            Il campo può contenere 32 caratteri al massimo
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
    </b-modal>

    <b-table
        id="timeoutsTable"
        class="table"
        :items="items"
        :fields="fields"
        :sort-by="sortBy"
        sort-icon-left
        striped
        responsive="sm"
        :fixed="fixed !== false ? true : null"
        :stacked="stackedModeOn"
        :current-page="currentPage"
        :per-page="perPage"
        :class="!stackedModeOn ? 'notStacked version-table' : ''"
        :empty-text="'Non ci sono dati da visualizzare'"
        :show-empty="true"
    >

      <template v-slot:cell(selected)="row">
        <b-row class="row-edit-delete">
          <b-button
              v-if="session && session.operator && session.operator.auth_mask[maskValue('TIMEOUT_CONF', 'UPDATE')] === '1'"
              v-on:click="newItem = cloneRowData(row); showEditModal = true;">
            <i class="fa fa-edit"/>
          </b-button>
          <b-button
              class="delete-button"
              v-b-tooltip.hover title='Elimina'
              v-if="session && session.operator && session.operator.auth_mask[maskValue('TIMEOUT_CONF', 'DELETE')] === '1'"
              @click="rowToUpdateRemoved = row.item; showDeleteModal = true">
            <i class="fa fa-trash-alt"/>
          </b-button>
        </b-row>
        <!-- Timeout cfg delete modal -->
        <b-modal
            cancel-title="Annulla"
            v-model="showDeleteModal"
            id="modal-delete-timeout"
            ref="modal"
            title="Elimina Timeout"
            @ok="deleteTimeout(rowToUpdateRemoved.sw_type)"
        >
          <div>Sei sicuro di voler eliminare la configurazione per il software {{ rowToUpdateRemoved.sw_type }}?</div>
        </b-modal>
        <!-- Timeout cfg edit modal -->
        <b-modal
            cancel-title="Annulla"
            no-close-on-backdrop
            size="xl"
            v-model="showEditModal"
            id="modal-edit-timeout"
            ref="modal"
            title="Modifica Timeout"
            @ok="editTimeout(newItem)"
            :ok-disabled='!swTypeState || !timeDeltaState'
            @cancel="resetNewItem()"
        >
          <form ref="form">
            <b-form-group label="Codice Tipologia Software">
              <b-form-input
                  aria-describedby="input-live-feedback"
                  id="sw-type-input"
                  v-model="newItem.sw_type"
                  disabled
              ></b-form-input>
            </b-form-group>
          </form>
          <form ref="form">
            <b-form-group label="Timeout delta (minuti)">
              <b-form-input
                  :state="timeDeltaState"
                  aria-describedby="input-live-feedback"
                  id="time-delta-input"
                  v-model="newItem.timeout"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback"
                                      v-if="!timeDeltaState">
                Il time delta non è valido, indica un numero intero e positivo di minuti
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-live-feedback"
                                  v-if="newItem.timeout && newItem.timeout.length > 32">
                Il campo può contenere 32 caratteri al massimo
              </b-form-invalid-feedback>
            </b-form-group>
          </form>
          <form ref="form">
            <b-form-group label="Descrizione (opt)">
              <b-form-input
                :state="newItem.description > 0 ? descriptionState : null"
                aria-describedby="input-live-feedback"
                id="description-input"
                v-model="newItem.description"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback"
                                  v-if="newItem.description && newItem.description.length > 32">
                Il campo può contenere 32 caratteri al massimo
              </b-form-invalid-feedback>
            </b-form-group>
          </form>
        </b-modal>
      </template>

      <template v-slot:cell(sw_type)="row">
        <div v-if="row.item.sw_type">{{ row.item.sw_type }}
        </div>
      </template>

      <template v-slot:cell(timeout)="row">
        <div v-b-tooltip.hover class="uri-hidden" :title=' row.item.timeout  ' v-if="row.item.timeout">
          {{ row.item.timeout }}
        </div>
      </template>

      <template v-slot:cell(description)="row">
        <div v-b-tooltip.hover class="uri-hidden" :title=' row.item.description  ' v-if="row.item.description">
          {{ row.item.description }}
        </div>
      </template>

    </b-table>

    <!--table footer-->
    <b-row>
      <b-col>
        <b-pagination
            class="my-pagination"
            :per-page="perPage"
            v-model="currentPage"
            :size="stackedModeOn ? 'sm' : 'md'"
            aria-controls="versionsTable"
            :total-rows="numberOfRows"
        >
        </b-pagination>
      </b-col>
      <b-col cols="5" sm="1" md="3">
        <b-form-group horizontal :label="''" :label-cols="5">
          <b-form-select :options="pageOptions" v-model="perPage"/>
        </b-form-group>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import Vue from 'vue'
import _ from 'lodash'

export default {
  data() {
    return {
      rowToUpdateRemoved: '',
      session: '',
      sortBy: 'sw_type',
      sortDesc: true,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      stackedModeOn: null,
      newItem: {
        sw_type: '',
        timeout: '',
        description: '',
      },
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
      rowToEdit: {
        sw_type: '',
        timeout: '',
        description: '',
      },
      fields: [
        {key: 'selected', label: ''},
        {key: 'sw_type', label: 'Tipologia Software', sortable: true},
        {key: 'timeout', label: 'Tempo di Timeout', sortable: true},
        {key: 'description', label: 'Descrizione', sortable: true}
      ],
      items: [
        {
          sw_type: '',
          timeout: '',
          description: ''
        }
      ]
    }
  },
  computed: {

    numberOfRows() {
      return this.items.length
    },
    swTypeState() {
      return this.newItem.sw_type.length > 0 && this.isSwTypeValid(this.newItem.sw_type) && this.newItem.sw_type.length <= 32
    },
    timeDeltaState() {
      return this.newItem.timeout.length > 0 && this.isTimeoutValid(this.newItem.timeout) && this.newItem.timeout.length <= 32
    },
    descriptionState() {
      return this.newItem.description.length <= 32
    }
  },
  mounted() {
    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md'||  size === 'lg'
    this.session = Vue.getLocalStorage('session')
    this.fetchData()
  },
  props: [
    'fixed',
  ],
  methods: {
    isSwTypeValid(swType){
      let res = swType.match(/^\d{1,32}$/g)
      return res != null;
    },
    isTimeoutValid(timeDelta){
      let res = timeDelta.match(/^[1-9]\d{0,31}$/g)
      return res != null;
    },
    addTimeout() {
      if (this.newItem.sw_type === '' || this.newItem.timeout === '') {
        this.$root.$emit('errorNotification')
        return
      }
      this.newItem.timeout = parseInt(this.newItem.timeout, 10)
      this.$root.$emit('activeLoader', true)
      Vue.myPost('/timeout_cfg', this.newItem).then(
        async (response) => {
          if (response.status !== 200) {
            this.$root.$emit('activeLoader', false)
            if (response.status === 400) {
              this.$root.$emit('errorNotification', null, "Dati inviati non validi")
            } else {
              this.$root.$emit('errorNotification')
            }
          } else {
            this.$root.$emit('activeLoader', false)
            this.$root.$emit('successNotification')
          }
        }
      )
      this.resetNewItem()
    },
    async fetchData() {
      const response = Vue.myGet(this, '/timeout_cfg')

      if (!response.ok) {
        this.$root.$emit('errorNotfication', null, 'Errore durante il caricamento dei dati')
      }
      this.items = await response
    },
    editTimeout(item) {
      this.$root.$emit('activeLoader', true)
      this.newItem.timeout = parseInt(this.newItem.timeout, 10)
      Vue.put('/timeout_cfg', item).then(
        async (response) => {
          if (response.status !== 204 && response.status !== 200) {
            this.$root.$emit('activeLoader', false)
            this.$root.$emit('errorNotification')
          } else {
            this.$root.$emit('activeLoader', false)
            this.$root.$emit('successNotification')
          }
        }
      )
      this.resetRowToEdit()
    },
    resetNewItem() {
      this.newItem = {
        sw_type: '',
        timeout: '',
        description: ''
      }
    },
    resetRowToEdit() {
      this.editTimeout = {
        sw_type: '',
        timeout: '',
        description: ''
      }
    },
    async deleteTimeout(identifier) {
      this.$root.$emit('activeLoader', true)
      await Vue.myDelete('/timeout_cfg/'+identifier).then(
        async (response) => {
          if (response.status !== 200 && response.status !==204){
            this.$root.$emit('activeLoader', false)
            this.$root.$emit('errorNotification', null, "Non e' stato possibile eliminare il record")
          } else {
            this.$root.$emit('activeLoader', false)
            this.$root.$emit('successNotification')
          }
        }
      )
    },
    cloneRowEdit(row) {
      this.rowToEdit = _.cloneDeep(row.item);
    },
    cloneRowData(row) {
      let obj = _.cloneDeep(row.item)
      obj.timeout = obj.timeout.toString()
      return obj
    }
  }
}
</script>

<style>
html, body {
  overflow-y: auto;
}

.table {
  background-color: white;
  border-radius: 25px;
  text-align: center;
}

.small-table-container {
  width: 100%;
  padding-top: 2rem;
  max-width: 95rem;
}

.notStacked > table td, .notStacked > table th {
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.notStacked > table tr:first-child th {
  border-top: 0;
}

.notStacked > table tr:last-child th {
  border-bottom: 0;
}

.table tr:last-child td {
  border-bottom: 0;
}

.table tr td:first-child,
.table tr th:first-child {
  border-left: 0;
}

.table tr td:last-child,
.table tr th:last-child {
  border-right: 0;
}

.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 0;
}

.search {
  position: relative;
  color: black;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}


.form-control {
  background: white;
  border-radius: 15px;
  font-size: 1.2rem;
}

.search .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}


.custom-control-label::before {
  position: absolute;
  display: flex;
  width: 1.2rem;
  height: 1.2rem;
  pointer-events: none;
  background-color: #fff;
}

.table thead th {
  vertical-align: inherit;
  border-bottom: 2px solid gray;
}

.table th, .table td {
  vertical-align: inherit;
  word-break: break-word;
}

.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  word-wrap: break-word;
}

.custom-control {
  z-index: unset;
}


.col-form-label {
  padding-bottom: 0;
  padding-top: 0;
}

.custom-table-button {
  margin-right: 0;
  margin-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 15px;
  font-size: 1.2rem;
  background-color: #FF5576;
  border: 0;
}

.pagination {
  font-size: 1.2rem;
}

.input-group > .input-group-append > .btn {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.edit-row-button {
  padding: 0.2rem;
}

.uri-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-text {
  text-align: start;
  word-break: break-all;
}

.version-table > .table tr td:first-child, .version-table > .table tr th:first-child {
  width: 10rem;
}

.version-table > .table tr td:nth-child(2), .version-table > .table tr th:nth-child(2) {
  width: 8rem;
}


.version-table > .table tr td:nth-child(3), .version-table > .table tr th:nth-child(3) {
  width: 8rem;
}

.version-table > .table tr td:nth-child(5), .version-table > .table tr th:nth-child(5) {
  width: 8rem;
}

.version-table > .table tr td:nth-child(6), .version-table > .table tr th:nth-child(6) {
  width: 15rem;
}

.version-table > .table tr td:nth-child(7), .version-table > .table tr th:nth-child(7) {
  width: 8rem;
}
</style>