<template>
  <b-container class="container-path-not-active">
    <b-row align-h="center" align-v="center">
      <b-col cols="auto">
        <font-awesome-layers class="mb-5 mr-5">
          <font-awesome-icon icon="hand-paper" size="3x" class="ml-3 c-white"/>
          <font-awesome-icon icon="ban" :style="{ color: 'red' }" size="5x"/>
        </font-awesome-layers>
      </b-col>
    </b-row>

    <b-row align-h="center" align-v="center">
      <h6 class="c-white" v-if="isMobile">Non si è in possesso dei permessi necessari, rivolgersi all'amministratotre di
        default.</h6>
      <h3 class="c-white" v-else>Non si è in possesso dei permessi necessari, rivolgersi all'amministratotre di
        default.</h3>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'PathNotActive',
  mounted() {
    this.checkWindowWidth()
  },
  data() {
    return {
      isMobile: false
    }
  },
  methods: {
    checkWindowWidth() {
      const size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    }
  }
}
</script>
<style scoped>
.container-path-not-active {
  margin-top: 20%;
}
</style>
