<template>
  <b-container class="small-table-container">
    <b-row>
      <b-col cols="12" sm="9" md="6" lg="4" xl="3">
        <div class="search">
          <span class="fa fa-search"></span>
          <b-form-input
              class="search-input"
              v-model="searchWord"
              id="input-search"
              placeholder="Ricerca"/>
        </div>
      </b-col>
      <div class="mb-3 ml-3">
        <b-button class="custom-table-button" v-b-toggle.my-collapse>
          <i class="fa fa-filter"></i>
        </b-button>
      </div>
      <div class="mb-3">
        <b-button v-on:click="resetFilters()" class="ml-3 my-button">
          Pulisci filtri
        </b-button>
      </div>
    </b-row>

    <b-collapse id="my-collapse">
      <b-row>
        <b-col cols="12" md="6" lg="6" xl="3">
          <label class="c-white">Flasher visualizzati:</label>

          <b-input-group class="search">
            <b-dropdown :text=selectedRemoved offset=""
                        v-model="selectedRemoved">
              <b-dropdown-item @click="selectedRemoved='Tutti'">Tutti</b-dropdown-item>
              <b-dropdown-item @click="selectedRemoved='Abilitati'">Abilitati</b-dropdown-item>
              <b-dropdown-item @click="selectedRemoved='Non abilitati'">Non abilitati</b-dropdown-item>

            </b-dropdown>
          </b-input-group>
        </b-col>
       <!-- <b-col cols="12" md="6" lg="6" xl="3">
          <label class="c-white">Tipo di veicolo</label>
          <b-input-group class="search">
            <b-dropdown class="my-btn-secondary my-dropdown" :text="selectedVehicleFilter.Type || selectedVehicleFilter"
                        v-model="selectedVehicleFilter">
              <b-dropdown-item
                  @click="selectedVehicleFilter = 'Tutte le tipologie';">Tutte le tipologie
              </b-dropdown-item>
              <b-dropdown-item :key="item.id" v-for="item in vehicles"
                               @click="selectedVehicleFilter = item; ">
                {{ item.Type }}
              </b-dropdown-item>
            </b-dropdown>
          </b-input-group>
        </b-col> -->
      </b-row>
    </b-collapse>
    <b-table
        id="flasherTable"
        class="table"
        :items="items"
        :fields="fields"
        :sort-by="sortBy"
        :sort-desc="false"
        sort-icon-left
        striped
        responsive="sm"
        :fixed="fixed !== false ? true : null"
        :stacked="stackedModeOn"
        :current-page="currentPage"
        :per-page="perPage"
        :class="!stackedModeOn ? 'notStacked flasher-table' : ''"
        :empty-text="'Non ci sono dati da visualizzare'"
        :show-empty="true"
    >

      <template v-slot:cell(edit)="row">
        <b-row style="justify-content: space-between" :class="session.operator.auth_mask[maskValue('FLASHERS', 'UPDATE')] === '0' ||
        session.operator.auth_mask[maskValue('FLASHERS', 'DELETE')] === '0' ?
        'row-edit-delete-alone' : 'row-edit-delete-alone'">
          <b-button
              v-if="session.operator.auth_mask[maskValue('FLASHERS', 'UPDATE')] === '1'"
              :disabled="row.item.active_status_id === 3 || row.item.removed"
              @click="cloneRowEdit(row)">
            <i class="fa fa-edit"/>
          </b-button>
          <b-button
              :disabled="row.item.active_status_id === 3"
              class="enable-disable"
              v-b-tooltip.hover title='Disabilita'
              v-if="!row.item.removed && session.operator.auth_mask[maskValue('FLASHERS', 'DELETE')] === '1'"
              @click="rowToUpdateRemoved = row.item; showDeleteModal = true">
            <i class="fa fa-trash-alt"/>
          </b-button>
          <b-button
              class="enable-disable"
              v-b-tooltip.hover title='Abilita'
              v-if="row.item.removed && session.operator.auth_mask[maskValue('FLASHERS', 'DELETE')] === '1'"
              @click="rowToUpdateRemoved = row.item; showRestoreModal = true">
            <i class="fa fa-trash-restore-alt "/>
          </b-button>
        </b-row>

        <b-modal
            cancel-title="Annulla"
            v-model="showDeleteModal"
            ref="modal"
            title="Disabilita flasher"
            @ok="rowToUpdateRemoved.removed = true; editRow(rowToUpdateRemoved)"
        >
          <div>Sei sicuro di voler disabilitare il flasher {{ rowToUpdateRemoved.flasher_serial }}?</div>
        </b-modal>
        <b-modal
            cancel-title="Annulla"
            v-model="showRestoreModal"
            ref="modal"
            title="Abilita flasher"
            @ok="rowToUpdateRemoved.removed = false; editRow(rowToUpdateRemoved)"
        >
          <div>Sei sicuro di voler abilitare il flasher {{ rowToUpdateRemoved.flasher_serial }}?</div>
        </b-modal>
        <b-modal
            cancel-title="Annulla"
            v-model="showWithStatus"
            ref="modal"
            title="Modifica caratteristiche"
            @ok="editRow(rowToEdit)"
            v-if="row.item.additions.active_status && row.item.additions.active_status.Name === 'READY_TO_FLASH'"
            :ok-disabled='!descriptionState'
        >
          <form ref="form">
            <b-form-group
                label="Descrizione *"
            >
              <b-form-input
                  v-model.trim="rowToEdit.flasher_des"
                  :state="rowToEdit.flasher_des && rowToEdit.flasher_des.length > 0 ? descriptionState : null"
                  aria-describedby="input-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback"
                                       v-if="rowToEdit.flasher_des && rowToEdit.flasher_des.length > 50">
                La descrizione deve contenere 50 caratteri al massimo
              </b-form-invalid-feedback>
            </b-form-group>
          </form>
          <form ref="form">
            <b-form-group
                label="Note"
            >
              <b-form-input
                  v-model.trim="rowToEdit.notes"
              ></b-form-input>
            </b-form-group>
          </form>
          <form ref="form">
            <b-form-group
                label="Stato richiesto"
            >
              <b-form-checkbox
                  id="checkbox-status"
                  v-model="rowToEdit.additions.resetStatus"
                  name="checkbox-status"
                  style="width: max-content"
              >
                Resetta a inizializzazione
              </b-form-checkbox>
            </b-form-group>
          </form>
        </b-modal>
        <b-modal
            cancel-title="Annulla"
            v-model="showWithoutStatus"
            ref="modal"
            title="Modifica caratteristiche"
            @ok="editRow(rowToEdit)"
            :ok-disabled='!descriptionState'
        >
          <form ref="form">
            <b-form-group
                label="Descrizione *"
            >
              <b-form-input
                  v-model.trim="rowToEdit.flasher_des"
                  :state="rowToEdit.flasher_des && rowToEdit.flasher_des.length > 0 ? descriptionState : null"
                  aria-describedby="input-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback"
                                       v-if="rowToEdit.flasher_des && rowToEdit.flasher_des.length > 50">
                La descrizione deve contenere 50 caratteri al massimo
              </b-form-invalid-feedback>
            </b-form-group>
          </form>
          <form ref="form">
            <b-form-group
                label="Note"
            >
              <b-form-input
                  v-model.trim="rowToEdit.notes"
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>
      </template>

      <template v-slot:cell(versions)="row">
        <b-row class="row-versions">
          <b-col>
            <div v-if="row.item.additions.active_version" class="div-versions">
          <span class="mr-2">
            <strong>{{row.item.additions.software_type}}</strong><br v-show="row.item.additions.software_type && row.item.additions.software_type !== ''">{{ row.item.additions.active_version_string }}
          </span>
              <i @click="showVersionDetailsModal = true; currentVersion = firmwareVersionsDetails.find(version => version.id === row.item.additions.active_version) || row.item.additions.active_version_info"
                 class="fa fa-eye"/>
              <i v-if="row.item.additions.active_version_info"
                 v-b-tooltip.hover title="Scaduta" class="fa ml-2 fa-clock"
              />
            </div>
          </b-col>
          <b-dropdown id="version-dropdown" @click="currentRow = row"
                      :disabled="row.item.removed"
                      v-if="row.item.additions.active_status && row.item.additions.active_status.Name !== 'FLASHING' &&
                      session.operator.auth_mask[maskValue('FLASHERS', 'UPDATE')] === '1'"
                      boundary="viewport">
            <b-dropdown-header>
              Versioni
            </b-dropdown-header>
            <div
                v-if="row.item.additions.active_version && !firmwareVersionsDetails.find(item => item.id === row.item.additions.active_version)">
              <b-row class="mr-2">
                <b-col cols="11">
                  <b-dropdown-item v-model="row.item.id">
                    <label>
                      <input :id="row.item.id" type="radio" class="mr-radio"
                             :checked=true
                             :size="stackedModeOn ? 'sm' : 'md'"/>
                    </label>
                    <label>{{ row.item.additions.active_version_string }}</label>
                    <i v-if="row.item.additions.active_version_info"
                       v-b-tooltip.hover title="Scaduta" class="fa ml-2 mt-2 fa-clock"
                    />
                  </b-dropdown-item>

                </b-col>
                  <i @click="showVersionDetailsModal = true;  currentVersion = row.item.additions.active_version_info"
                     class="fa fa-eye"/>

              </b-row>
            </div>
            <div v-for="item in firmwareVersionsDetails" :key="item.id">
              <b-row class="mr-2">
                <b-col cols="11">
                  <b-dropdown-item v-model="row.item.id" @click=" postVersion(row.item.id, item.id);">
                      <input :id="row.item.id" type="radio" class="mr-radio"
                             :checked="checkIsActive(item.id, row.item.additions.active_version)"
                             :size="stackedModeOn ? 'sm' : 'md'"/>
                    <label :for="item.id"><strong>{{item.software_type}}</strong> {{ item.firmware_version }}</label>
                  </b-dropdown-item>

                </b-col>
                <i @click="showVersionDetailsModal = true;  currentVersion = item" class="fa fa-eye"/>

              </b-row>
            </div>
          </b-dropdown>
        </b-row>

        <b-modal
            cancel-title="Annulla"
            v-model="showVersionDetailsModal"
            id="modal-version-details"
            ref="versionDetailModal"
            title="Dettagli versione"
            size="lg"
        >
          <div>
            <b-row>
              <b-col cols="3">
                <h6>Versione del software:</h6>
              </b-col>
              <b-col cols="8">
                <h6>{{ currentVersion.firmware_version }}</h6>
              </b-col>
              <b-col cols="3">
                <h6> Tipo del software: </h6>
              </b-col>
              <b-col cols="8">
                <h6 class="text">{{ currentVersion.software_type }}</h6>
              </b-col>
              <b-col cols="3">
                <h6> URL del software: </h6>
              </b-col>
              <b-col cols="8">
                <h6 class="text">{{ currentVersion.firmware_uri }}</h6>
              </b-col>
              <b-col cols="3">
                <h6>Descrizione:</h6>
              </b-col>
              <b-col cols="9">
                <tree
                    :data="currentVersion.firmware_popup"
                >
                </tree>
              </b-col>
              <b-col cols="3">
                <h6>Note:</h6>
              </b-col>
              <b-col cols="9">
                <h6 class="text">{{ currentVersion.notes }}</h6>
              </b-col>
              <b-col cols="3">
                <h6>Data di scadenza:</h6>
              </b-col>
              <b-col cols="9">
                <h6 class="text">{{ currentVersion.valid_date }}</h6>
              </b-col>
            </b-row>
          </div>
        </b-modal>
      </template>
     <template v-slot:cell(vehicle)="row">
        <form>
          <b-dropdown :disabled="row.item.removed" v-b-tooltip.hover.top
                      title='Il tipo di veicolo è un parametro necessario per eseguire la flashatura!'
                      :class="row.item.additions.vehicle_type_string === undefined || row.item.additions.vehicle_type_string === '' ? 'blinkable' : null"
                      :text="row.item.additions.vehicle_type_string"
                      v-model="row.item.additions.vehicle_type_string"
                      boundary="viewport"
          >
            <b-dropdown-item :key="item.id" v-for="item in vehicles"
                             @click="row.item.additions.vehicle_type_string = item.Type; row.item.vehicle_type_id = item.id; editRow(row.item)">
              {{ item.Type }}
            </b-dropdown-item>
          </b-dropdown>

        </form>
      </template>
    </b-table>

    <b-row>
      <b-col>
        <b-pagination
            class="my-pagination "
            :per-page="perPage"
            v-model="currentPage"
            :size="stackedModeOn ? 'sm' : 'md'"
            aria-controls="flasherTable"
            :total-rows="numberOfRows"
        >
        </b-pagination>
      </b-col>
      <b-col cols="5" sm="1" md="3">
        <b-form-group horizontal :label="''" :label-cols="5">
          <b-form-select :options="pageOptions" v-model="perPage"/>
        </b-form-group>
      </b-col>
      <b-col cols="7" sm="2" md="2" lg="auto">
        <b-button
            class="my-button"
            @click="excel('Flasher')"> Excel
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import myFlasherStatusTranslate from "@/plugin/my-flasher-status-translate";
import myUsefulFunctions from "@/plugin/my-useful-functions";

export default {
  data() {
    return {
      showWithStatus: false,
      showWithoutStatus: false,
      showEditModal: false,
      showDeleteModal: false,
      showRestoreModal: false,
      showVersionDetailsModal: false,
      searchWord: null,

      currentRow: '',
      currentVersion: '',
      rowToUpdateRemoved: '',
      session: '',

      selectedVersion: false,
      refresh: false,

      vehicles: [],
      originalItems: [],
      status: [],
      selectedVehicle: 'Tutte le tipologie',
      selectedRemoved: 'Abilitati',
      selectedVehicleFilter: 'Tutte le tipologie',

      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      stackedModeOn: null,
      sortBy: 'flasher_serial',
      sortDesc: true,

      rowToEdit: {
        id: '',
        flasher_serial: '',
        flasher_des: '',
        active_status_id: '',
        desired_status_id: '',
        notes: '',
        vehicle_type_id: '',
        additions: {}
      },
      fields: [
        {key: 'edit', label: ''},
        {key: 'flasher_serial', label: 'Seriale', sortable: true},
        {key: 'flasher_des', label: 'Descrizione', sortable: true},
        {key: 'additions.active_status.status_translate', label: 'Stato attivo', sortable: true},
        {key: 'additions.desired_status.status_translate', label: 'Stato richiesto', sortable: true,},
        {key: 'notes', label: 'Note', sortable: false},
        {key: 'vehicle', label: 'Tipo di veicolo'},
        {key: 'versions', label: 'Versione software'}
      ],
      items: [
        {
          id: '',
          flasher_serial: '',
          flasher_des: '',
          active_status_id: '',
          desired_status_id: '',
          notes: '',
          vehicle_type_id: '',
          additions: {
            active_version_string: '',
            software_type: '',
            active_version: '',
            desired_status: {id: '', Name: '', status_translate: ''},
            active_status: {id: '', Name: '', status_translate: ''},
            resetStatus: '',
            vehicle_type_string: '',
            active_version_info: {}
          }
        }
      ],
      firmwareVersionsDetails: [
        {
          id: '',
          firmware_version: '',
          software_type: '',
          firmware_uri: '',
          firmware_des: '',
          valid_date: '',
          notes: ''
        }
      ],
    }
  },
  computed: {
    numberOfRows() {
      return this.items.length
    },
    descriptionState() {
      return this.rowToEdit.flasher_des && true && this.rowToEdit.flasher_des.length > 0 && this.rowToEdit.flasher_des.length <= 50
    },
  },
  async created() {
    const size = Vue.myGetWindowSize()
    this.stackedModeOn = size === 'sm' || size === 'cols' || size === 'md'||  size === 'lg'
    this.session = Vue.getLocalStorage('session')
    await this.fetchData()
  },
  methods: {
    async fetchData(flasherId, query) {
      if (flasherId) {
        await Vue.myGet(this, '/flasher_version/' + flasherId + '/active', 'flasher_versions').then((version) => {
          if (version.status !== 404) {
            let filteredItems = _.cloneDeep(this.originalItems)
            filteredItems.find((item) => item.id === version.id_flasher).additions.active_version = version.id_version
            this.originalItems = _.cloneDeep(filteredItems)
            this.items = this.originalItems
          }
        })
      } else {
        if (this.originalItems === []) {
          this.$root.$emit('activeLoader', true)
        }
        this.status = await myUsefulFunctions.getStatus()
        if (!this.refresh) {
          this.refresh = true
          await Vue.myGet(this, '/version?valid=true', 'firmwareVersionsDetails').then((versions) => {
            this.firmwareVersionsDetails = versions
            myUsefulFunctions.translateSomeFields(this.firmwareVersionsDetails)
            this.firmwareVersionsDetails.forEach((version) => {
              version.valid_date = this.transformDate(version.valid_date)
            })
          })
          this.vehicles = await myUsefulFunctions.getVehicle()
        }

        await Vue.myGet(this, query || '/flasher?removed=false', 'flasher').then((flashers) => {
          flashers.forEach(async (item) => {
            const additions = {}
            item.additions = {}
            await Vue.myGet(this, '/flasher_version/' + item.id + '/active', 'flasher_versions').then(async (version) => {
              if (version.status !== 400) {
                if (!_.isUndefined(version.id_version)) {
                  additions.active_version = version.id_version
                  if (this.firmwareVersionsDetails) {
                    let activeVersion = this.firmwareVersionsDetails.find(versionItem => versionItem.id === additions.active_version)
                    if (!_.isUndefined(activeVersion)) {
                      additions.active_version_string = activeVersion.firmware_version
                      additions.software_type = activeVersion.software_type
                    } else {
                      await Vue.myGet(this, '/version/' + version.id_version, 'flasher_versions').then((versionInfo) => {
                        additions.active_version_string = versionInfo.firmware_version
                        additions.active_version_info = versionInfo
                        additions.active_version_info.valid_date = this.transformDate(additions.active_version_info.valid_date)
                      })
                    }
                  }
                }
              }
            })
            const state = this.status.find(state => state.id === item.active_status_id)
            if (!_.isUndefined(state)) {
              state.status_translate = myFlasherStatusTranslate.statusTranslate(state.id)
              additions.active_status = state
            }
            if (item.desired_status_id === 0) {
              additions.desired_status = {
                Name: 'Nessuno stato',
                id: 0,
                status_translate: myFlasherStatusTranslate.statusTranslate(0)
              }
              additions.resetStatus = false
            } else {
              const state = this.status.find(state => state.id === item.desired_status_id)
              if (!_.isUndefined(state))
                state.status_translate = myFlasherStatusTranslate.statusTranslate(state.id)
              additions.desired_status = state
              additions.resetStatus = additions.desired_status.Name === 'WAIT_FOR_INIT';
            }
            if (!_.isUndefined(item.vehicle_type_id)) {
              await Vue.myGet(this, '/vehicle/' + item.vehicle_type_id, 'vehicle').then((vehicle) => {
                additions.vehicle_type_string = vehicle.Type
              })
            }
            if (!_.isUndefined(item.flasher_des))
              item.flasher_des = item.flasher_des.trim()
            if (!_.isUndefined(item.notes))
              item.notes = item.notes.trim()
            item.additions = additions
          })
          this.originalItems = flashers
        }).finally(() => {
          this.items = this.originalItems
          this.$root.$emit('activeLoader', false)
        })

      }
    },

    getItems(searchWord) {
      let allItems = _.cloneDeep(this.originalItems)

      if (searchWord && searchWord !== '' && !_.isNull(searchWord)) {
        allItems = myUsefulFunctions.filterSearchWord(allItems, this.fields, searchWord)
      }
      return allItems
    },

    resetFilters() {
      this.searchWord = null
      this.items = this.originalItems
      this.selectedRemoved = 'Abilitati'
      this.selectedVehicleFilter = 'Tutte le tipologie'
    },

    excel(fileName, customFields) {
      let newFields = _.cloneDeep(this.fields)
      newFields.forEach((field) => {
        if (field.key === 'vehicle')
          field.key = 'additions.vehicle_type_string'
      })
      return Vue.excel(
          customFields || newFields,
          this.items,
          fileName,
      )
    },
    editRow(item) {
      let theSame = this.originalItems.find(originalItem => originalItem.id === item.id)
      if (!_.isUndefined(theSame)
          && theSame.notes === item.notes
          && theSame.flasher_des === item.flasher_des
          && theSame.desired_status
          && theSame.desired_status.id === item.desired_status_id
          && theSame.vehicle_type_id === item.vehicle_type_id)
        return
      if (_.isUndefined(item.notes))
        item.notes = ''
      if (_.isUndefined(item.flasher_des))
        item.flasher_des = ''
      item.notes.trim()
      item.flasher_des.trim()
      this.$root.$emit('activeLoader', true)
      delete item.additions
      if (item.flasher_des === '' || _.isUndefined(item.flasher_des))
        item.flasher_des = item.flasher_serial
      Vue.put('/flasher/' + item.id.toString(), item).then(async (response) => {
        await this.fetchData().finally(async() => {
          this.$root.$emit('activeLoader', false)
          if (response.status !== 200 && response.status !== 204) {
            this.$root.$emit('errorNotification')
          } else {
            let flashers = await myUsefulFunctions.getNotRemovedFlashers()
            Vue.setLocalStorage('flashers', flashers)
            this.$root.$emit('successNotification')
            this.$root.$emit('updateFlashers')
          }
        })
      }).catch(() => {
        this.$root.$emit('activeLoader', false)
        this.$root.$emit('errorNotification')
      })
    },
    async postVersion(flasher, version) {
      const newItem = {
        active: true,
        id_flasher: flasher,
        id_version: version
      }
      this.$root.$emit('activeLoader', true)
      await Vue.myPost('/flasher_version', newItem).then(async (response) => {
        await this.fetchData(flasher).finally(async () => {
          let flashers = await myUsefulFunctions.getNotRemovedFlashers()
          Vue.setLocalStorage('flashers', flashers)
          this.$root.$emit('activeLoader', false)
          this.$root.$emit('updateFlashers')
        })
        
        if (response.status == 500)
            this.$root.$emit('errorNotification')
        else 
            this.$root.$emit('successNotification')

      }).catch(() => {
        this.$root.$emit('activeLoader', false)
        this.$root.$emit('errorNotification')
      })
    },
    checkIsActive(version, activeVersion) {
      return version === activeVersion
    },
    cloneRowEdit(row) {
      if (!_.isUndefined((row.item.additions.active_status)))
        this.showWithStatus = row.item.additions.active_status.Name === 'READY_TO_FLASH';
      this.showWithoutStatus = !this.showWithStatus
      this.rowToEdit = _.cloneDeep(row.item);
      if (!_.isUndefined(this.rowToEdit.vehicle_type_id))
        this.selectedVehicle = this.rowToEdit.additions.vehicle_type_string
    },
    resetDesiredStatus() {
      const desStatus = this.status.find(item => item.Name === 'WAIT_FOR_INIT')
      if (!_.isUndefined(desStatus))
        this.rowToEdit.desired_status_id = desStatus.id
    },
    settingNullDesiredStatus() {
      this.rowToEdit.desired_status_id = 0
    },
    transformDate(date) {
      if (date && date !== '')
        return new Date(date).toLocaleDateString("it-IT", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
    },
    filter() {
      let path = '/flasher?'
      if (this.selectedRemoved === 'Tutti' && this.selectedVehicleFilter === 'Tutte le tipologie')
        this.fetchData(null, path)
      else {
        this.selectedRemoved !== 'Tutti' ? path = path + 'removed=' + (this.selectedRemoved === 'Non abilitati').toString() + '&' : null
        this.selectedVehicleFilter !== 'Tutte le tipologie' ? path = path + 'vehicle_type_id=' + (this.selectedVehicleFilter.id).toString() : null
        this.fetchData(null, path)
      }
    }
  },
  props: [
    'fixed',
  ],
  watch: {
    searchWord() {
      this.items = this.getItems(this.searchWord)
    },
    'rowToEdit.additions.resetStatus'() {
      if (this.rowToEdit.additions.resetStatus === true)
        this.resetDesiredStatus()
      else this.settingNullDesiredStatus()
    },
    selectedRemoved() {
      this.filter()
    },
    selectedVehicleFilter() {
      this.filter()
    }
  }
}
</script>
<style>
html, body {
  overflow-y: auto;
}

.table {
  background-color: white;
  border-radius: 25px;
  text-align: center;
}

.notStacked > table td, .notStacked > table th {
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.notStacked > table tr:first-child th {
  border-top: 0;
}

.notStacked > table tr:last-child th {
  border-bottom: 0;
}

.table tr:last-child td {
  border-bottom: 0;
}

.table tr td:first-child,
.table tr th:first-child {
  border-left: 0;
}

.table tr td:last-child,
.table tr th:last-child {
  border-right: 0;
}

.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 0;
}

.search {
  position: relative;
  color: black;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.form-control {
  background: white;
  border-radius: 15px;
  font-size: 1.2rem;
}

.search .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}


.custom-control-label::before {
  position: absolute;
  display: flex;
  width: 1.2rem;
  height: 1.2rem;
  pointer-events: none;
  background-color: #fff;
}

.table thead th {
  vertical-align: inherit;
  border-bottom: 2px solid gray;
}

.table th, .table td {
  vertical-align: inherit;
}


.custom-control {
  z-index: unset;
}

.btn-secondary {
  background-color: #FF5576;
  border: 0;
}
.btn-secondary:focus {
  background-color:#FF5576!important;
  border-color:#FF5576!important;
}

.col-form-label {
  padding-bottom: 0;
  padding-top: 0;
}

.my-button {
  margin-right: 0;
  margin-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 15px;
  font-size: 1.2rem;
}

.pagination {
  font-size: 1.2rem;
}

.input-group > .input-group-append > .btn {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

#version-dropdown > .dropdown-menu {
  min-width: 5rem;
  overflow: auto;
  min-height: 10rem;
}

.mr-radio {
  margin-right: 2rem;
}

.fa.fa-eye {
  line-height: 2;
  cursor: pointer;
}

#warning-header {
  color: red;
}

.modal-content {
  word-break: break-word;
}

.flasher-table > .table tr td:last-child, .flasher-table > .table tr th:last-child {
  width: 12rem;
}

.flasher-table > .table tr td:first-child, .flasher-table > .table tr th:first-child {
  width: 8rem;
}

.flasher-table > .table tr td:nth-child(4), .flasher-table > .table tr th:nth-child(4) {
  width: 15rem;
}

.flasher-table > .table tr td:nth-child(5), .flasher-table > .table tr th:nth-child(5) {
  width: 15rem;
}

.row-versions {
  align-items: baseline;
}

.div-versions {
  display: table;
}

.row-edit-delete {
  justify-content: space-evenly;
  margin-left: unset;
}

.enable-disable {
  background-color: lightpink;
  border-color: #FF5576;
  border-width: 0.01rem;
  border-style: solid;
}

.delete-button {
  background-color: #0D4394;
}

.blinkable > .btn-secondary {
  background-color: red;
  animation: blink 1s infinite;
}

@keyframes blink {
  from {
    background-color: #FF5576;
  }
  to {
    background-color: red;
  }
  0% {
    opacity: 0
  }
  49% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
}

.pre-formatted {
  white-space: pre;
}
</style>
